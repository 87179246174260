var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('header', {
    staticClass: "row p12",
    staticStyle: {
      "background": "#f2f2f2"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "h-filter"
  }, [_c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Daterange")]), _c('date-picker', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "value-type": "format",
      "format": "YYYY-MM-DD",
      "range": "",
      "placeholder": "Select daterange"
    },
    model: {
      value: _vm.search.dateRange,
      callback: function ($$v) {
        _vm.$set(_vm.search, "dateRange", $$v);
      },
      expression: "search.dateRange"
    }
  })], 1), _c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Card Code")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.cardCode,
      expression: "search.cardCode"
    }],
    staticClass: "input outline-dark h34",
    attrs: {
      "type": "text",
      "placeholder": "Card code"
    },
    domProps: {
      "value": _vm.search.cardCode
    },
    on: {
      "change": _vm.filterSearch,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "cardCode", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Account")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.accountId,
      expression: "search.accountId"
    }],
    staticClass: "input outline-dark h34",
    attrs: {
      "type": "text",
      "placeholder": "Account ID"
    },
    domProps: {
      "value": _vm.search.accountId
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.filterSearch.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "accountId", $event.target.value);
      }
    }
  })]), _c('div', {
    staticStyle: {
      "display": "block",
      "position": "relative",
      "text-align": "left",
      "float": "left"
    }
  }, [_c('button', {
    staticClass: "btn btn-info btn-sm mr6 mt25",
    on: {
      "click": _vm.filterSearch
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  }), _vm._v(" Search ")])])])]), _c('div', {
    staticClass: "table-control"
  }, [_c('table', {
    staticClass: "table wh800",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "w50 txt-center"
  }, [_vm._v("Acc.ID")]), _c('th', {
    staticClass: "w70 txt-center"
  }, [_vm._v("Profile")]), _c('th', [_vm._v("Card Code")]), _c('th', [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "300px"
    }
  }, [_vm._v("Type")]), _c('th', {
    attrs: {
      "width": "150px"
    }
  }, [_vm._v("Location")]), _c('th', {
    staticClass: "w100 txt-center"
  }, [_vm._v("Status")]), _c('th', {
    staticStyle: {
      "width": "150px"
    }
  }, [_vm._v("Created At")]), _vm.checkoutPermission('GET_TRAINING_TICKET_DETAIL') ? _c('th', {
    staticClass: "w100 txt-center"
  }, [_vm._v(" Action")]) : _vm._e()])]), _vm.checkoutPermission('LIST_TRAINING_TICKET') ? _c('tbody', _vm._l(_vm.data.trainingTicket, function (trainingTicket, index) {
    var _trainingTicket$accou;
    return _c('tr', {
      key: (trainingTicket, index),
      staticClass: "hover-list"
    }, [_c('td', {
      staticClass: "txt-center"
    }, [_vm._v(_vm._s(trainingTicket === null || trainingTicket === void 0 ? void 0 : trainingTicket.account.id))]), _c('td', {
      staticClass: "txt-center"
    }, [trainingTicket.account && trainingTicket.account.imageUrl ? _c('img', {
      staticStyle: {
        "height": "40px",
        "width": "40px",
        "object-fit": "cover"
      },
      attrs: {
        "src": _vm.getFullImage(trainingTicket.account.imageUrl)
      }
    }) : _c('img', {
      staticStyle: {
        "height": "40px",
        "width": "40px",
        "object-fit": "cover"
      },
      attrs: {
        "src": require('@/assets/images/user_default.jpeg')
      }
    })]), _c('td', [_vm._v(_vm._s((trainingTicket === null || trainingTicket === void 0 ? void 0 : (_trainingTicket$accou = trainingTicket.account) === null || _trainingTicket$accou === void 0 ? void 0 : _trainingTicket$accou.code) || `N/A`))]), trainingTicket.account ? _c('td', [_vm._v(_vm._s(trainingTicket.account.firstName) + " " + _vm._s(trainingTicket.account.lastName) + " ")]) : _vm._e(), _c('td', [_vm._v(_vm._s(trainingTicket.type.name))]), _c('td', [_vm._v(_vm._s(trainingTicket.locationType))]), _c('td', {
      staticClass: "txt-center",
      staticStyle: {
        "text-transform": "capitalize"
      }
    }, [trainingTicket.status == 'pending' ? _c('span', {
      staticClass: "txt-warning"
    }, [_vm._v(_vm._s(trainingTicket.status))]) : trainingTicket.status == 'open' ? _c('span', {
      staticClass: "txt-info"
    }, [_vm._v(_vm._s(trainingTicket.status))]) : trainingTicket.status == 'closed' ? _c('span', {
      staticClass: "txt-danger"
    }, [_vm._v(_vm._s(trainingTicket.status))]) : _c('span', [_vm._v(_vm._s(trainingTicket.status))])]), _c('td', [_vm._v(_vm._s(_vm.$helper.formatDate(trainingTicket.createdAt)))]), _c('td', {
      staticClass: "txt-center"
    }, [_vm.checkoutPermission('GET_TRAINING_TICKET_DETAIL') ? _c('router-link', {
      attrs: {
        "to": {
          name: 'EditTrainingTicket',
          query: {
            id: trainingTicket.id
          }
        }
      }
    }, [_c('button', {
      staticClass: "btn btn-info btn-xs mr5"
    }, [_c('i', {
      staticClass: "fa fa-eye"
    })])]) : _vm._e()], 1)]);
  }), 0) : _vm._e()]), _vm.isFetching ? _c('TableLoading') : _vm._e(), _vm.isNoData ? _c('NoItem') : _vm._e()], 1), _c('div', {
    staticClass: "total ph12"
  }, [_c('div', {
    staticClass: "ul-xl-li go-left"
  }, [_vm._v(" Total : " + _vm._s(_vm.data.pagination.total) + " ")]), _vm.data.pagination.totalPage > 0 ? _c('Pagination', {
    attrs: {
      "pagination": _vm.data.pagination.totalPage
    }
  }) : _vm._e()], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "go-left mt25",
    staticStyle: {
      "display": "inline-block",
      "position": "relative"
    }
  }, [_c('b', [_vm._v("Training")])]);

}]

export { render, staticRenderFns }