var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_vm.productDetail.id ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 pr12"
  }, [_c('div', {
    staticClass: "row animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white pb-20 radius6"
  }, [_c('div', {
    staticClass: "col-6 pr6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Card Code:")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text",
      "placeholder": "Card Code"
    },
    domProps: {
      "value": _vm.productDetail.cardCode
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Item Code:")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text",
      "placeholder": "Item Code"
    },
    domProps: {
      "value": _vm.productDetail.itemCode
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Price:")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.formatPrice(_vm.productDetail.prices.priceAfterDiscount)
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Image:")]), _c('div', {
    staticClass: "media-container",
    staticStyle: {
      "position": "relative",
      "width": "fit-content"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "border-radius": "4px",
      "border": "1px solid #ccc",
      "padding": "3px",
      "display": "inline-block"
    }
  }, [_vm.body.imageUrl ? _c('div', {
    staticClass: "go-left pointer display-flex",
    staticStyle: {
      "width": "175px"
    }
  }, [_c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.getFullImage(_vm.body.imageUrl),
      "height": "35px"
    }
  }), _c('div', {
    staticClass: "remove-image"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/cancel1.svg'),
      "alt": "",
      "width": "10px"
    },
    on: {
      "click": function ($event) {
        _vm.body.imageUrl = '';
      }
    }
  })]), _c('div', {
    staticClass: "p-file-name"
  }, [_c('span', {
    staticClass: "font12 p-file-name mr6",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getNameFromUrl(_vm.body.imageUrl)) + " ")])])]) : _c('div', {
    staticClass: "go-left"
  }, [_c('img', {
    staticClass: "attachment mh6",
    attrs: {
      "src": require('@/assets/icons/Vectorpaperclip.png'),
      "width": "8px"
    }
  }), _c('span', {
    staticClass: "font12",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(" No file selected ")])]), _c('label', {
    staticClass: "btn btn-secondary btn-sm go-right mt2 mr2",
    attrs: {
      "id": "image"
    },
    on: {
      "click": function ($event) {
        return _vm.chooseImage('single');
      }
    }
  }, [_vm._v(" Choose file ")])])])])])]), _c('div', {
    staticClass: "col-6 pl6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Customer:")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.productDetail.customer.firstName + ' ' + _vm.productDetail.customer.lastName
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Foreign Item Name:")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.productDetail.foreignItemName
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Min Qty Order:")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.productDetail.minQtyOrder
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Points:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.body.points,
      expression: "body.points",
      modifiers: {
        "number": true
      }
    }],
    staticClass: "input outline-dark",
    attrs: {
      "type": "number",
      "placeholder": "input points..."
    },
    domProps: {
      "value": _vm.body.points
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "points", _vm._n($event.target.value));
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Status: ")]), _c('div', {
    staticClass: "select"
  }, [_c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.status,
      expression: "body.status"
    }],
    attrs: {
      "type": "radio",
      "id": "active",
      "value": "active"
    },
    domProps: {
      "checked": _vm._q(_vm.body.status, "active")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body, "status", "active");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "active"
    }
  }, [_vm._v("Active")])]), _c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.status,
      expression: "body.status"
    }],
    attrs: {
      "type": "radio",
      "id": "inactive",
      "value": "inactive"
    },
    domProps: {
      "checked": _vm._q(_vm.body.status, "inactive")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body, "status", "inactive");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "inactive"
    }
  }, [_vm._v("Inactive")])])])])]), _c('div', {
    staticClass: "row"
  }, [_vm.loading.isCreating ? _c('button', {
    staticClass: "btn btn-md btn-info go-right"
  }, [_vm._v(" Processing... ")]) : _c('button', {
    staticClass: "btn btn-md btn-info go-right",
    on: {
      "click": _vm.submitEdit
    }
  }, [_vm._v(" Submit ")])]), _vm.display.imagePreview ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal"
  }, [_vm.body.image.file ? _c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.fileToPath(_vm.body.image.file)
    }
  }) : _c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.getFullImage(_vm.data.media.url)
    }
  })])]) : _vm._e(), _vm.display.media.single ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal-md"
  }, [_c('Media', {
    attrs: {
      "type": "single",
      "method": _vm.closeModal,
      "folder": "catelog"
    },
    on: {
      "existedMedia": _vm.existedMediaCallback
    }
  })], 1)]) : _vm.display.media.multiple ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal-md"
  }, [_c('Media', {
    attrs: {
      "type": "multiple",
      "method": _vm.closeModal,
      "folder": "catelog"
    },
    on: {
      "existedMultiMedia": _vm.existedMultiMediaCallback
    }
  })], 1)]) : _vm._e()])])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }