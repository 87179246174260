import Multiselect from "vue-multiselect";
import Service from "../../../../utils/api/service";
import _debounce from 'lodash.debounce';
export default {
  name: "CreateTopSelection",
  data() {
    return {
      isCreating: false,
      isSearching: false,
      display: {
        imagePreview: false,
      },
      data: {
        productList: []
      },
      body: {
        product: [],
      },
    };
  },
  components: { Multiselect },
  computed: {
    debouncedOnSearchProducts() {
      return _debounce(this.searchProducts, 500);
    }
  },
  created() {
    this.searchProducts();
  },

  watch: {
    "$route.fullPath": function () {
      this.searchProducts();
    },
  },

  methods: {
    goBack() {
      this.$router.push({
        name: "ListTopSelection",
        query: {
          page: 1,
          limit: 10,
        },
      });
    },

    async searchProducts(query) {
      this.isSearching = true
      let search = { search: query }
      let setQuery = this.$helper.filterKeyMapping({ page: query?.page || 1, ...search })
      let params = this.$helper.queryParam(setQuery)

      await Service.getAllProducts(params).then((response) => {
        this.isSearching = false
        if (response?.statusCode) {
          if (response?.statusCode === "4410") {
            Service.refreshToken().then((response) => {
              if (response === "ok") {
                this.searchProducts();
              }
            });
          } else {
            this.$toasted.show(response?.message.capitalize());
          }
        } else {
          this.data.productList = response?.data;
        }
      });
    },

    subminCreateTopSelection() {
      if (!this.body.product) {
        this.$toasted.show("Product cannot be empty!");
      } else {
        this.createTopSelection();
      }
    },

    createTopSelection() {
      let pid = []
      this.body.product && this.body.product.length > 0 && this.body.product.map(item => {
        return pid.push(item?.id)
      })
      let body = { productIds: pid }
      this.isCreating = true

      Service.createTopSelection(body).then((response) => {
        this.isCreating = false
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.createTopSelection();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.resetBody();
          this.$toasted.show("Top selection product has been created.");
        }
      });
    },

    resetBody() {
      this.body = {
        product: []
      };
    },
  },
};
