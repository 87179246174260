import Pagination from "./../../../../components/share/pagination";
import Service from "./../../../../utils/api/service";
import Helper from "./../../../../utils/global/func";
import TableLoading from "./../../../../components/share/table-loading";
import NoItem from "./../../../../components/share/table-no-item";
import { mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "ListCustomer",
  data() {
    return {
      isToggle: false,
      isFetching: true,
      isDeleting: false,
      isNoData: false,
      displayModalDetail: false,
      search: {
        email: "",
        search: "",
        phone: "",
        dateRange: [],
        status: "",
        sort: "",
        code: "",
        customerGroupId: "",
      },
      data: {
        fields: {
          index: "",
          status: "",
        },
        customers: [],
        customer: "",
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
      modal: {
        delete: false,
      },
      customerGroups: [],
    };
  },
  components: {
    Pagination,
    TableLoading,
    NoItem,
    DatePicker,
  },
  created() {
    this.setParams();
    this.getCustomers();
    this.getCustomerGroups();
  },
  watch: {
    "$route.fullPath": function () {
      this.setParams();
      this.getCustomers();
    },
  },
  mounted() {},
  computed: {
    ...mapState(["permissions"]),
  },
  methods: {
    setParams() {
      let startDate =
        (this.$root.$route.query?.startDate &&
          this.$root.$route.query?.startDate + "T00:00:00.000") ||
        "";
      let endDate =
        (this.$root.$route.query?.startDate &&
          this.$root.$route.query?.startDate + "T23:59:59.999") ||
        "";
      this.search = this.$helper.setParams(this.search, {
        ...this.$root.$route.query,
        startDate: startDate,
        endDate: endDate,
      });
    },

    checkoutPermission(permissionName) {
      if (this.permissions) {
        let result = false;
        this.permissions.find((item) => {
          item.permissions.find((permission) => {
            if (permission.name == permissionName) {
              result = true;
            }
          });
        });

        return result;
      }
    },

    getCustomerGroups() {
      let params = "?limit=100&offset=0";
      Service.getCustomerGroups(params).then((response) => {
        if (response?.statusCode) {
          if (response?.statusCode === "4410") {
            Service.refreshToken().then((response) => {
              if (response === "ok") {
                this.getCustomerGroups();
              }
            });
          } else if (response?.statusCode === "403") {
            this.customerGroups = [];
            this.$toasted.show(response?.message.capitalize());
          } else {
            this.$toasted.show(response?.message.capitalize());
          }
        } else {
          if (response?.data.length > 0) {
            this.customerGroups = response?.data;
          } else {
            this.customerGroups = [];
          }
        }
      });
    },

    async filterSearch() {
      if (this.search.phone) {
        this.search.phone = this.search.phone.replace(/\s/g, "");
      }
      let query = this.$helper.filterKeyMapping({
        limit: 10,
        page: 1,
        ...this.search,
      });
      await this.$router.push({ path: "/customer", query }).catch(() => {});
    },

    getCustomers() {
      this.isFetching = true;
      let query = this.$root.$route.query;
      let setQuery = this.$helper.filterKeyMapping({
        page: query?.page || 1,
        ...query,
      });
      let params = this.$helper.queryParam(setQuery);

      Service.getCustomers(params).then((response) => {
        this.isFetching = false;
        const errorCode = ["500", 500];
        if (errorCode.includes(response?.statusCode)) {
          this.isNoData = true;
        }

        if (response?.statusCode) {
          if (response?.statusCode === "4410") {
            Service.refreshToken().then((response) => {
              if (response === "ok") {
                this.getCustomers();
              }
            });
          } else if (response?.statusCode === "403") {
            this.isNoData = true;
            this.data.customers = [];
            this.$toasted.show(response?.message.capitalize());
          } else {
            this.$toasted.show(response?.message.capitalize());
          }
        } else {
          if (response.data.length > 0) {
            this.isNoData = false;
            this.data.customers = response.data;
            this.data.pagination = Helper.calculatePagination(response.meta);
          } else {
            this.data.customers = [];
            this.isNoData = true;
            this.resetPagination();
          }
        }
      });
    },

    getCustomerDetail(id) {
      this.displayModalDetail = true;
      let query = "?address=true";
      Service.getCustomer(id, query).then((response) => {
        if (response?.statusCode) {
          if (response?.statusCode === "4410") {
            Service.refreshToken().then((response) => {
              if (response === "ok") {
                this.getCustomerDetail();
              }
            });
          }
        } else {
          this.data.customer = response.data;
        }
      });
    },

    popup(status, index) {
      this.data.fields.index = index;
      this.data.fields.status = status;
      this.modal.delete = true;
    },

    confirmBlock() {
      this.isDeleting = true;
      let body = {
        status:
          this.data.customers[this.data.fields.index].status === "active"
            ? "inactive"
            : "active",
      };
      let id = this.data.customers[this.data.fields.index].id;
      Service.blockCustomer(id, body).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.confirmBlock();
              }
            });
          } else {
            this.isDeleting = false;
            this.$toasted.show(response.message.capitalize());
            // this.closeModal();
          }
        } else {
          this.isDeleting = false;
          this.data.customers[this.data.fields.index].status === "inactive"
            ? this.$toasted.show("Successfully Unblock")
            : this.$toasted.show("Successfully Block");
          this.data.customers[this.data.fields.index].status = body.status;
          this.closeModal();
        }
      });
    },

    closeModal() {
      this.modal = {
        delete: false,
      };
    },

    resetPagination() {
      this.data.pagination = {
        limit: 10,
        page: 1,
        total: 0,
        totalPage: 0,
      };
    },

    getFullImage(path) {
      // return process.env.VUE_APP_BASE_URL_IMAGE + path;
      let tarea = path;
      if (
        tarea !== null &&
        tarea !== undefined &&
        ((tarea && tarea.match("http://")) ||
          (tarea !== null && tarea !== undefined && tarea.match("https://")))
      ) {
        return path + "?cache=none";
      }
      return (
        (path && process.env.VUE_APP_BASE_URL_IMAGE + path) ||
        require("@/assets/images/user_default.jpeg")
      );
    },
  },
};
