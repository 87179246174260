import helper from "../../../utils/api/helper";
import Service from "./../../../utils/api/service";

export default {
    name: "login",
    data() {
        return {
            showPassword: false,
            body: {
                email: "",
                password: "",
            },
            isLogin: false,
        };
    },
    components: {},
    created() {},
    mounted() {},
    methods: {
        login() {
            this.isLogin = true;
            let body = {
                email: this.body.email,
                password: this.body.password,
            };
            Service.login(body).then((response) => {
                this.isLogin = false;
                
                if (response.statusCode) {
                    this.$toasted.show(response.message.capitalize());
                } else {
                    localStorage.setItem(
                        helper?.tokenEnum()?.access_token,
                        response.data.access_token
                    );
                    localStorage.setItem(
                        helper?.tokenEnum()?.refresh_token,
                        response.data.refresh_token
                    );
                    localStorage.setItem(
                        helper?.tokenEnum()?.account_id,
                        response.data.account_id
                    );
                    location.reload();
                }
            });
        },
    },
};
