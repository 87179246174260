var render = function render(){
  var _vm$quotation, _vm$quotation$product;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row border-top-light-blue bg-white pt10 order-loading",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('div', {
    staticClass: "font18 pr24 pl24",
    staticStyle: {
      "width": "100%",
      "font-weight": "600 !important",
      "margin-bottom": "-22px",
      "display": "inline-block"
    }
  }, [_c('i', {
    staticClass: "fas fa-arrow-left pointer",
    staticStyle: {
      "margin-right": "18px",
      "cursor": "pointer"
    },
    attrs: {
      "title": "Back"
    },
    on: {
      "click": _vm.goBack
    }
  }), _c('span', [_vm._v("Quotation Detail")])]), !_vm.isFetching ? _c('div', {
    attrs: {
      "id": "order-report"
    }
  }, [_c('div', {
    staticClass: "row pt24 pr24 pl24 pt10"
  }, [_c('div', {
    staticClass: "col-6 col-md-6 p4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(0), _c('li', [_vm._m(1), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.quotation.id))])]), _c('li', [_vm._m(2), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.quotation.transactionNumber))])]), _c('li', [_vm._m(3), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.quotation.businessType))])]), _c('li', [_vm._m(4), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.$helper.formatDate(_vm.quotation.createdAt)) + " ")])]), _c('li', [_vm._m(5), _c('div', {
    staticClass: "col-7 text-capitalize"
  }, [_vm._v(": " + _vm._s(_vm.quotation.status))])]), _c('li', [_vm._m(6), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.quotation.remark))])])])])]), _c('div', {
    staticClass: "col-6 col-md-6"
  }, [_c('div', {
    staticClass: "row ph4 pt4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(7), _c('li', [_vm._m(8), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.quotation.firstName) + " " + _vm._s(_vm.quotation.lastName))])]), _c('li', [_vm._m(9), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.$helper.formatPhoneNumber(_vm.quotation.phone) || 'N/A') + " ")])]), _c('li', [_vm._m(10), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.quotation.email))])]), _c('li', [_vm._m(11), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.quotation.district.name) + ", " + _vm._s(_vm.quotation.province.name) + ", " + _vm._s(_vm.quotation.country.name) + " ")])]), _c('li', [_vm._m(12), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.quotation.cardCode))])])])])])])]), _c('div', {
    staticClass: "row pr27 pl27 mt12 mb24"
  }, [_c('table', {
    staticClass: "table",
    attrs: {
      "cellspacing": "0"
    }
  }, [_vm._m(13), _vm.quotation.product ? _c('tbody', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.quotation.product.id))]), _c('td', [_vm._v(_vm._s(_vm.quotation.product.name))]), _c('td', {
    attrs: {
      "colspan": "2"
    },
    domProps: {
      "innerHTML": _vm._s((_vm$quotation = _vm.quotation) === null || _vm$quotation === void 0 ? void 0 : (_vm$quotation$product = _vm$quotation.product) === null || _vm$quotation$product === void 0 ? void 0 : _vm$quotation$product.specification)
    }
  })]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }), _c('td', {
    staticClass: "txt-right bold"
  }, [_vm._v("Total")]), _c('td', {
    staticClass: "txt-right bold"
  }, [_c('b', [_vm._v(_vm._s(_vm.$helper.formatPrice(_vm.quotation.priceAfterDiscount)))])])])]) : _vm._e()])])]) : _vm._e(), _vm.isFetching ? _c('TableLoading') : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fa fa-shopping-cart"
  }), _vm._v(" General Information")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5 text-capitalize"
  }, [_c('b', [_vm._v("Quotation ID")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5 text-capitalize"
  }, [_c('b', [_vm._v("Transaction Number")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Business Type")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Created At")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Status")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Remark")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fas fa-user"
  }), _vm._v(" Customer")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Name")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Phone")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Email")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Address")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Card Code")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("ID")]), _c('th', [_vm._v("Product")]), _c('th', {
    attrs: {
      "colspan": "2",
      "width": "120px"
    }
  }, [_vm._v("Description")])])]);

}]

export { render, staticRenderFns }