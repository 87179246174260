import Service from "./../../../utils/api/service";
import Pagination from "@/components/share/pagination";
import Helper from "./../../../utils/global/func";
import TableLoading from "./../../../components/share/table-loading";
import NoItem from "./../../../components/share/table-no-item";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapState } from "vuex";

export default {
  name: "ListNotification",
  data() {
    return {
      isFetching: true,
      isDeleting: false,
      isNoData: false,
      fields: {
        id: "",
        index: "",
      },
      search: {
        dateRange: [],
      },
      display: {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          imagePreview: {
            show: false,
            index: "",
          },
        },
      },
      data: {
        limit: 10,
        detail: {},
        notifications: [],
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
    };
  },
  components: {
    Pagination,
    TableLoading,
    NoItem,
    DatePicker,
  },
  created() {
    this.setParams()
    this.getAllNotification();
  },
  watch: {
    "$route.fullPath": function () {
      this.setParams()
      this.getAllNotification();
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() { },
  methods: {
    setParams() {
      this.search = this.$helper.setParams(this.search, this.$root.$route.query)
    },

    checkoutPermission(permissionName) {
      if (this.permissions) {
        let result = false;
        this.permissions.find((item) => {
          item.permissions.find((permission) => {
            if (permission.name == permissionName) {
              result = true;
            }
          });
        });

        return result;
      }
    },

    getFullImage(path) {
      return process.env.VUE_APP_BASE_URL_IMAGE + path;
    },

    htmlToText(html) {
      return Helper.convertToPlain(html);
    },

    linkPage(type, index) {
      if (index >= 0) {
        this.$router.push({
          name: type,
          query: { id: this.data.notifications[index].id },
        });
      } else
        this.$router.push({
          name: type,
        });
    },

    getAllNotification() {
      this.isFetching = true;
      this.isNoData = false;
      let query = this.$root.$route.query;
      let setQuery = this.$helper.filterKeyMapping({ page: query?.page || 1, ...query })

      setQuery?.startDate && (setQuery.minDate = new Date(setQuery?.startDate).toISOString()) && delete setQuery.startDate
      setQuery?.endDate && (setQuery.maxDate = new Date(setQuery?.endDate).toISOString()) && delete setQuery.endDate

      let params = this.$helper.queryParam(setQuery)

      Service.getAllNotification(params).then((response) => {
        this.isFetching = false;
        if (response?.statusCode) {
          if (response?.statusCode === "4410") {
            Service.refreshToken().then((response) => {
              if (response === "ok") {
                this.getAllNotification();
              }
            });
          } else if (response?.statusCode === "403") {
            this.isNoData = true;
            this.data.notifications = [];
            this.$toasted.show(response?.message.capitalize());
            return
          } else {
            this.$toasted.show(response?.message.capitalize());
            return
          }
        } else {
          if (response?.data.length > 0) {
            this.isNoData = false;
            this.data.notifications = response?.data;
            this.data.pagination = Helper.calculatePagination(response?.meta);
            return
          } else {
            this.data.notifications = [];
            this.isNoData = true;
            return
          }
        }
      });
    },

    async searchNotification() {
      let query = this.$helper.filterKeyMapping({ limit: 10, page: 1, ...this.search })
      await this.$router.push({ query }).catch(() => { });
    },

    popupModalDelete(index) {
      this.display.modal.delete.index = index;
      this.display.modal.delete.show = true;
    },

    deleteNotification() {
      this.isDeleting = true;
      let notificationId = this.data.notifications[
        this.display.modal.delete.index
      ].id;
      Service.deleteNotification(notificationId).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.deleteNotification();
              }
            });
          } else {
            this.isDeleting = false;
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.isDeleting = false;
          this.data.notifications.splice(this.display.modal.delete.index, 1);
          this.data.pagination.total -= 1;
          this.$toasted.show("Notification has been deleted.");
          this.closeModal();
        }
      });
    },

    imagePreview(index) {
      if (this.data.notifications[index].imageUrl) {
        this.display.modal.imagePreview.index = index;
        this.display.modal.imagePreview.show = true;
      }
    },

    closeModal() {
      this.display = {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          imagePreview: {
            show: false,
            index: "",
          },
        },
      };
    },
  },
};
