var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "modal-header"
  }, [_c('span', [_vm._v("Select File")]), _c('span', {
    staticClass: "btn-cancel mt-5 pointer",
    attrs: {
      "title": "Close"
    },
    on: {
      "click": _vm.closeMedia
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/cancel1.svg'),
      "alt": "",
      "width": "15px"
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "flex-row border-botttom"
  }, [_c('div', {
    class: _vm.display.tab === 'server' ? 'active-tab' : '',
    on: {
      "click": function ($event) {
        return _vm.tab('server');
      },
      "change": _vm.getData
    }
  }, [_vm._m(0)]), _c('div', {
    class: _vm.display.tab === 'computer' ? 'active-tab' : '',
    on: {
      "click": function ($event) {
        return _vm.tab('computer');
      }
    }
  }, [_vm._m(1)])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.display.tab === 'server',
      expression: "display.tab === 'server'"
    }],
    staticClass: "modal-header txt-info",
    staticStyle: {
      "max-height": "600px",
      "overflow": "auto"
    }
  }, [_c('div', {
    staticClass: "row ph16"
  }, [_vm.data.mediaSelected && _vm.data.mediaSelected.length > 0 ? _c('div', {
    staticClass: "row",
    staticStyle: {
      "border": "1px solid #eaeaea"
    }
  }, [_c('div', {
    staticClass: "col-12 p12",
    staticStyle: {
      "background": "#eaeaea",
      "z-index": "9"
    }
  }, [_c('span', [_vm._v("Image Selected")]), _c('button', {
    staticClass: "btn btn-xs btn-info go-right",
    on: {
      "click": _vm.submitMediaSelected
    }
  }, [_vm._v(" Done ")]), _c('button', {
    staticClass: "btn btn-xs btn-danger go-right mr6",
    on: {
      "click": _vm.removeAllMediaSelected
    }
  }, [_vm._v(" Clear ")])]), _c('div', {
    staticClass: "row p6"
  }, _vm._l(_vm.data.mediaSelected, function (mediaSelected, mediaSelectedIndex) {
    return _c('div', {
      key: (mediaSelected, mediaSelectedIndex),
      staticClass: "list-photo"
    }, [_c('div', {
      staticClass: "photo"
    }, [_c('div', {
      staticClass: "overlay-image"
    }, [_c('img', {
      staticClass: "image-cancel-right",
      attrs: {
        "src": require('@/assets/images/cancel.svg'),
        "alt": "",
        "title": "remove",
        "width": "22px"
      },
      on: {
        "click": function ($event) {
          return _vm.removeMediaSelected(mediaSelectedIndex);
        }
      }
    })]), _c('img', {
      staticClass: "display-image",
      attrs: {
        "src": _vm.getFullImage(mediaSelected.url),
        "alt": ""
      }
    })])]);
  }), 0)]) : _vm._e()]), _c('div', {
    staticClass: "row pv24 select-folder"
  }, [_c('span', [_vm._v("Select Folder")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.folder,
      expression: "body.folder"
    }],
    staticClass: "outline-dark",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "folder", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.filterDirectory]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All")]), _vm._l(_vm.mediaDirectory, function (director, index) {
    return _c('option', {
      key: (director, index),
      domProps: {
        "value": director.code
      }
    }, [_vm._v(_vm._s(director.name) + " ")]);
  })], 2)]), _vm._l(_vm.data.media, function (image, index) {
    return _c('div', {
      key: index,
      staticClass: "list-photo"
    }, [_c('div', {
      staticClass: "photo"
    }, [_c('div', {
      staticClass: "overlay-image"
    }, [_c('div', {
      staticClass: "overlay-image",
      attrs: {
        "title": "select"
      },
      on: {
        "click": function ($event) {
          return _vm.selectMedia(index);
        }
      }
    }), _c('img', {
      staticClass: "image-cancel-right",
      attrs: {
        "src": require('@/assets/images/cancel.svg'),
        "alt": "",
        "title": "remove",
        "width": "22px"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteItem(index);
        }
      }
    })]), _c('img', {
      staticClass: "display-image",
      attrs: {
        "src": _vm.getFullImage(image.url),
        "alt": ""
      }
    })])]);
  }), _c('label', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && _vm.data.pagination && _vm.data.pagination.total > 10 && _vm.data.pagination.limit < _vm.data.pagination.total,
      expression: "!loading && data.pagination && data.pagination.total > 10 && data.pagination.limit < data.pagination.total "
    }],
    staticClass: "btn btn-md row show-more",
    on: {
      "click": _vm.showMore
    }
  }, [_vm._v(" Show more ")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "no-data"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/loading1.gif'),
      "alt": "",
      "width": "80px"
    }
  })]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && _vm.data.pagination && _vm.data.pagination.total == 0,
      expression: "!loading && data.pagination && data.pagination.total == 0 "
    }],
    staticClass: "no-data"
  }, [_c('p', [_vm._v("No Item !!")])])], 2), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.display.tab === 'computer',
      expression: "display.tab === 'computer'"
    }],
    staticClass: "bg-white box-list-photo p20"
  }, [_c('div', {
    staticClass: "pt16 ph16"
  }, [_c('div', {
    staticClass: "row mb12",
    staticStyle: {
      "border": "1px solid #eaeaea"
    }
  }, [_c('div', {
    staticClass: "col-12 p12 title",
    staticStyle: {
      "background": "#eaeaea"
    }
  }, [_c('span', [_vm._v("Add New Media")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.directory,
      expression: "body.directory"
    }],
    staticClass: "outline-dark",
    attrs: {
      "id": "select-directory"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "directory", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v("Select Directory")]), _vm._l(_vm.mediaDirectory, function (director, index) {
    return _c('option', {
      key: (director, index),
      domProps: {
        "value": director.code
      }
    }, [_vm._v(_vm._s(director.name) + " ")]);
  })], 2)]), _c('div', {
    staticClass: "row p12"
  }, [_vm._l(_vm.body.image.fileList, function (media, index) {
    return _c('div', {
      key: index,
      staticClass: "list-photo"
    }, [_c('div', {
      staticClass: "photo"
    }, [_c('div', {
      staticClass: "overlay-image"
    }, [_c('img', {
      staticClass: "image-cancel-right",
      attrs: {
        "src": require('@/assets/images/cancel.svg'),
        "alt": "",
        "title": "remove",
        "width": "22px"
      },
      on: {
        "click": function ($event) {
          return _vm.removeItem(index);
        }
      }
    })]), _c('img', {
      staticClass: "display-image",
      attrs: {
        "src": media ? _vm.fileToPath(media) : require('@/assets/images/noImage.jpg')
      }
    })])]);
  }), _c('label', {
    staticClass: "p6 border pointer mt5",
    staticStyle: {
      "display": "inline-block"
    },
    attrs: {
      "for": 'chooseImage',
      "id": "image"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "80px",
      "height": "80px",
      "object-fit": "cover"
    },
    attrs: {
      "src": require('@/assets/images/addImage.jpg')
    }
  })]), _c('input', {
    ref: 'chooseImage',
    attrs: {
      "hidden": "",
      "type": "file",
      "id": 'chooseImage',
      "accept": "image/*, video/*",
      "multiple": ""
    },
    on: {
      "change": function ($event) {
        return _vm.chooseImage($event);
      }
    }
  })], 2), _c('div', {
    staticClass: "col-12 p12 flex-row",
    staticStyle: {
      "border-top": "1px solid #eaeaea"
    }
  }, [!_vm.uploading ? _c('button', {
    staticClass: "btn btn-info btn-md go-right",
    on: {
      "click": _vm.submitFiles
    }
  }, [_vm._v(" Submit ")]) : _c('button', {
    staticClass: "btn btn-info btn-md go-right"
  }, [_vm._v(" Uploading... ")])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p8 mr5 tab-hover ml7"
  }, [_c('p', {
    staticClass: "mb0"
  }, [_vm._v("Choose From Server")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p8 mr5 tab-hover"
  }, [_c('p', {
    staticClass: "mb0"
  }, [_vm._v("Upload From Computer")])]);

}]

export { render, staticRenderFns }