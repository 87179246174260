var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Filter Service Ticket Report ")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "split"
  }, [_vm._m(0), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.serviceTypeId,
      expression: "body.serviceTypeId"
    }],
    staticClass: "input-container",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "serviceTypeId", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Service Type")]), _vm._l(_vm.data.serviceTypes, function (serviceType, serviceTypeIndex) {
    return _c('option', {
      key: serviceType.id,
      domProps: {
        "value": serviceType.id
      }
    }, [_vm._v(_vm._s(serviceType.name))]);
  })], 2)]), _c('div', {
    staticClass: "split"
  }, [_vm._m(1), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.clickOutsideCustomerTextbox,
      expression: "clickOutsideCustomerTextbox"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.body.customerName,
      expression: "body.customerName"
    }],
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.body.customerName
    },
    on: {
      "click": function ($event) {
        _vm.isCustomerDropdown = true;
      },
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "customerName", $event.target.value);
      }, _vm.debouncedOnSearchCustomers]
    }
  }), _vm.body.customerId ? _c('i', {
    staticClass: "fa fa-times",
    staticStyle: {
      "position": "absolute",
      "top": "9px",
      "right": "10px",
      "cursor": "pointer"
    },
    on: {
      "click": _vm.onUnselectCustomer
    }
  }) : _vm._e(), _vm.isCustomerDropdown ? _c('div', {
    staticClass: "block-customer-dropdown"
  }, _vm._l(_vm.data.customers, function (customer, customerIndex) {
    return _c('div', {
      key: customer.id,
      staticClass: "row p6 customer-item",
      on: {
        "click": function ($event) {
          return _vm.onSelectCustomer(customerIndex);
        }
      }
    }, [_vm._v(" " + _vm._s(customer.firstName) + " " + _vm._s(customer.lastName) + " ")]);
  }), 0) : _vm._e()])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(2), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.code,
      expression: "body.code"
    }],
    attrs: {
      "type": "text",
      "placeholder": "BP code, E-com code"
    },
    domProps: {
      "value": _vm.body.code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "code", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(3), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.equipmentInfoId,
      expression: "body.equipmentInfoId"
    }],
    staticClass: "input-container",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "equipmentInfoId", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Equipment Info")]), _vm._l(_vm.data.equipmentInfo, function (equipmentInfo, equipmentInfoIndex) {
    return _c('option', {
      key: equipmentInfo.id,
      domProps: {
        "value": equipmentInfo.id
      }
    }, [_vm._v(_vm._s(equipmentInfo.name))]);
  })], 2)]), _c('div', {
    staticClass: "split"
  }, [_vm._m(4), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.brandId,
      expression: "body.brandId"
    }],
    staticClass: "input-container",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "brandId", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Brand")]), _vm._l(_vm.data.brands, function (brand, brandIndex) {
    return _c('option', {
      key: brand.id,
      domProps: {
        "value": brand.id
      }
    }, [_vm._v(_vm._s(brand.name))]);
  })], 2)]), _c('div', {
    staticClass: "split"
  }, [_vm._m(5), _c('date-picker', {
    attrs: {
      "value-type": "format",
      "placeholder": "Select date",
      "format": "YYYY-MM-DD"
    },
    model: {
      value: _vm.body.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.body, "startDate", $$v);
      },
      expression: "body.startDate"
    }
  })], 1), _c('div', {
    staticClass: "split"
  }, [_vm._m(6), _c('date-picker', {
    attrs: {
      "value-type": "format",
      "placeholder": "Select date",
      "format": "YYYY-MM-DD"
    },
    model: {
      value: _vm.body.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.body, "endDate", $$v);
      },
      expression: "body.endDate"
    }
  })], 1), _c('div', {
    staticClass: "split"
  }, [_vm._m(7), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.status,
      expression: "body.status"
    }],
    staticClass: "input-container",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Status")]), _c('option', {
    attrs: {
      "value": "Closed"
    }
  }, [_vm._v("Closed")]), _c('option', {
    attrs: {
      "value": "Open"
    }
  }, [_vm._v("Open")]), _c('option', {
    attrs: {
      "value": "Pending"
    }
  }, [_vm._v("Pending")]), _c('option', {
    attrs: {
      "value": "Check-up"
    }
  }, [_vm._v("Check Up")]), _c('option', {
    attrs: {
      "value": "Cancel"
    }
  }, [_vm._v("Cancel")])])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(8), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.provider,
      expression: "body.provider"
    }],
    staticClass: "input-container",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "provider", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Provider")]), _c('option', {
    attrs: {
      "value": "ecommerce"
    }
  }, [_vm._v("Ecommerce")]), _c('option', {
    attrs: {
      "value": "sap"
    }
  }, [_vm._v("SAP")])])])]), _c('div', {
    staticClass: "col-12 pv6 mb12",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('div', [!_vm.isGenerating ? _c('button', {
    staticClass: "btn btn-theme-grey-light btn-xs btn-create",
    on: {
      "click": function ($event) {
        return _vm.generateReport('');
      }
    }
  }, [_c('span', [_vm._v("Generate")])]) : _c('button', {
    staticClass: "btn btn-info btn-xs btn-create"
  }, [_c('span', [_vm._v("Generating...")])])])])])]), _vm.isGenerated ? _c('div', {
    staticClass: "row bg-white border-top-light-blue mt26"
  }, [_c('input', {
    attrs: {
      "type": "file",
      "id": "file",
      "hidden": ""
    }
  }), _c('header', {
    staticClass: "navbar r-navbar"
  }, [_vm._m(9), _vm.table.headers.length > 0 && _vm.table.body.length > 0 ? _c('div', {
    staticClass: "con-search-btn mt12",
    staticStyle: {
      "position": "unset"
    }
  }, [!_vm.isExporting ? _c('button', {
    staticClass: "btn btn-theme-grey-light btn-xs btn-create",
    on: {
      "click": function ($event) {
        return _vm.generateReport('export');
      }
    }
  }, [_vm._v(" Export File ")]) : _c('button', {
    staticClass: "btn btn-theme-grey-light btn-xs btn-create"
  }, [_c('span', [_vm._v("Exporting...")])])]) : _vm._e()]), _c('div', {
    staticClass: "table-control p12"
  }, [_c('table', {
    staticClass: "table wh800",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('thead', [_c('tr', _vm._l(_vm.table.headers, function (item) {
    return _c('th', {
      staticClass: "table-header"
    }, [_vm._v(_vm._s(item))]);
  }), 0)]), _c('tbody', _vm._l(_vm.table.body, function (item, index) {
    return _c('tr', {
      key: (item, index),
      staticClass: "hover-list"
    }, _vm._l(item, function (key, keyIndex) {
      return _c('td', [_vm._v(" " + _vm._s(keyIndex == 'Created Date' || keyIndex == 'Updated Date' ? _vm.ExcelDateToJSDate(key) : key) + " ")]);
    }), 0);
  }), 0)])])]) : _vm._e(), _vm.table.body.length == 1 ? _c('div', {
    staticClass: "row"
  }, [_c('p', {
    staticClass: "txt-center",
    staticStyle: {
      "line-height": "490px"
    }
  }, [_vm._v("No Report")])]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Service Type :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Customer Name :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Code :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Equipment Info :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Equipment Brand :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Start Date :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("End Date :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Status :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Provider :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ul-xl"
  }, [_c('div', {
    staticClass: "ul-xl-li mt12"
  }, [_vm._v(" Reports ")])]);

}]

export { render, staticRenderFns }