var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row txt-center c-nodata"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.isImageFolder ? _c('div', {
    staticClass: "image-folder"
  }, [_c('img', {
    staticClass: "filter-element",
    staticStyle: {
      "width": "auto",
      "height": "150px"
    },
    attrs: {
      "src": _vm.folderImage,
      "alt": "",
      "trigger-disabled": "true"
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "col-12 mt10 txt-center c-nodata-footer"
  }, [_vm.label ? _c('b', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _vm.title ? _c('span', {
    staticClass: "pl10 mt10 mb20",
    staticStyle: {
      "max-width": "350px"
    }
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }