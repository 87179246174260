var render = function render(){
  var _vm$data, _vm$$root$$route$quer, _vm$data2, _vm$data3, _vm$data4, _vm$data5, _vm$data6, _vm$data7, _vm$data8, _vm$data9, _vm$data10, _vm$data11, _vm$data12, _vm$data13, _vm$data14, _vm$data15, _vm$data15$customerGr, _vm$data16, _vm$data16$customerGr;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.checkoutPermission('GET_CUSTOMER_PROFILE') ? _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "title with-back-icon"
  }, [_c('i', {
    staticClass: "fas fa-angle-double-left mr15",
    on: {
      "click": _vm.goBack
    }
  }), _vm._v(" Customer Account Details ")]), _vm.isLoading && Object.keys(_vm.data).length <= 0 ? _c('div', {
    staticClass: "row txt-center p50 bg-white"
  }, [_vm._v(" Loading... ")]) : _c('div', {
    staticClass: "content",
    staticStyle: {
      "border-bottom": "none",
      "padding": "20px 0"
    }
  }, [(_vm$data = _vm.data) !== null && _vm$data !== void 0 && _vm$data.statusCode || _vm.undefinedAccount ? _c('div', {
    staticClass: "row txt-center p50 bg-white account-not-found"
  }, [_c('p', [_vm._v("Your customer account request - "), _c('b', [_vm._v(_vm._s((_vm$$root$$route$quer = _vm.$root.$route.query) === null || _vm$$root$$route$quer === void 0 ? void 0 : _vm$$root$$route$quer.code))]), _vm._v(" - did not match any documents.")]), _c('span', {
    staticClass: "back-span",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v("Click here")]), _c('div', {
    staticClass: "back-button"
  }, [_c('img', {
    staticClass: "pointer",
    attrs: {
      "src": require('@/assets/icons/searchnotfound.svg'),
      "alt": ""
    }
  })])]) : _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6 col-md-6 p4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(0), _c('li', {
    staticClass: "profile",
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.getFullImage((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.imageUrl) || require('@/assets/images/user_default.jpeg'),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s([((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.firstName) || 'N/A', ((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.lastName) || 'N/A'].join(' ')) + " ")]), _c('li', {
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_c('i', {
    staticClass: "fas fa-venus-mars"
  }), _vm._v(_vm._s(((_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : _vm$data5.gender) || 'N/A'))]), _c('li', {
    staticStyle: {
      "text-transform": "capitalize"
    }
  }, [_c('i', {
    staticClass: "fas fa-envelope"
  }), _vm._v(_vm._s(((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : _vm$data6.email) || 'N/A'))]), _c('li', [_c('i', {
    staticClass: "fa fa-phone color-999"
  }), _vm._v(_vm._s(_vm.$helper.formatPhoneNumber((_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : _vm$data7.phone) || 'N/A'))]), _c('li', [_c('i', {
    staticClass: "fas fa-share-alt"
  }), _vm._v(_vm._s(((_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : _vm$data8.provider) || 'N/A'))]), _c('li', [_c('i', {
    staticClass: "fas fa-user"
  }), _vm._v("Business name : " + _vm._s(((_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : _vm$data9.bpName) || 'N/A'))]), _c('li', [_c('i', {
    staticClass: "fas fa-barcode"
  }), _vm._v("Code : " + _vm._s(((_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : _vm$data10.code) || 'N/A'))]), _c('li', [_vm._v("Type : " + _vm._s(((_vm$data11 = _vm.data) === null || _vm$data11 === void 0 ? void 0 : _vm$data11.type) || 'N/A'))]), _c('li', [_vm._v("Total Point : " + _vm._s(((_vm$data12 = _vm.data) === null || _vm$data12 === void 0 ? void 0 : _vm$data12.totalPoints) || '0'))]), _c('li', [_vm._v("status : " + _vm._s(((_vm$data13 = _vm.data) === null || _vm$data13 === void 0 ? void 0 : _vm$data13.status) || 'N/A'))]), _c('li', [_c('i', {
    staticClass: "fas fa-calendar-alt"
  }), _vm._v("Joined : " + _vm._s(_vm.$helper.formatDate((_vm$data14 = _vm.data) === null || _vm$data14 === void 0 ? void 0 : _vm$data14.createdAt) || 'N/A'))])])])]), _c('div', {
    staticClass: "col-6 col-md-6 p4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(1), _c('li', [_c('b', [_vm._v("Name : ")]), _vm._v(_vm._s(((_vm$data15 = _vm.data) === null || _vm$data15 === void 0 ? void 0 : (_vm$data15$customerGr = _vm$data15.customerGroup) === null || _vm$data15$customerGr === void 0 ? void 0 : _vm$data15$customerGr.name) || 'N/A'))]), _c('li', [_c('b', [_vm._v("Badge: ")]), _c('img', {
    attrs: {
      "src": _vm.getFullImage((_vm$data16 = _vm.data) === null || _vm$data16 === void 0 ? void 0 : (_vm$data16$customerGr = _vm$data16.customerGroup) === null || _vm$data16$customerGr === void 0 ? void 0 : _vm$data16$customerGr.badge),
      "width": "330px",
      "alt": ""
    }
  })])])])])])])])])])]) : _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "content",
    staticStyle: {
      "border": "none",
      "padding": "20px 0"
    }
  }, [_c('div', {
    staticClass: "row txt-center p20 bg-white account-not-found"
  }, [_c('p', [_vm._v("You don't have the permission to access, try again later.")]), _c('span', {
    staticClass: "back-span",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v("Click here")]), _c('div', {
    staticClass: "back-button"
  }, [_c('img', {
    staticClass: "pointer",
    attrs: {
      "src": require('@/assets/icons/searchnotfound.svg'),
      "alt": ""
    }
  })])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fas fa-user"
  }), _vm._v(" Customer Account Information")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fas fa-users"
  }), _vm._v(" Customer Group Information")]);

}]

export { render, staticRenderFns }