var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue bg"
  }, [_vm._m(0), _c('div', {
    staticClass: "table-control"
  }, [_c('table', {
    staticClass: "table wh800",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px",
      "padding-left": "18px"
    }
  }, [_vm._v("Name")]), _c('th', [_vm._v("Value")]), _vm.checkoutPermission('UPDATE_SETTING') ? _c('th', {
    staticClass: "txt-center"
  }, [_vm._v("Action")]) : _vm._e()])]), !_vm.isFetching && _vm.checkoutPermission('LIST_SETTING') ? _c('tbody', _vm._l(_vm.data.settings, function (setting, index) {
    return _c('tr', {
      key: (setting, index),
      staticClass: "hover-list"
    }, [_c('td', {
      staticStyle: {
        "padding-left": "20px"
      }
    }, [_vm._v(_vm._s(setting.name))]), _c('td', {
      staticClass: "fw600"
    }, [_vm._v(" " + _vm._s(setting.value.substr(0, 100)) + " ")]), _c('td', {
      staticClass: "txt-center"
    }, [_vm.checkoutPermission('UPDATE_SETTING') ? _c('button', {
      staticClass: "btn btn-info btn-xs",
      on: {
        "click": function ($event) {
          return _vm.displayFormUpdate(index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pen"
    })]) : _vm._e()])]);
  }), 0) : _vm._e()]), _vm.isFetching ? _c('TableLoading') : _vm._e(), _vm.isNoData ? _c('NoItem') : _vm._e()], 1)])]), _vm.update.show ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal-md"
  }, [_vm._m(1), _c('div', {
    staticClass: "modal-body",
    staticStyle: {
      "overflow-y": "hidden",
      "padding": "16px"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(2), _c('div', {
    staticClass: "col-12 pt0 p6 go-left"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.update.data.name,
      expression: "update.data.name"
    }],
    staticClass: "input outline-dark not-allowed",
    attrs: {
      "disabled": "",
      "type": "text",
      "placeholder": "Setting name"
    },
    domProps: {
      "value": _vm.update.data.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.update.data, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_vm._m(3), _c('div', {
    staticClass: "col-12 pt0 p6 go-left"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.update.data.value,
      expression: "update.data.value"
    }],
    staticClass: "input outline-dark",
    attrs: {
      "type": "text",
      "placeholder": "Input value"
    },
    domProps: {
      "value": _vm.update.data.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.update.data, "value", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_vm._m(4), _c('div', {
    staticClass: "col-12 pt0 p6 go-left"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.update.data.description,
      expression: "update.data.description"
    }],
    staticClass: "input outline-dark desc",
    attrs: {
      "type": "text",
      "placeholder": "Input description"
    },
    domProps: {
      "value": _vm.update.data.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.update.data, "description", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "modal-footer border-top"
  }, [!_vm.isUpdating ? _c('button', {
    staticClass: "btn btn-info btn-xs go-right",
    on: {
      "click": _vm.confirmUpdate
    }
  }, [_vm._v(" Confirm ")]) : _c('button', {
    staticClass: "btn btn-info btn-xs go-right"
  }, [_vm._v(" Loading... ")]), _c('button', {
    staticClass: "btn btn-light-secondary btn-xs mr12 go-right",
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" Cancel ")])])])]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "navbar r-navbar"
  }, [_c('div', {
    staticClass: "ul-xl"
  }, [_c('div', {
    staticClass: "ul-xl-li"
  }, [_vm._v(" Settings ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-header border-bottom txt-info"
  }, [_c('span', {
    staticClass: "txt-sm"
  }, [_vm._v("Update Setting")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12 p6 go-left"
  }, [_c('p', {
    staticClass: "mb0"
  }, [_vm._v("Name")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12 p6 go-left"
  }, [_c('p', {
    staticClass: "mb0"
  }, [_vm._v("Value")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12 p6 go-left"
  }, [_c('p', {
    staticClass: "mb0"
  }, [_vm._v("Description")])]);

}]

export { render, staticRenderFns }