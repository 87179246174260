import Service from "../../../../../utils/api/service";
import Media from "../../../../../components/share/media";

export default {
    name: "EditVaraint",
    data() {
        return {
            isUpdating: false,
            customToolbar: [
                ["bold", "italic", "underline"],
                [
                    {
                        list: "ordered",
                    },
                    {
                        list: "bullet",
                    },
                ],
                ["image"],
            ],
            data: {
                options: [],
                detail: {},
            },
            display: {
                imagePreview: false,
                media: false,
            },
            body: {
                description: "",
                imageUrl: "",
                points: "",
                image: {
                    file: "",
                    presign: "",
                },
                specification: "",
            },
        };
    },
    created() {
        this.getVariantDetail(this.$route.query.id);
    },
    components: {
        Media,
    },
    methods: {
        goBack() {
            this.$router.push({
                name: "EditProduct",
                query: {
                    id: this.data?.detail?.productId,
                },
            });
        },

        getVariantDetail(id) {
            Service.getVariantDetail(id).then((response) => {
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.getVariantDetail(id);
                            }
                        });
                    } else {
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    this.data.detail = response.data;
                    this.body.description = response.data.description;
                    this.body.specification = response.data.specification;
                    this.body.points = response.data.points;
                }
            });
        },

        imagePreview() {
            if (
                this.body.image.file ||
                this.data.detail.imageUrl ||
                this.data.media.url
            ) {
                this.display.imagePreview = true;
            }
        },

        getFullImage(path) {
            return process.env.VUE_APP_BASE_URL_IMAGE + path;
        },

        getNameFromUrl(url) {
            return url.split("/").pop();
        },

        fileToPath(file) {
            return window.URL.createObjectURL(file);
        },

        chooseImage() {
            this.display.media = true;
        },

        existedMediaCallback(media) {
            this.data.media = media;
            this.closeModal();
        },

        newMediaCallBack(file) {
            this.body.image.file = file;
            this.closeModal();
        },

        subminUpdateVariant() {
            let validatedMessage = this.validateBody(this.body);
            if (validatedMessage == "ok") {
                this.isUpdating = true;
                if (this.body.image.file) {
                    this.uploadPresign();
                } else {
                    this.updateVariant();
                }
            } else {
                this.$toasted.show(validatedMessage);
            }
        },

        validateBody(data) {
            if (!data.description) {
                return "Description type cannot be empty!";
            } else {
                return "ok";
            }
        },

        uploadPresign() {
            let body = {
                media: [
                    {
                        ext: this.body.image.file.type.split("/").pop(),
                        type: "option",
                        filename: this.body.image.file.name,
                    },
                ],
            };
            Service.uploadPresign(body).then((response) => {
                this.body.image.presign = response.data;
                this.uploadFile(this.body.image.file, false);
            });
        },

        async uploadFile(file, isFromEditor) {
            if (file) {
                let uploadUrl = this.body.image.presign[0].uploadUrl;
                await Service.uploadMedia(uploadUrl, file, file.type).then(
                    (response) => {
                        if (response == "ok") {
                            if (!isFromEditor) {
                                this.updateVariant();
                                this.addMedia();
                            }
                        } else {
                            this.$toasted.show("File upload fail!");
                        }
                    }
                );
            } else {
                this.$toasted.show("File cannot be empty!");
            }
        },

        addMedia() {
            let file = this.body.image.file;
            let body = {
                fileName: file.name,
                url: this.body.image.presign
                    ? this.body.image.presign[0].key
                    : "",
                type: file.type.split("/")[0].split("/")[0],
                folder: "catelog",
                status: "active",
            };

            Service.addMedia(body);
        },

        updateVariant() {
            let id = this.$route.query.id;
            if (this.body.image.file) {
                this.data.detail.imageUrl = this.body.image.presign
                    ? this.body.image.presign[0].key
                    : "";
            } else if (this.data.media) {
                this.data.detail.imageUrl = this.data.media.url;
            }

            let body = {
                description: this.body.description,
                imageUrl: this.data.detail.imageUrl,
                specification: this.body.specification,
                points: this.body.points || 0,
            };

            Service.updateVariant(id, body).then((response) => {
                this.isUpdating = false;
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.updateVariant();
                            }
                        });
                    } else {
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    this.goBack();
                    this.$toasted.show("Variant value has been updated.");
                }
            });
        },
        handleImageAdded(file, Editor, cursorLocation) {
            let body = {
                media: [
                    {
                        ext: file.type.split("/").pop(),
                        type: "product",
                        filename: file.name,
                    },
                ],
            };
            Service.uploadPresign(body).then((response) => {
                this.body.image.presign = response.data;
                this.uploadFile(file, true);
                let url = this.body.image.presign[0].accessUrl;
                Editor.insertEmbed(cursorLocation, "image", url);
            });
        },

        closeModal() {
            this.display.media = false;
            this.display.imagePreview = false;
        },
    },
};
