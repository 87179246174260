var render = function render(){
  var _vm$thumbnail, _vm$thumbnail2, _vm$imageDetail, _vm$imageDetail2, _vm$imageDetail3, _vm$imageTitle, _vm$imageTitle2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "image-details"
  }, [_c('div', {
    staticClass: "details"
  }, [_c('div', {
    on: {
      "click": function ($event) {
        _vm.openDetail(_vm.thumbnail || _vm.items && _vm.items.length > 0 && _vm.items[0] || require('./noImage.jpg'));
      }
    }
  }, [_c('detail', {
    attrs: {
      "className": _vm.classChange || '',
      "heightChange": _vm.heightChange || '100%',
      "widthChange": _vm.widthChange || '100%',
      "radiusChange": _vm.radiusChange || '0px',
      "objectFit": _vm.objectFit || 'contain',
      "src": ((_vm$thumbnail = _vm.thumbnail) === null || _vm$thumbnail === void 0 ? void 0 : _vm$thumbnail.src) || _vm.thumbnail || _vm.items && _vm.items.length > 0 && _vm.items[0] || require('./noImage.jpg'),
      "alt": ((_vm$thumbnail2 = _vm.thumbnail) === null || _vm$thumbnail2 === void 0 ? void 0 : _vm$thumbnail2.alt) || 'default thumbnail detail'
    }
  })], 1)]), _vm.isOpenDetail && (_vm$imageDetail = _vm.imageDetail) !== null && _vm$imageDetail !== void 0 && _vm$imageDetail.src ? _c('div', {
    staticClass: "details-wrapper"
  }, [_c('div', {
    staticClass: "overlay-allin",
    on: {
      "click": function ($event) {
        _vm.isOpenDetail = false;
      }
    }
  }), _c('div', {
    staticClass: "overlay-allin-close",
    on: {
      "click": function ($event) {
        _vm.isOpenDetail = false, _vm.isOpenDetailCollapse = false;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-times"
  })]), _vm.items && _vm.items.length > 0 ? _c('div', {
    staticClass: "overlay-allin-close",
    staticStyle: {
      "top": "70px"
    },
    on: {
      "click": function ($event) {
        _vm.isOpenDetailCollapse = !_vm.isOpenDetailCollapse;
      }
    }
  }, [_vm.isOpenDetailCollapse ? _c('i', {
    staticClass: "fa fa-angle-up"
  }) : _c('i', {
    staticClass: "fa fa-angle-down"
  })]) : _vm._e(), _c('div', {
    staticClass: "all-in-wrapper"
  }, [_c('div', {
    staticClass: "all-in"
  }, [_c('div', {
    staticClass: "detail-main-wrapper"
  }, [_c('detail', {
    attrs: {
      "src": ((_vm$imageDetail2 = _vm.imageDetail) === null || _vm$imageDetail2 === void 0 ? void 0 : _vm$imageDetail2.src) || require('./noImage.jpg'),
      "alt": ((_vm$imageDetail3 = _vm.imageDetail) === null || _vm$imageDetail3 === void 0 ? void 0 : _vm$imageDetail3.alt) || 'default image detail'
    }
  })], 1)])]), _vm.items && _vm.items.length > 0 ? _c('div', {
    staticClass: "details-wrapper-child",
    class: _vm.isOpenDetailCollapse && 'set-height'
  }, [_c('div', {
    staticStyle: {
      "margin-top": "3px"
    },
    on: {
      "click": function ($event) {
        return _vm.previewImage(_vm.imageTitle);
      }
    }
  }, [_c('detail', {
    attrs: {
      "src": ((_vm$imageTitle = _vm.imageTitle) === null || _vm$imageTitle === void 0 ? void 0 : _vm$imageTitle.src) || _vm.imageTitle || require('./noImage.jpg'),
      "alt": ((_vm$imageTitle2 = _vm.imageTitle) === null || _vm$imageTitle2 === void 0 ? void 0 : _vm$imageTitle2.alt) || 'default image detail'
    }
  })], 1), _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.previewImage(item);
        }
      }
    }, [_c('detail', {
      attrs: {
        "src": (item === null || item === void 0 ? void 0 : item.src) || item,
        "alt": (item === null || item === void 0 ? void 0 : item.alt) || 'default item detail'
      }
    })], 1);
  })], 2) : _vm._e()]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }