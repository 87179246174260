var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-md-12 col-sm-12 col-xs-12 pr12"
  }, [_c('div', {
    staticClass: "row border-top-light-blue"
  }, [_c('div', {
    staticClass: "p20 navbar-sticky",
    staticStyle: {
      "padding-bottom": "0"
    }
  }, [_c('div', {
    staticClass: "font18",
    staticStyle: {
      "font-weight": "600 !important",
      "margin": "0px 4px 0px 4px"
    }
  }, [_c('i', {
    staticClass: "fas fa-arrow-left pointer",
    staticStyle: {
      "margin-right": "18px",
      "cursor": "pointer"
    },
    attrs: {
      "title": "Back"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/catalog/special-blend');
      }
    }
  }), _vm._v(" Special Blend ")])]), _vm.isLoading ? _c('div', {
    staticClass: "row txt-center p50 bg-white"
  }, [_vm._v(" Loading... ")]) : _vm.data.productDetail ? _c('div', {
    staticClass: "row bg-white p24"
  }, [_vm.data.productDetail ? _c('ProductGeneral', {
    attrs: {
      "productDetail": _vm.data.productDetail
    },
    on: {
      "createProductGeneral": _vm.createProductGeneralListener
    }
  }) : _vm._e()], 1) : _c('div', {
    staticClass: "row txt-center p50 bg-white"
  }, [_vm._v(" special blend not found ")]), _vm.data.productDetail.id ? _c('ListVaraintOption', {
    attrs: {
      "id": _vm.data.productDetail.id,
      "variants": _vm.data.productDetail.variantOptions
    }
  }) : _vm._e()], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }