var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('header', {
    staticClass: "row p12",
    staticStyle: {
      "background": "#f2f2f2"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "h-filter"
  }, [_c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Daterange")]), _c('date-picker', {
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "value-type": "format",
      "format": "YYYY-MM-DD",
      "range": "",
      "placeholder": "Select daterange"
    },
    model: {
      value: _vm.search.dateRange,
      callback: function ($$v) {
        _vm.$set(_vm.search, "dateRange", $$v);
      },
      expression: "search.dateRange"
    }
  })], 1), _c('div', {
    staticClass: "h-filter-item"
  }, [_vm._m(1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.cardCode,
      expression: "search.cardCode"
    }],
    staticClass: "input outline-dark h34",
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "type": "text",
      "placeholder": "Card Code"
    },
    domProps: {
      "value": _vm.search.cardCode
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searching.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "cardCode", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "h-filter-item"
  }, [_vm._m(2), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.transactionNumber,
      expression: "search.transactionNumber"
    }],
    staticClass: "input outline-dark h34",
    staticStyle: {
      "width": "150px"
    },
    attrs: {
      "type": "text",
      "placeholder": "Transaction Number"
    },
    domProps: {
      "value": _vm.search.transactionNumber
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searching.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "transactionNumber", $event.target.value);
      }
    }
  })]), _c('div', {
    staticStyle: {
      "display": "block",
      "position": "relative",
      "text-align": "left",
      "float": "left"
    }
  }, [_c('button', {
    staticClass: "btn btn-info btn-sm mr6 mt25",
    on: {
      "click": _vm.searching
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  })])])])]), _c('div', {
    staticClass: "table-control"
  }, [_c('table', {
    staticClass: "table wh800",
    attrs: {
      "cellspacing": "0"
    }
  }, [_vm._m(3), _vm.checkoutPermission('VIEW_QUOTATION') ? _c('tbody', _vm._l(_vm.data.order, function (order, index) {
    var _order$product;
    return _c('tr', {
      key: (order, index),
      staticClass: "hover-list"
    }, [_c('td', [_vm._v(_vm._s(order === null || order === void 0 ? void 0 : order.id))]), _c('td', [_vm._v(_vm._s((order === null || order === void 0 ? void 0 : order.transactionNumber) || 'N/A'))]), _c('td', [_vm._v(_vm._s((order === null || order === void 0 ? void 0 : order.cardCode) || 'N/A'))]), _c('td', [_vm._v(_vm._s(order.firstName) + " " + _vm._s(order.lastName))]), _c('td', [_vm._v(_vm._s(order.phone))]), _c('td', [_vm._v(_vm._s((_order$product = order.product) === null || _order$product === void 0 ? void 0 : _order$product.name))]), _c('td', [_vm._v(_vm._s(order.businessType))]), _c('td', {
      staticClass: "text-center",
      staticStyle: {
        "text-transform": "capitalize"
      }
    }, [_vm._v(_vm._s(order.status))]), _c('td', [_vm._v(_vm._s(order.createdAt && _vm.$helper.formatDate(order.createdAt)))]), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: 'DetailQuotation',
          params: {
            id: order.id
          }
        }
      }
    }, [_c('button', {
      staticClass: "btn btn-info btn-xs"
    }, [_c('i', {
      staticClass: "fa fa-eye"
    })])])], 1)]);
  }), 0) : _vm._e()]), _vm.isFetching ? _c('TableLoading') : _vm._e(), _vm.isNoData && !_vm.isFetching ? _c('NoItem') : _vm._e()], 1), _c('div', {
    staticClass: "total ph12"
  }, [_c('div', {
    staticClass: "ul-xl-li go-left"
  }, [_vm._v(" Total : " + _vm._s(_vm.data.pagination.total) + " ")]), _vm.data.pagination.totalPage > 0 ? _c('Pagination', {
    attrs: {
      "pagination": _vm.data.pagination.totalPage
    }
  }) : _vm._e()], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "go-left mt25",
    staticStyle: {
      "display": "inline-block",
      "position": "relative"
    }
  }, [_c('b', [_vm._v("Quotations")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "m0"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("CardCode")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "m0"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Transaction")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Quot. ID")]), _c('th', [_vm._v("Tran. Num")]), _c('th', [_vm._v("Card Code")]), _c('th', [_vm._v("Customer Name")]), _c('th', [_vm._v("Phone Number")]), _c('th', [_vm._v("Package Name")]), _c('th', [_vm._v("Business Type")]), _c('th', {
    staticClass: "text-center"
  }, [_vm._v("Order Status")]), _c('th', [_vm._v("Created At")]), _c('th', {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Action")])])]);

}]

export { render, staticRenderFns }