var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Shipping courier ")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "split"
  }, [_vm._m(0), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.courierName,
      expression: "body.courierName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "Input courier name..."
    },
    domProps: {
      "value": _vm.body.courierName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "courierName", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(1), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.courierNumber,
      expression: "body.courierNumber"
    }],
    attrs: {
      "type": "text",
      "placeholder": "Input courier number..."
    },
    domProps: {
      "value": _vm.body.courierNumber
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "courierNumber", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(2), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.courierCode,
      expression: "body.courierCode"
    }],
    attrs: {
      "type": "text",
      "placeholder": "Input courier code..."
    },
    domProps: {
      "value": _vm.body.courierCode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "courierCode", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "col-12 p6 mb12",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('button', {
    staticClass: "btn btn-light-secondary mr8",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Cancel ")]), !_vm.isCreating ? _c('button', {
    staticClass: "btn btn-info",
    on: {
      "click": _vm.submitCreateShipping
    }
  }, [_vm._v(" Create ")]) : _c('button', {
    staticClass: "btn btn-info"
  }, [_vm._v("Loading...")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Courier name :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Courier number :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Courier code :")])]);

}]

export { render, staticRenderFns }