import Service from "../../../../utils/api/service";
import Helper from './../../../../utils/global/func'
import Media from './../../../../components/share/media'

export default {
  name: "EditBrand",
  props: {},
  data() {
    return {
      isUpdating: false,
      display: {
        imagePreview: false,
        media: false
      },
      body: {
        image: {
          file: "",
          presign: ""
        },
        img: "",
        link: "",
        type: "",
        sortOrder: 0,
        status: "",
      },
      data: {},
    };
  },
  components: {
    Media
  },
  computed: {},
  created() {
    this.getBrandDetail(this.$route.query.id)
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "ListBrand",
        query: {
          page: 1,
          limit: 10
        },
      });
    },

    getBrandDetail(id) {
      Service.getBrandDetail(id).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.getBrandDetail(id);
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.data = response.data
        }
      })
    },

    imagePreview() {
      if (this.body.image.file || this.data.imageUrl) {
        this.display.imagePreview = true
      }
    },

    chooseImage() {
      this.display.media = true
    },

    existedMediaCallback(media) {
      this.data.media = media
      this.data.imageUrl = media.url
      this.closeModal()
    },

    newMediaCallBack(file) {
      this.body.image.file = file
      this.closeModal()
    },

    closeModal() {
      this.display = {
        imagePreview: false,
        media: false
      }
    },

    getFullImage(path) {
      return process.env.VUE_APP_BASE_URL_IMAGE + path;
    },

    getNameFromUrl(url) {
      return url.split('/').pop()
    },

    fileToPath(file) {
      return window.URL.createObjectURL(file)
    },

    submitUpdateBrand() {
      let validatedMessage = this.validateBody(this.data)
      if (validatedMessage == "ok") {
        this.isUpdating = true
        if (this.body.image.file) {
          this.uploadPresign()
        } else {
          this.updateBrand()
        }
      } else {
        this.$toasted.show(validatedMessage)
      }
    },

    validateBody(data) {
      if (!data.imageUrl) {
        return "Image file cannot be empty!"
      } else if (!data.name) {
        return "Name cannot be empty!";
      } else if (!data.sortOrder) {
        return "Sort order cannot be empty!";
      } else if (!data.status) {
        return "Status order cannot be empty!";
      } else {
        return "ok";
      }
    },

    uploadPresign() {
      let body = {
        media: [{
          ext: this.body.image.file.type.split('/').pop(),
          type: "brand",
          filename: this.body.image.file.name
        }]
      }
      Service.uploadPresign(body).then((response) => {
        this.body.image.presign = response.data
        this.uploadFile(this.body.image.file)
      });
    },

    async uploadFile(file) {
      if (file) {
        let uploadUrl = this.body.image.presign[0].uploadUrl
        await Service.uploadMedia(uploadUrl, file, file.type)
          .then((response) => {
            if (response == "ok") {
              this.updateBrand();
              this.addMedia()
            } else {
              this.$toasted.show("File upload fail!")
            }
          })
      } else {
        this.$toasted.show("File cannot be empty!")
      }
    },

    addMedia() {
      let file = this.body.image.file
      let body = {
        fileName: file.name,
        url: this.body.image.presign ? this.body.image.presign[0].key : "",
        type: file.type.split("/")[0],
        folder: "catelog",
        status: "active"
      }

      Service.addMedia(body)
    },

    updateBrand() {
      let id = this.data.id;
      if (this.body.image.file) {
        this.data.imageUrl = this.body.image.presign ? this.body.image.presign[0].key : ""
      } else if (this.data.media && this.data.media.url) {
        this.data.imageUrl = this.data.media.url
      }

      let body = {
        name: this.data.name,
        imageUrl: this.data.imageUrl,
        sortOrder: this.data.sortOrder,
        status: this.data.status,
      };

      Service.updateBrand(id, body).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.updateBrand()
              }
            })
          } else {
            this.isUpdating = false
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.goBack()
          this.$toasted.show('Brand has been updated.')
        }
      });
    },
  },
};