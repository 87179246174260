import { render, staticRenderFns } from "./template.html?vue&type=template&id=070bf0f2&scoped=true&external"
import script from "./script.js?vue&type=script&lang=js&external"
export * from "./script.js?vue&type=script&lang=js&external"
import style0 from "./style.css?vue&type=style&index=0&id=070bf0f2&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "070bf0f2",
  null
  
)

export default component.exports