export default {
  name: 'table-no-item',
  props: {
    data: ""
  },
  data() {
    return {
    }
  },

  components: {

  },
  create() {
  },

  methods: {

  }
}