var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Customer Group ")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "split"
  }, [_vm._m(0), _c('div', {
    staticClass: "media-container",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "border-radius": "4px",
      "border": "1px solid #ccc",
      "padding": "3px",
      "display": "inline-block"
    }
  }, [_vm.body.badge ? _c('div', {
    staticClass: "go-left pointer",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('image-detail', {
    attrs: {
      "heightChange": '36px',
      "widthChange": '36px',
      "thumbnail": _vm.body.badge && _vm.fileToPath(_vm.body.badge) || require('@/assets/images/noImage.jpg')
    }
  }), _c('div', {
    staticClass: "p-file-name"
  }, [_c('span', {
    staticClass: "font12 p-file-name mr6",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(_vm._s(_vm.body.badge.name))])])], 1) : _vm.data.badge ? _c('div', {
    staticClass: "go-left pointer display-flex"
  }, [_c('image-detail', {
    attrs: {
      "heightChange": '36px',
      "widthChange": '36px',
      "thumbnail": _vm.data.badge && _vm.getFullImage(_vm.data.badge) || require('@/assets/images/noImage.jpg')
    }
  }), _c('div', {
    staticClass: "p-file-name"
  }, [_c('span', {
    staticClass: "font12 mr6",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(_vm._s(_vm.getNameFromUrl(_vm.data.badge)))])])], 1) : _c('div', {
    staticClass: "go-left"
  }, [_c('img', {
    staticClass: "attachment mh6",
    attrs: {
      "src": require('@/assets/icons/Vectorpaperclip.png'),
      "width": "8px"
    }
  }), _c('span', {
    staticClass: "font12",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v("No file selected")])]), _c('label', {
    staticClass: "btn btn-secondary btn-sm go-right mt2 mr2",
    attrs: {
      "for": 'chooseLogo'
    }
  }, [_vm._v(" Choose file ")])]), _c('input', {
    ref: "chooseLogo",
    attrs: {
      "type": "file",
      "id": "chooseLogo",
      "accept": "image/jpeg,image/png,image/jpg",
      "hidden": ""
    },
    on: {
      "change": function ($event) {
        return _vm.chooseLogo($event);
      }
    }
  })])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(1), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.name,
      expression: "data.name"
    }],
    attrs: {
      "type": "text",
      "placeholder": "input customer group name..."
    },
    domProps: {
      "value": _vm.data.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.data, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(2), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.description,
      expression: "data.description"
    }],
    staticClass: "input outline-dark desc",
    attrs: {
      "type": "text",
      "placeholder": "Description..."
    },
    domProps: {
      "value": _vm.data.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.data, "description", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "split"
  }, [_vm._m(3), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.data.sortOrder,
      expression: "data.sortOrder",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "number",
      "placeholder": "input order..."
    },
    domProps: {
      "value": _vm.data.sortOrder
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.data, "sortOrder", _vm._n($event.target.value));
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])])]), _c('div', {
    staticClass: "col-12 p6 mb12",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('button', {
    staticClass: "btn btn-light-secondary mr8",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Cancel ")]), !_vm.isUpdating ? _c('button', {
    staticClass: "btn btn-info",
    on: {
      "click": _vm.submitUpdate
    }
  }, [_vm._v(" Update ")]) : _c('button', {
    staticClass: "btn btn-info"
  }, [_vm._v("Loading...")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title mt5"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Badge :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Name :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Description :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Sort order :")])]);

}]

export { render, staticRenderFns }