import detail from './image-detail-loading/index.vue'

export default {
    name: "image-details",
    props: {
        items: Array,
        thumbnail: String,
        classChange: String,
        heightChange: String,
        widthChange: String,
        radiusChange: String,
        objectFit: String
    },
    data() {
        return {
            imageDetail: {
                src: "",
                alt: ""
            },
            isImageDetailChange: false,
            isOpenDetail: false,
            isOpenDetailCollapse: true,
            imageTitle: ""
        };
    },
    components: { detail },
    created() {},
    watch: {},
    mounted() {},
    methods: {
        previewImage(data) {
            this.isImageDetailChange = true
            this.items && this.items.length > 0 && (
                this.imageDetail.src = (data?.src || data),
                this.imageDetail.alt = (data?.alt || 'default image detail')
            )
        },

        openDetail(data) {
            this.isOpenDetail = true
            this.isOpenDetailCollapse = true
            this.imageTitle = data?.src || data
            this.imageDetail.src = data?.src || data
            this.imageDetail.alt = data?.alt || 'default image detail'
        }
    }
}