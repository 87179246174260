import Pagination from "./../../../../components/share/pagination";
import Service from "../../../../utils/api/service";
import Helper from "../../../../utils/global/func";
import TableLoading from "./../../../../components/share/table-loading";
import NoItem from "./../../../../components/share/table-no-item";
import { mapState } from "vuex";

export default {
  name: "ListPointExchange",
  data() {
    return {
      isFetching: true,
      isUpdating: false,
      isNoData: false,
      data: {
        points: [],
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
      search: {
        text: "",
        status: "",
      },
      update: {
        data: "",
        show: false,
        index: -1,
      },
      approvalType: "",
    };
  },
  components: {
    Pagination,
    TableLoading,
    NoItem,
  },
  created() {
    this.setParams();
    this.getAllPointExchange();
  },
  mounted() {},
  watch: {
    "$route.fullPath": function () {
      this.setParams();
      this.getAllPointExchange();
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  methods: {
    setParams() {
      this.search = this.$helper.setParams(
        this.search,
        this.$root.$route.query
      );
    },

    checkoutPermission(permissionName) {
      if (this.permissions) {
        let result = false;
        this.permissions.find((item) => {
          item.permissions.find((permission) => {
            if (permission.name == permissionName) {
              result = true;
            }
          });
        });

        return result;
      }
    },

    getFullImage(path) {
      return process.env.VUE_APP_BASE_URL_IMAGE + path;
    },

    async searchStatus() {
      let query = this.$helper.filterKeyMapping({
        limit: 10,
        page: 1,
        ...this.search,
      });
      await this.$router.push({ query }).catch(() => {});
    },

    getAllPointExchange() {
      this.isFetching = true;
      let query = this.$root.$route.query;
      let setQuery = this.$helper.filterKeyMapping({
        page: query?.page || 1,
        ...query,
      });
      let params = this.$helper.queryParam(setQuery);

      Service.getAllPointExchange(params).then((response) => {
        this.isFetching = false;
        if (response?.statusCode) {
          this.$toasted.show(response?.message.capitalize());
          if (response.statusCode === "4410") {
            Service.refreshToken().then((response) => {
              if (response === "ok") {
                this.getAllPointExchange();
              }
            });
          } else if (response?.statusCode === "403") {
            this.isNoData = true;
          } else {
            this.$toasted.show(response?.message.capitalize());
          }
        } else {
          if (response?.data.length > 0) {
            this.data.points = response?.data;
            this.isNoData = false;
            this.data.pagination = Helper.calculatePagination(response?.meta);
          } else {
            this.data.points = [];
            this.isNoData = true;
            this.resetPagination();
          }
        }
      });
    },

    displayFormUpdate(index) {
      var version = this.data.points[index];
      this.update.data = {
        id: version.id,
        merchandiseImageUrl: version.merchandiseImageUrl,
        merchandiseId: version.merchandiseId,
        merchandiseName: version.merchandiseName,
        firstName: version.account.firstName,
        lastName: version.account.lastName,
        points: version.points,
        quantity: version.quantity,
        status: version.status,
        code: version.account.code,
      };
      this.update.show = true;
      this.update.index = index;
    },

    confirmUpdate(type) {
      this.approvalType = type;

      if (type === "rejected") {
        if (!this.update.data.remark) {
          this.$toasted.error("Please input remark.");
          return;
        }
      }

      this.isUpdating = true;
      let id = this.update.data.id;
      let body = {
        status: type,
        remark: this.update.data.remark,
      };

      Service.updatePointExchange(id, body).then((response) => {
        this.isUpdating = false;
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.confirmUpdate();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.data.points[this.update.index].status = this.update.data.status;
          this.$toasted.show("Point exchange status has been updated.");
          this.approvalType = "";
          this.update.data.status = "";
          this.update.data.remark = "";
          this.closeModal();
          this.getAllPointExchange();
        }
      });
    },

    formatBalance(x) {
      let result = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return result;
    },

    closeModal() {
      this.update = {
        data: "",
        show: false,
        index: -1,
      };
    },

    resetPagination() {
      this.data.pagination = {
        limit: 10,
        page: 1,
        total: 0,
        totalPage: 0,
      };
    },
  },
};
