import Pagination from "./../../../../components/share/pagination";
import Service from "../../../../utils/api/service";
import Helper from "../../../../utils/global/func";
import TableLoading from "./../../../../components/share/table-loading";
import NoItem from "./../../../../components/share/table-no-item";
import Update from "../edit";
import { mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "ListTrainingTicket",
  data() {
    return {
      isFetching: true,
      isDeleting: false,
      isNoData: false,
      fields: {
        id: "",
        index: "",
      },
      display: {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          large: false,
        },
      },
      data: {
        trainingTicket: [],
        detail: {},
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
      search: {
        dateRange: [],
        cardCode: "",
        accountId: ""
      },
    };
  },
  components: {
    Pagination,
    TableLoading,
    Update,
    NoItem,
    DatePicker
  },
  created() {
    this.setParams()
    this.getAllTrainingTicket();
  },
  watch: {
    "$route.fullPath": function () {
      this.setParams()
      this.getAllTrainingTicket();
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  methods: {
    setParams() {
      this.search = this.$helper.setParams(this.search, this.$root.$route.query)
    },

    async filterSearch() {
      let query = this.$helper.filterKeyMapping({limit: 10, page: 1, ...this.search})
      await this.$router.push({ query }).catch(() => { });
    },

    checkoutPermission(permissionName) {
      if (this.permissions) {
        let result = false;
        this.permissions.find((item) => {
          item.permissions.find((permission) => {
            if (permission.name == permissionName) {
              result = true;
            }
          });
        });

        return result;
      }
    },

    getFullImage(path) {
      return process.env.VUE_APP_BASE_URL_IMAGE + path;
    },

    getAllTrainingTicket() {
      this.isFetching = true;
      this.isNoData = false;
      let query = this.$root.$route.query;
      let setQuery = this.$helper.filterKeyMapping({ page: query?.page || 1, ...query })

      setQuery?.startDate && (setQuery.minDate = new Date(setQuery?.startDate).toISOString()) && delete setQuery.startDate
      setQuery?.endDate && (setQuery.maxDate = new Date(setQuery?.endDate).toISOString()) && delete setQuery.endDate
      let params = this.$helper.queryParam(setQuery)

      Service.getAllTrainingTicket(params).then((response) => {
        this.isFetching = false;
        if (response?.statusCode) {
          if (response?.statusCode === "4410") {
            Service.refreshToken().then((response) => {
              if (response === "ok") {
                this.getAllTrainingTicket();
              }
            });
          } else if (response?.statusCode === "403") {
            this.isNoData = true;
            this.data.trainingTicket = [];
            this.$toasted.show(response?.message.capitalize());
          } else {
            this.$toasted.show(response?.message.capitalize());
          }
        } else {
          if (response?.data.length > 0) {
            this.isNoData = false;
            this.data.trainingTicket = response?.data;
            this.data.pagination = Helper.calculatePagination(response?.meta);
          } else {
            this.data.trainingTicket = [];
            this.isNoData = true;
            this.resetPagination();
          }
        }
      });
    },

    popupModal(index) {
      this.fields.index = index;
      this.display.modal.large = true;
      this.data.detail = this.data.trainingTicket[index];
    },

    closeModal() {
      this.display = {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          large: false,
        },
      };
    },

    updated(value) {
      this.data.trainingTicket[this.fields.index] = value;
    },

    resetPagination() {
      this.data.pagination = {
        limit: 10,
        page: 1,
        total: 0,
        totalPage: 0,
      };
    },
  },
};
