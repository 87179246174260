var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Update Tutorial Playlist ")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "split"
  }, [_vm._m(0), _c('upload-image', {
    attrs: {
      "value": _vm.body.imageUrl
    },
    model: {
      value: _vm.body.imageUrl,
      callback: function ($$v) {
        _vm.$set(_vm.body, "imageUrl", $$v);
      },
      expression: "body.imageUrl"
    }
  })], 1), _c('div', {
    staticClass: "split"
  }, [_vm._m(1), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.name,
      expression: "body.name"
    }],
    attrs: {
      "type": "text",
      "placeholder": "Input name..."
    },
    domProps: {
      "value": _vm.body.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(2), _c('div', {
    staticClass: "input-container"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.description,
      expression: "body.description"
    }],
    staticClass: "input",
    staticStyle: {
      "border": "none"
    },
    domProps: {
      "value": _vm.body.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "description", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(3), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.videoUrl,
      expression: "body.videoUrl"
    }],
    attrs: {
      "type": "text",
      "placeholder": "Input video URL..."
    },
    domProps: {
      "value": _vm.body.videoUrl
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "videoUrl", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(4), _c('div', {
    staticClass: "input-container",
    staticStyle: {
      "padding": "0px"
    }
  }, [_c('vue-timepicker', {
    attrs: {
      "close-on-complete": "",
      "hide-clear-button": "",
      "format": "HH:mm:ss"
    },
    on: {
      "change": _vm.checkFormatTime
    },
    model: {
      value: _vm.model.duration,
      callback: function ($$v) {
        _vm.$set(_vm.model, "duration", $$v);
      },
      expression: "model.duration"
    }
  })], 1)]), _c('div', {
    staticClass: "split"
  }, [_vm._m(5), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.sortOrder,
      expression: "body.sortOrder"
    }],
    attrs: {
      "type": "number",
      "placeholder": "Input sort order..."
    },
    domProps: {
      "value": _vm.body.sortOrder
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "sortOrder", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "split",
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm._m(6), _c('div', {
    staticClass: "select"
  }, [_c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.status,
      expression: "body.status"
    }],
    attrs: {
      "type": "radio",
      "id": "active",
      "value": "active"
    },
    domProps: {
      "checked": _vm._q(_vm.body.status, "active")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body, "status", "active");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "active"
    }
  }, [_vm._v("Active")])]), _c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.status,
      expression: "body.status"
    }],
    attrs: {
      "type": "radio",
      "id": "inactive",
      "value": "inactive"
    },
    domProps: {
      "checked": _vm._q(_vm.body.status, "inactive")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body, "status", "inactive");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "inactive"
    }
  }, [_vm._v("Inactive")])])])])]), _c('div', {
    staticClass: "col-12 p6 mb12",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('button', {
    staticClass: "btn btn-light-secondary mr8",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Cancel ")]), !_vm.isCreating ? _c('button', {
    staticClass: "btn btn-info",
    on: {
      "click": _vm.submitUpdateTutorialPlaylist
    }
  }, [_vm._v(" update ")]) : _c('button', {
    staticClass: "btn btn-info"
  }, [_vm._v("Loading...")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Thumbnail :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Name :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Description :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Video URL :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Duration :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("SortOrder :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Status :")])]);

}]

export { render, staticRenderFns }