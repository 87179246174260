export default {
    name: "notfound",
    props: {
        label: { type: String, default: "Error!" },
        title: {
            type: String,
            default: "𝓞𝓸𝓸𝓹..! 𝓟𝓪𝓰𝓮 𝓝𝓸𝓽 𝓕𝓸𝓾𝓷𝓭",
        },
        isImageFolder: { type: Boolean, default: true },
        txtTryAgain: { type: String, default: "Try Again" },
    },

    data() {
        return {
            folderImage: require("./404.svg"),
        };
    },

    components: {},

    created() {},

    watch: {},

    computed: {},

    methods: {},
};
