import Pagination from "./../../../../components/share/pagination";
import Service from "./../../../../utils/api/service";
import Helper from "./../../../../utils/global/func";
import TableLoading from "./../../../../components/share/table-loading";
import NoItem from "./../../../../components/share/table-no-item";
import { mapState } from "vuex";

export default {
  name: "ListPaymentMethod",
  data() {
    return {
      isFetching: true,
      isNoData: false,
      data: {
        payments: [],
      },
      search: {
        status: "",
      },
      display: {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          large: false,
        },
      },
      field: {
        index: "",
      },
      isDeleting: false,
      detail: {},
    };
  },
  components: {
    Pagination,
    TableLoading,
    NoItem,
  },
  created() {
    this.getAllPayment(10, 0);
  },
  mounted() {},
  watch: {
    "$route.fullPath": function() {
      this.getAllPayment();
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  methods: {
    checkoutPermission(permissionName) {
      if (this.permissions) {
        let result = false;
        this.permissions.find((item) => {
          item.permissions.find((permission) => {
            if (permission.name == permissionName) {
              result = true;
            }
          });
        });

        return result;
      }
    },
    
    getFullImage(path) {
      return process.env.VUE_APP_BASE_URL_IMAGE + path;
    },

    getAllPayment(limit, offset) {
      this.isFetching = true
      let query = this.$root.$route.query;
      let setQuery = this.$helper.filterKeyMapping({page: query?.page || 1, ...query})
      let params = this.$helper.queryParam(setQuery)

      Service.getAllPayment(params).then((response) => {
        this.isFetching = false;
        if (response?.statusCode) {
          this.$toasted.show(response?.message.capitalize());
          if (response.statusCode === "4410") {
            Service.refreshToken().then((response) => {
              if (response === "ok") {
                this.getAllPayment(limit, offset);
              }
            });
          } else if (response?.statusCode === "403") {
            this.isNoData = true;
            this.data.payments = [];
            this.$toasted.show(response?.message.capitalize());
          } else {
            this.$toasted.show(response?.message.capitalize());
          }
        } else {
          if (response?.data.length > 0) {
            this.data.payments = response?.data;
            this.isNoData = false;
          } else {
            this.data.payments = [];
            this.isNoData = true;
          }
        }
      });
    },

    async searchStatus() {
      let query = this.$helper.filterKeyMapping({limit: 10, page: 1, ...this.search})
      await this.$router.push({ query }).catch(() => { });
    },

    popupModalDelete(index) {
      this.display.modal.delete.index = index;
      this.display.modal.delete.show = true;
    },

    closeModal() {
      this.display = {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          large: false,
        },
      };
    },

    modalDetail() {
      this.display.modal.detail = true;
    },

    deletePaymentMethod() {
      this.isDeleting = true;
      let index = this.display.modal.delete.index;
      let param = this.data.payments[index].id;

      Service.deletePaymentMethod(param).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.deletePaymentMethod();
              }
            });
          } else {
            this.isDeleting = false;
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.data.payments.splice(index, 1);
          this.$toasted.show("Payment method has been deleted.");
          this.isDeleting = false;
          this.closeModal();
        }
      });
    },

    updated(value) {
      this.data.payments[this.field.index] = value;
    },
  },
};
