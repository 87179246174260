<template>
    <image-loading-component>
        <img 
            slot="image" 
            :src="src || require('../../image-detail/noImage.jpg')" 
            :class="className || ''" 
            :style="{
                borderRadius: radiusChange, 
                width: widthChange || '100%',
                height: heightChange || '100%',
                objectFit: objectFit || 'contain'
            }"
        />
        <div slot="preloader" class="loading-style">
            <img :src="require('../../image-detail/loading1.gif')" :class="className || 'image-loading-class'" />
        </div>
        <div slot="error" class="image-not-found">Wrong Image Url</div>
    </image-loading-component>
</template>
<script>
import ImageLoadingComponent from './component/index.vue'

export default {
    name: "image-detail-loading",
    props: {
        src: String,
        widthChange: String,
        heightChange: String,
        className: String,
        alt: String,
        radiusChange: String,
        objectFit: String
    },
    components: { ImageLoadingComponent },
}
</script>
<style scoped>
    .image-not-found {
        width: 100%;
        position: absolute;
        right: 0;
        left: 0;
        top: 30px;
        margin: 0 auto;
        text-align: center;
    }
    .image-loading-class {
        width: 20px;
        height: 20px;
    }
    .loading-style {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>