var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "image-loading"
  }, [_vm.status === 'loaded' ? _vm._t("image") : _vm.status === 'failed' ? _vm._t("error") : _vm.status === 'loading' ? _vm._t("preloader") : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }