var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mt10 mb20"
  }, [_c('div', {
    staticClass: "row radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row p12 bg-white border-top-light-blue"
  }, [_vm._m(0), _c('div', {
    staticClass: "p12",
    class: _vm.renderClass()
  }, [_c('table', {
    staticClass: "table wh800",
    attrs: {
      "cellspacing": "0"
    }
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.variants, function (variantValue, index) {
    return _c('tr', {
      key: (variantValue, index),
      staticClass: "hover-list"
    }, [_c('td', {
      staticClass: "txt-center"
    }, [_vm._v(_vm._s(variantValue.id))]), _c('td', {
      staticClass: "cover"
    }, [variantValue.optionValues.imageUrl ? _c('img', {
      staticStyle: {
        "height": "40px"
      },
      attrs: {
        "src": _vm.getFullImage(variantValue.optionValues.imageUrl)
      }
    }) : _c('img', {
      staticStyle: {
        "height": "40px"
      },
      attrs: {
        "src": require('@/assets/images/noImage.jpg'),
        "alt": "No Image"
      }
    })]), _c('td', [_vm._v(_vm._s(variantValue.text || "N/A") + " " + _vm._s(variantValue.optionValues.text || "N/A"))]), _c('td', [_vm._v(_vm._s(variantValue.type || "N/A"))])]);
  }), 0)]), !_vm.variants.length ? _c('NoItem') : _vm._e()], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "navbar r-navbar"
  }, [_c('div', {
    staticClass: "ul-xl"
  }, [_c('div', {
    staticClass: "ul-xl-li"
  }, [_vm._v(" Variantation ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "txt-center",
    staticStyle: {
      "width": "40px"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "txt-center",
    staticStyle: {
      "width": "60px"
    }
  }, [_vm._v("Image")]), _c('th', [_vm._v("Name")]), _c('th', [_vm._v("Type")])])]);

}]

export { render, staticRenderFns }