const helper = {};

helper.userDefault = function () {
    return "./assets/images/user_default.jpeg";
};

helper.getMediaType = function (url) {
    if (url) {
        const images = ["jpg", "jpeg", "gif", "png"];
        const videos = ["mp4", "mov", "3gp", "ogg"];
        const extension = url.split(".").pop();
        if (images.includes(extension)) {
            return "image";
        } else if (videos.includes(extension)) {
            return "video";
        } else {
            return "unknown";
        }
    } else {
        return "empty";
    }
};

helper.calculatePagination = function (data) {
    let totalPage = data.total / data.limit;
    let page = data.page || data.offset + data.limit - data.limit + 1;
    let pagination = {
        limit: data.limit,
        page: parseInt(page),
        total: data.total,
        totalPage:
            totalPage > parseInt(totalPage)
                ? parseInt(totalPage) + 1
                : parseInt(totalPage),
    };
    return pagination;
};

helper.base64toFile = function (dataurl, filename) {
    var arr = dataurl.split(","),
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: "image/jpeg" });
};

helper.getFullImage = function (path) {
    return process.env.VUE_APP_BASE_URL_IMAGE + path;
};

helper.formatPhoneNumber = (data) => {
    if (!data) {
        return;
    }
    let phoneNumber = data.replace("+855", "0");
    let phone = phoneNumber.split("") || data.split("");

    let countIndex = [];
    let countIndex1 = [];
    let countIndex2 = [];
    let countIndex3 = [];
    let countIndex4 = [];

    phone.map((item, i) => {
        i <= 2 && countIndex1.push(item);
        i > 2 && i <= 5 && countIndex2.push(item);
        i > 5 && i <= 8 && countIndex3.push(item);
        i > 8 && countIndex4.push(item);
    });

    countIndex1.length > 0 &&
        countIndex.push(countIndex1.toString().replace(/,/g, ""));
    countIndex2.length > 0 &&
        countIndex.push(countIndex2.toString().replace(/,/g, ""));
    countIndex3.length > 0 &&
        countIndex.push(countIndex3.toString().replace(/,/g, ""));
    countIndex4.length > 0 &&
        countIndex.push(countIndex4.toString().replace(/,/g, ""));

    return countIndex.join(" ");
};

helper.filterKeyMapping = (keys) => {
    for (const [key, value] of Object.entries(keys)) {
        !value && delete keys[key];
        // ((typeof value).includes('object')) && delete keys[key];
        if (Array.isArray(value) && value.length > 0 && key === "dateRange") {
            keys["startDate"] = value[0];
            keys["endDate"] = value[1];
            delete keys[key];
        }
        Array.isArray(value) && value.length <= 0 && delete keys[key];
    }

    return keys;
};

helper.setParams = (params, querys) => {
    let startDate = "";
    let endDate = "";

    for (const [key, value] of Object.entries(querys)) {
        if (value && key === "startDate") {
            startDate = value + "T00:00:00.000";
            if (!value) {
                params[key] = "";
            } else {
                delete params[key];
            }
        }
        if (value && key === "endDate") {
            endDate = value + "T23:59:59.999";
            if (!value) {
                params[key] = "";
            } else {
                delete params[key];
            }
        }
        if (value && typeof params[key] === "string") {
            params[key] = value;
        }
        if (!value && typeof params[key] === "string") {
            params[key] = "";
        }

        if (value && typeof params[key] === "object") {
            params[key] = value;
        }
        if (!value && typeof params[key] === "object") {
            params[key] = "";
        }

        if (value && typeof params[key] === "number") {
            params[key] = value;
        }
        if (!value && typeof params[key] === "number") {
            params[key] = 0;
        }
    }

    if (startDate && endDate) {
        params["dateRange"] = [startDate, endDate];
    }
    return params;
};

helper.queryParam = function (queryParams) {
    if (queryParams.startDate) {
        queryParams.startDate = queryParams.startDate + "T00:00:00.000";
    }
    if (queryParams.endDate) {
        queryParams.endDate = queryParams.endDate + "T23:59:59.999";
    }
    let queryParam = queryParams || "";
    const offsetQuery =
        (queryParams?.limit || 10) * queryParams?.page -
        (queryParams?.limit || 10);
    delete queryParams?.page;
    const { limit = 10, ...queries } = queryParams || {};
    const queryString = (Object.entries(queries) || [])
        .map((item) => item.join("="))
        .join("&");
    queryParam =
        "?limit=" +
        limit +
        "&offset=" +
        offsetQuery +
        (queryString ? "&" + queryString : "");
    return queryParam;
};

helper.queryCustomParam = function (queryParams) {
    let params = this.filterKeyMapping(queryParams);
    const queryString = (Object.entries(params) || [])
        .map((item) => item.join("="))
        .join("&");
    return Object.keys(queryString).length > 0 ? "?" + queryString : "";
};

helper.formatDate = function (date) {
    // let newDatetime = date.substring(0, 19).split("T")
    // let hours = "00:00:00 AM"
    // if (newDatetime[1]) {
    //     hours = this.time24hTo12h(newDatetime[1])
    // }
    // let newDate = new Date(newDatetime[0])
    // let strDate = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate()
    // let strMonth = (newDate.getMonth() + 1) < 10 ? "0" + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
    // let strYear = newDate.getFullYear()
    // return strDate + "-" + strMonth + "-" + strYear + " " + hours;

    const timestamp = date || "";

    if (!timestamp) {
        return "N/A";
    }

    if (!timestamp.includes("T")) {
        return timestamp;
    }

    if (timestamp) {
        const splitTimestamp = timestamp.split("T");
        if (splitTimestamp.length === 2) {
            const datePart = splitTimestamp[0].split("-").reverse().join("-");
            const timePart = splitTimestamp[1].split(".")[0];

            return datePart + " " + timePart;
        } else {
            return "N/A";
        }
    }
};

helper.time24hTo12h = function (time) {
    let strHour = time.substring(0, 2);
    let suffix = parseInt(strHour) >= 12 ? "PM" : "AM";
    let hour =
        ((parseInt(strHour) + 11) % 12) + 1 < 10
            ? "0" + (((parseInt(strHour) + 11) % 12) + 1)
            : ((parseInt(strHour) + 11) % 12) + 1;
    return hour + time.substring(2) + " " + suffix;
};

helper.timestampFormat = (date, delimiter) => {
    let hours = date.getHours();
    const minutes = ("00" + date.getMinutes()).slice(-2);
    const seconds = ("00" + date.getSeconds()).slice(-2);
    const dan = hours - 7 >= 12 ? "PM" : "AM";
    hours = (date.getHours() - 7) % 12; // time format fit to api
    hours = ("00" + (hours == 0 ? 12 : hours)).slice(-2);
    let time = hours.toString();

    const timestamp = [time.replace("-", "0"), minutes, seconds].join(
        delimiter || ":"
    );
    return [timestamp, dan].join(" ");
};

helper.dateSuffix = (data) => {
    const suffix = ["th", "st", "nd", "rd"];
    const date = new Date(data).getDate();

    const value = date % 100;

    return suffix[(value - 20) % 10] || suffix[value] || suffix[0];
};

helper.filterDateRange = (data) => {
    const date = new Date(data);

    const dateOfWeek = ("00" + (date.getDate() + 1)).slice(-2);
    const month = ("00" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    let newDate = new Date([month, dateOfWeek, year].join("-")).toISOString();

    return newDate;
};

helper.setDateRangeFormat = (data) => {
    const date = new Date(data);

    const dateOfWeek = ("00" + date.getDate()).slice(-2);
    const month = ("00" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return [month, dateOfWeek, year].join("-");
};

helper.formatNumber = function (number) {
    let newNumber = number || 0;
    let result = newNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result;
};

helper.formatPrice = function (price = 0) {
    var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(price);
};

helper.tokenEnum = () => {
    return {
        access_token: "kofi_admin_access_token",
        refresh_token: "kofi_admin_refresh_token",
        account_id: "account_id",
    };
};

export default helper;
