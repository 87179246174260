import Service from "../../utils/api/service";
import AreaChart from "./../../components/chart/area-chart.vue";
import BarChart from "./../../components/chart/bar-chart.vue";
import PieChart from "./../../components/chart/pie-chart.vue";
import TableLoading from "./../../components/share/table-loading";
export default {
    name: "dashboard",
    data() {
        return {
            isFetching: true,
            data: {
                activeCustomer: 0,
                orderFail: 0,
                customers: 0,
                inActiveCustomer: 0,
                onlineCustomers: 0,
                pendingOrder: 0,
                products: 0,
                successOrder: 0,
                totalAmountSalOrder: 0,
                totalOrderStatus: 0,
                pendingOrder: 0,
                paymentMethod: [],
                userAccount: [],
                bestSales: [],
                tickets: [],
            },
            filter: {
                // startDate: this.$moment(new Date()).subtract(1, 'months').format("YYYY-MM-DD"),
                // endDate: this.$moment(new Date()).format("YYYY-MM-DD")
                startDate: "",
                endDate: "",
            },
            userAccountAreaChart: {
                labels: [],
                datasets: [
                    {
                        label: "Customer Registration",
                        backgroundColor: [
                            "#5688bb",
                            "#aa6544",
                            "#99bb55",
                            "#ef9944",
                            "#ba5555",
                        ],
                        data: [],
                    },
                ],
            },
            paymentMethodPieChart: {
                labels: [],
                datasets: [
                    {
                        backgroundColor: [
                            "#e5e5e5",
                            "#005c76",
                            "#c8a337",
                            "#e4872e",
                            "#a1abb0",
                        ],
                        data: [],
                    },
                ],
            },
        };
    },
    components: {
        AreaChart,
        BarChart,
        PieChart,
        TableLoading,
    },
    created() {
        this.getPaymentGraphData();
    },
    mounted() {},
    methods: {
        getPaymentGraphData() {
            let params = "?";
            if (this.filter.startDate) {
                params = params + "startDate=" + this.filter.startDate;
            }
            if (this.filter.endDate) {
                params = params + "&endDate=" + this.filter.endDate;
            }
            // params = ""
            Service.getPaymentGraphData(params).then((response) => {
                if (response.statusCode) {
                    if (response.statusCode === "4410") {
                        Service.refreshToken().then((response) => {
                            if (response === "ok") {
                                this.getPaymentGraphData();
                            }
                        });
                    } else {
                        // this.$toasted.show(response?.message.capitalize());
                    }
                } else {
                    this.data.activeCustomer = response?.activeCustomer;
                    this.data.orderFail = response?.orderFail;
                    this.data.customers = response?.customers;
                    this.data.inActiveCustomer = response?.inActiveCustomer;
                    this.data.onlineCustomers = response?.pendingOrder;
                    this.data.products = response?.products;
                    this.data.successOrder = response?.successOrder;
                    this.data.totalAmountSalOrder =
                        response?.totalAmountSalOrder;
                    this.data.totalOrderStatus = response?.totalOrderStatus;
                    this.data.pendingOrder = response?.pendingOrder;
                    this.data.paymentMethod = response?.data;
                    this.mapDataPaymentMethodToPieChart();
                    this.getReportUserGraphData();
                    this.getDashboardBestSale();
                    this.getDashboardTicket();
                }
            });
        },

        mapDataPaymentMethodToPieChart() {
            this.data.paymentMethod.forEach((item) => {
                let chartLabel = item.name + " : " + item.count;
                this.paymentMethodPieChart.labels.push(chartLabel);
                this.paymentMethodPieChart.datasets[0].data.push(
                    parseInt(item.count)
                );
            });
        },

        getReportUserGraphData() {
            Service.getReportUserGraphData().then((response) => {
                this.isFetching = false;
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.getReportUserGraphData();
                            }
                        });
                    } else {
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    this.data.userAccount = response.data;
                    this.mapDataUserAccountToAreaChart();
                }
            });
        },

        getDashboardBestSale() {
            let params = "?";
            if (this.filter.startDate) {
                params = params + "startDate=" + this.filter.startDate;
            }
            if (this.filter.endDate) {
                params = params + "&endDate=" + this.filter.endDate;
            }
            Service.getDashboardBestSale(params).then((response) => {
                this.isFetching = false;
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.getDashboardBestSale();
                            }
                        });
                    } else {
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    this.data.bestSales = response.data || [];
                }
            });
        },

        getDashboardTicket() {
            let params = "?";
            if (this.filter.startDate) {
                params = params + "startDate=" + this.filter.startDate;
            }
            if (this.filter.endDate) {
                params = params + "&endDate=" + this.filter.endDate;
            }
            Service.getDashboardTicket(params).then((response) => {
                this.isFetching = false;
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.getDashboardTicket();
                            }
                        });
                    } else {
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    this.data.tickets = response.data || [];
                }
            });
        },

        mapDataUserAccountToAreaChart() {
            for (let i = 0; i < this.data.userAccount.length; i++) {
                let item = this.data.userAccount[i];

                this.userAccountAreaChart.labels.push(item.label);
                this.userAccountAreaChart.datasets[0].data.push(
                    parseInt(item.count)
                );
            }
        },
    },
};
