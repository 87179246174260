import Service from "../../../../utils/api/service";
import Media from "./../../../../components/share/media";

export default {
    name: "CreatePromotion",
    data() {
        return {
            isCreating: false,
            display: {
                schedule: false,
                media: false,
                imagePreview: false,
            },
            body: {
                title: "",
                description: "",
                imageUrl: "",
                pushAt: "",
                startDate: "",
                endDate: "",
                status: "active",
            },
            data: {
                media: "",
            },
            customToolbarSpecification: [
                ["bold", "italic", "underline"],
                [
                    {
                        list: "ordered",
                    },
                    {
                        list: "bullet",
                    },
                ],
                ["link"],
            ],
        };
    },
    components: {
        Media,
    },
    computed: {},
    created() {
        this.getBody();
    },
    methods: {
        goBack() {
            this.$router.push({
                name: "ListPromotion",
            });
        },
        removeMedia() {
            this.data.media = "";
            this.body.imageUrl = "";
        },
        closeModal() {
            this.display.media = false;
            this.display.imagePreview = false;
        },

        imagePreview() {
            if (this.data.media != "") {
                this.display.imagePreview = true;
            }
        },

        chooseImage() {
            this.display.media = true;
        },

        existedMediaCallback(media) {
            this.data.media = media;
            this.body.imageUrl = media.url;
            this.closeModal();
        },

        fileToPath(file) {
            return window.URL.createObjectURL(file);
        },

        getFullImage(path) {
            return process.env.VUE_APP_BASE_URL_IMAGE + path;
        },

        getNameFromUrl(url) {
            return url.split("/").pop();
        },

        submitCreateNotification() {
            let validatedMessage = this.validateBody(this.body);
            if (validatedMessage == "ok") {
                this.isCreating = true;
                this.createNotification();
            } else {
                this.$toasted.show(validatedMessage);
            }
        },

        validateBody(data) {
            if (!data.imageUrl) {
                return "Image cannot be empty!";
            } else if (!data.title) {
                return "Title cannot be empty!";
            } else if (!data.description) {
                return "Description cannot be empty!";
            }
            if (!data.pushAt) {
                return "Push At cannot be empty!";
            }
            if (!data.startDate) {
                return "Start Date cannot be empty!";
            }
            if (!data.endDate) {
                return "End Date cannot be empty!";
            } else {
                return "ok";
            }
        },

        getBody: function () {
            this.body = {
                title: this.body.title,
                description: this.body.description,
                imageUrl: this.body.imageUrl,
                startDate: this.formatDateTime(
                    this.body.startDate || new Date()
                ),
                endDate: this.formatDateTime(this.body.endDate || new Date()),
                pushAt: this.formatDateTime(this.body.pushAt || new Date()),
                status: this.body.status,
            };
        },

        formatDateTime(dateTime) {
            const adjustedDateTime = new Date(dateTime);
            adjustedDateTime.setHours(adjustedDateTime.getHours() + 7);

            return adjustedDateTime.toISOString().slice(0, 16);
        },

        formatDateTimePayload(dateTime) {
            const adjustedDateTime = new Date(dateTime);
            adjustedDateTime.setHours(adjustedDateTime.getHours() + 7);

            return adjustedDateTime.toISOString();
        },

        createNotification() {
            const body = {
                title: this.body.title,
                description: this.body.description,
                imageUrl: this.body.imageUrl,
                startDate: this.formatDateTimePayload(this.body.startDate),
                endDate: this.formatDateTimePayload(this.body.endDate),
                pushAt: this.formatDateTimePayload(this.body.pushAt),
                status: this.body.status,
            };

            Service.createPromotion(body).then((response) => {
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.createNotification();
                            }
                        });
                    } else {
                        this.isCreating = false;
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    this.isCreating = false;
                    this.resetBody();
                    this.$toasted.show("Promotion has been created.");
                }
            });
        },

        resetBody() {
            this.body = {
                title: "",
                description: "",
                imageUrl: "",
                startDate: this.formatDateTime(new Date()),
                endDate: this.formatDateTime(new Date()),
                pushAt: this.formatDateTime(new Date()),
                status: "active",
            };
            this.body.imageUrl = "";
            this.data = {
                media: "",
            };
        },
    },
};
