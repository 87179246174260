var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_vm._m(0), _c('div', {
    staticClass: "table-control"
  }, [_c('table', {
    staticClass: "table wh800",
    attrs: {
      "cellspacing": "0"
    }
  }, [_vm._m(1), !_vm.isFetching && _vm.checkoutPermission('LIST_ORDER_STATUS') ? _c('tbody', _vm._l(_vm.data.orderStatus, function (orderStatus, index) {
    return _c('tr', {
      key: (orderStatus, index),
      staticClass: "hover-list"
    }, [_c('td', {
      staticClass: "txt-center"
    }, [_vm._v(_vm._s(orderStatus === null || orderStatus === void 0 ? void 0 : orderStatus.id))]), _c('td', [_vm._v(_vm._s(orderStatus.name) + " ")]), _c('td', [_vm._v(_vm._s(orderStatus.description ? _vm.htmlToText(orderStatus.description) : 'N/A'))])]);
  }), 0) : _vm._e()]), _vm.isFetching ? _c('TableLoading') : _vm._e(), _vm.isNoData && !_vm.isFetching ? _c('NoItem') : _vm._e()], 1)])]), _vm.display.modal.delete.show ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal-md"
  }, [_vm._m(2), _vm._m(3), _c('div', {
    staticClass: "modal-footer border-top"
  }, [!_vm.isDeleting ? _c('button', {
    staticClass: "btn btn-info btn-xs go-right",
    on: {
      "click": _vm.deleteOrderStatus
    }
  }, [_vm._v(" Yes ")]) : _c('button', {
    staticClass: "btn btn-info btn-xs go-right"
  }, [_vm._v(" Loading... ")]), _c('button', {
    staticClass: "btn btn-light-secondary btn-xs mr12 go-right",
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" No ")])])])]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "row p12",
    staticStyle: {
      "background": "#f2f2f2"
    }
  }, [_c('div', {
    staticClass: "go-left",
    staticStyle: {
      "display": "inline-block",
      "position": "relative"
    }
  }, [_c('b', [_vm._v("Order Status")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "w70 txt-center"
  }, [_vm._v("No")]), _c('th', {
    attrs: {
      "width": "250px"
    }
  }, [_vm._v("Name")]), _c('th', [_vm._v("Description")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-header border-bottom txt-info"
  }, [_c('span', {
    staticClass: "txt-sm"
  }, [_vm._v("Delete")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-body",
    staticStyle: {
      "overflow-y": "hidden",
      "padding": "16px"
    }
  }, [_c('span', [_vm._v("Do you want to delete this record?")])]);

}]

export { render, staticRenderFns }