<template>
    <div>
        <textarea :id="editorId"></textarea>
    </div>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
    props: {
        editorId: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            editor: null,
        };
    },
    mounted() {
        ClassicEditor.create(document.getElementById(this.editorId), {
            toolbar: [
                // "heading",
                // "|",
                "bold",
                "italic",
                "underline",
                // "strikethrough",
                // "highlight",
                "|",
                // "alignment",
                "|",
                "bulletedList",
                "numberedList",
                // "todoList",
                "|",
                "indent",
                "outdent",
                "|",
                "link",
                "blockQuote",
                "|",
                // "imageUpload",
                // "mediaEmbed",
                // "insertTable",
                "|",
                // "fontFamily",
                // "fontSize",
                // "fontColor",
                // "fontBackgroundColor",
                "|",
                // "code",
                // "codeBlock",
                "|",
                // "removeFormat",
                "|",
                "undo",
                "redo",
            ],
        })
            .then((editor) => {
                this.editor = editor;
                this.editor.setData(this.value);
                this.editor.model.document.on("change:data", () => {
                    this.$emit("input", this.editor.getData());
                });
            })
            .catch((error) => {
                console.error("CKEditor initialization error:", error);
            });
    },
    beforeUnmount() {
        if (this.editor) {
            this.editor.destroy().then(() => {
                this.editor = null;
            });
        }
    },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>
