var render = function render(){
  var _vm$data, _vm$data$customer, _vm$data2, _vm$data2$customer, _vm$data3, _vm$data3$customer, _vm$data4, _vm$data4$customer, _vm$data5, _vm$data5$customer, _vm$data6, _vm$data6$customer, _vm$data7, _vm$data7$customer, _vm$data7$customer$cu, _vm$data8, _vm$data8$customer, _vm$data9, _vm$data9$customer, _vm$data10, _vm$data10$customer, _vm$data11, _vm$data11$customer, _vm$data12, _vm$data12$customer, _vm$data13, _vm$data13$customer, _vm$data14, _vm$data14$customer, _vm$data15, _vm$data15$customer, _vm$data16, _vm$data16$customer, _vm$data17, _vm$data17$customer, _vm$data18, _vm$data18$customer, _vm$data19, _vm$data19$customer, _vm$data20, _vm$data20$customer, _vm$data21, _vm$data21$customer;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('header', {
    staticClass: "row p12",
    staticStyle: {
      "background": "#f2f2f2"
    }
  }, [_c('b', {
    staticClass: "mt0 mb10"
  }, [_vm._v("Customers List")]), _c('div', {
    staticClass: "h-filter"
  }, [_c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Email")]), _c('div', {
    staticClass: "search-box search-email",
    staticStyle: {
      "margin-right": "2px"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.email,
      expression: "search.email"
    }],
    attrs: {
      "type": "search",
      "placeholder": "Email"
    },
    domProps: {
      "value": _vm.search.email
    },
    on: {
      "change": _vm.filterSearch,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.filterSearch.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "email", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "search-box mr2 search-username"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.search,
      expression: "search.search"
    }],
    attrs: {
      "type": "search",
      "placeholder": "Name"
    },
    domProps: {
      "value": _vm.search.search
    },
    on: {
      "change": _vm.filterSearch,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.filterSearch.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "search", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Phone")]), _c('div', {
    staticClass: "search-box mr2 search-username"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.phone,
      expression: "search.phone"
    }],
    attrs: {
      "type": "search",
      "placeholder": "Phone number"
    },
    domProps: {
      "value": _vm.search.phone
    },
    on: {
      "change": _vm.filterSearch,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.filterSearch.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "phone", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Code")]), _c('div', {
    staticClass: "search-box mr2 search-username"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.code,
      expression: "search.code"
    }],
    attrs: {
      "type": "search",
      "placeholder": "Code"
    },
    domProps: {
      "value": _vm.search.code
    },
    on: {
      "change": _vm.filterSearch,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.filterSearch.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "code", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "h-filter-item"
  }, [_c('div', {
    staticClass: "outside",
    class: _vm.isToggle && 'displayBlock',
    on: {
      "click": function ($event) {
        _vm.isToggle = !_vm.isToggle;
      }
    }
  }), _c('i', {
    staticClass: "fas fa-filter icon-filter mr2 nav-dropdown",
    on: {
      "click": function ($event) {
        _vm.isToggle = !_vm.isToggle;
      }
    }
  }), _c('div', {
    staticClass: "dropdown-navbar",
    class: _vm.isToggle ? 'displayBlock' : ''
  }, [_c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Date Range")]), _c('div', {
    staticClass: "mr8"
  }, [_c('date-picker', {
    staticClass: "search-date",
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "value-type": "format",
      "format": "YYYY-MM-DD",
      "range": "",
      "placeholder": "Select date range"
    },
    on: {
      "change": _vm.filterSearch
    },
    model: {
      value: _vm.search.dateRange,
      callback: function ($$v) {
        _vm.$set(_vm.search, "dateRange", $$v);
      },
      expression: "search.dateRange"
    }
  })], 1)]), _c('div', {
    staticClass: "h-filter-item mt4"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Status")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.status,
      expression: "search.status"
    }],
    staticClass: "input mr6 h34",
    staticStyle: {
      "padding": "0",
      "width": "200px"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.search, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.filterSearch]
    }
  }, [_c('option', {
    staticClass: "option-all",
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Status")]), _c('option', {
    attrs: {
      "value": "active"
    }
  }, [_vm._v("Active")]), _c('option', {
    attrs: {
      "value": "inactive"
    }
  }, [_vm._v("Inactive")])])]), _c('div', {
    staticClass: "h-filter-item mt4"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Sort By")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.sort,
      expression: "search.sort"
    }],
    staticClass: "input mr6 h34",
    staticStyle: {
      "padding": "0",
      "width": "200px"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.search, "sort", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.filterSearch]
    }
  }, [_c('option', {
    staticClass: "option-all",
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select below")]), _c('option', {
    attrs: {
      "value": "ASC"
    }
  }, [_vm._v("ASC")]), _c('option', {
    attrs: {
      "value": "DESC"
    }
  }, [_vm._v("DESC")])])]), _c('div', {
    staticClass: "h-filter-item mt4"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Customer Group")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.customerGroupId,
      expression: "search.customerGroupId"
    }],
    staticClass: "input mr6 h34",
    staticStyle: {
      "padding": "0",
      "width": "200px"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.search, "customerGroupId", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.filterSearch]
    }
  }, [_c('option', {
    staticClass: "option-all",
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Customer Group")]), _vm._l(_vm.customerGroups, function (item, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": item === null || item === void 0 ? void 0 : item.id
      }
    }, [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.name) || 'N/A') + " ")]);
  })], 2)])])]), _c('div', {
    staticClass: "go-left"
  }, [_c('button', {
    staticClass: "btn btn-info mt24",
    on: {
      "click": _vm.filterSearch
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  }), _vm._v(" Search ")])])])]), _c('div', {
    staticClass: "table-control"
  }, [_c('table', {
    staticClass: "table wh800",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "150px"
    }
  }, [_vm._v("Code")]), _c('th', {
    attrs: {
      "width": "150px"
    }
  }, [_vm._v("Full Name")]), _c('th', [_vm._v("BP Name")]), _c('th', {
    attrs: {
      "width": "150px"
    }
  }, [_vm._v("Phone")]), _c('th', {
    attrs: {
      "width": "150px"
    }
  }, [_vm._v("Email")]), _c('th', {
    staticClass: "txt-center",
    attrs: {
      "width": "150px"
    }
  }, [_vm._v("Total Point")]), _c('th', {
    attrs: {
      "width": "150px"
    }
  }, [_vm._v("Custom Group")]), _c('th', {
    attrs: {
      "width": "150px"
    }
  }, [_vm._v("Joined")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "100px"
    }
  }, [_vm._v("Verified")]), _c('th', {
    staticClass: "text-center",
    attrs: {
      "width": "100px"
    }
  }, [_vm._v("Status")]), _vm.checkoutPermission('BLOCK_CUSTOMER_ACCOUNT') ? _c('th', {
    staticClass: "w80 txt-center"
  }, [_vm._v("Action")]) : _vm._e()])]), _vm.checkoutPermission('LIST_CUSTOMER_ACCOUNT') ? _c('tbody', _vm._l(_vm.data.customers, function (customer, index) {
    return _c('tr', {
      key: (customer, index),
      staticClass: "hover-list"
    }, [_c('td', [_vm._v(_vm._s((customer === null || customer === void 0 ? void 0 : customer.code) || 'N/A'))]), _c('td', {
      attrs: {
        "width": "150px",
        "title": [(customer === null || customer === void 0 ? void 0 : customer.firstName) || 'N/A', (customer === null || customer === void 0 ? void 0 : customer.lastName) || 'N/A'].join(' ')
      }
    }, [_c('div', {
      staticClass: "text-limit"
    }, [_c('div', {
      staticClass: "text"
    }, [_vm._v(" " + _vm._s([(customer === null || customer === void 0 ? void 0 : customer.firstName) || 'N/A', (customer === null || customer === void 0 ? void 0 : customer.lastName) || 'N/A'].join(' ')) + " ")])])]), _c('td', {
      attrs: {
        "width": "150px",
        "title": (customer === null || customer === void 0 ? void 0 : customer.bpName) || 'N/A'
      }
    }, [_c('div', {
      staticClass: "text-limit"
    }, [_c('div', {
      staticClass: "text"
    }, [_vm._v(" " + _vm._s((customer === null || customer === void 0 ? void 0 : customer.bpName) || 'N/A') + " ")])])]), _c('td', [_vm._v(_vm._s(_vm.$helper.formatPhoneNumber(customer === null || customer === void 0 ? void 0 : customer.phone) || 'N/A'))]), _c('td', [_vm._v(_vm._s((customer === null || customer === void 0 ? void 0 : customer.email) || 'N/A'))]), _c('td', {
      staticClass: "txt-center"
    }, [_vm._v(_vm._s((customer === null || customer === void 0 ? void 0 : customer.totalPoints) || '0'))]), _c('td', [_vm._v(_vm._s((customer === null || customer === void 0 ? void 0 : customer.customerGroup) || 'N/A'))]), _c('td', [_vm._v(_vm._s((customer === null || customer === void 0 ? void 0 : customer.createdAt) && _vm.$helper.formatDate(customer === null || customer === void 0 ? void 0 : customer.createdAt)))]), _c('td', {
      staticClass: "text-center",
      class: customer !== null && customer !== void 0 && customer.isVerified ? 'txt-success text-capitalize' : 'txt-danger text-capitalize'
    }, [_vm._v(" " + _vm._s(customer === null || customer === void 0 ? void 0 : customer.isVerified))]), _c('td', {
      staticClass: "text-center",
      class: (customer === null || customer === void 0 ? void 0 : customer.status) == 'active' ? 'txt-success text-capitalize' : 'txt-danger text-capitalize'
    }, [_vm._v(" " + _vm._s(customer === null || customer === void 0 ? void 0 : customer.status) + " ")]), _c('td', {
      staticClass: "txt-center"
    }, [_vm.checkoutPermission('BLOCK_CUSTOMER_ACCOUNT') && customer.status == 'active' ? _c('button', {
      staticClass: "btn btn-danger btn-xs",
      on: {
        "click": function ($event) {
          return _vm.popup('active', index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-times",
      attrs: {
        "aria-hidden": "true"
      }
    })]) : _c('button', {
      staticClass: "btn btn-success btn-xs",
      on: {
        "click": function ($event) {
          return _vm.popup('inactive', index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-check",
      attrs: {
        "aria-hidden": "true"
      }
    })]), _vm.checkoutPermission('GET_CUSTOMER_PROFILE') ? _c('button', {
      staticClass: "btn btn-info btn-xs ml5",
      on: {
        "click": function ($event) {
          return _vm.getCustomerDetail(customer === null || customer === void 0 ? void 0 : customer.id);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-eye"
    })]) : _vm._e()])]);
  }), 0) : _vm._e()]), _vm.isFetching ? _c('TableLoading') : _vm._e(), _vm.isNoData && !_vm.isFetching ? _c('NoItem') : _vm._e()], 1), _c('div', {
    staticClass: "total p12"
  }, [_c('div', {
    staticClass: "ul-xl-li go-left"
  }, [_vm._v(" Total : " + _vm._s(_vm.data.pagination.total) + " ")]), _vm.data.pagination.totalPage ? _c('Pagination', {
    attrs: {
      "pagination": _vm.data.pagination.totalPage
    }
  }) : _vm._e()], 1)])]), _vm.displayModalDetail ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": function ($event) {
        _vm.displayModalDetail = false;
      }
    }
  }), _c('div', {
    staticClass: "modal-md customer-list-wrapper",
    staticStyle: {
      "height": "90vh"
    }
  }, [_vm._m(0), _vm.data.customer ? _c('div', {
    staticClass: "modal-body p16 customer-list-info"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }), _c('div', {
    staticClass: "col-8"
  }, [_c('img', {
    staticClass: "w100 h100 cover mb12",
    attrs: {
      "src": _vm.getFullImage((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$customer = _vm$data.customer) === null || _vm$data$customer === void 0 ? void 0 : _vm$data$customer.imageUrl)
    }
  })])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("ID")]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v(": " + _vm._s(((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$customer = _vm$data2.customer) === null || _vm$data2$customer === void 0 ? void 0 : _vm$data2$customer.id) || 'N/A'))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Code")]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v(": " + _vm._s(((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : (_vm$data3$customer = _vm$data3.customer) === null || _vm$data3$customer === void 0 ? void 0 : _vm$data3$customer.code) || 'N/A'))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Full Name")]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v(": " + _vm._s([((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : (_vm$data4$customer = _vm$data4.customer) === null || _vm$data4$customer === void 0 ? void 0 : _vm$data4$customer.firstName) || 'N/A', ((_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : (_vm$data5$customer = _vm$data5.customer) === null || _vm$data5$customer === void 0 ? void 0 : _vm$data5$customer.lastName) || 'N/A'].join(' ')) + " ")])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("BP Name")]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v(": " + _vm._s(((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : (_vm$data6$customer = _vm$data6.customer) === null || _vm$data6$customer === void 0 ? void 0 : _vm$data6$customer.bpName) || 'N/A'))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Customer Group")]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v(": " + _vm._s(((_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : (_vm$data7$customer = _vm$data7.customer) === null || _vm$data7$customer === void 0 ? void 0 : (_vm$data7$customer$cu = _vm$data7$customer.customerGroup) === null || _vm$data7$customer$cu === void 0 ? void 0 : _vm$data7$customer$cu.name) || 'N/A'))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Gender")]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v(": " + _vm._s(((_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : (_vm$data8$customer = _vm$data8.customer) === null || _vm$data8$customer === void 0 ? void 0 : _vm$data8$customer.gender) || 'N/A'))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Email")]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v(": " + _vm._s(((_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : (_vm$data9$customer = _vm$data9.customer) === null || _vm$data9$customer === void 0 ? void 0 : _vm$data9$customer.email) || 'N/A'))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Phone")]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v(": " + _vm._s(_vm.$helper.formatPhoneNumber((_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : (_vm$data10$customer = _vm$data10.customer) === null || _vm$data10$customer === void 0 ? void 0 : _vm$data10$customer.phone) || 'N/A'))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Total Point")]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v(": " + _vm._s(((_vm$data11 = _vm.data) === null || _vm$data11 === void 0 ? void 0 : (_vm$data11$customer = _vm$data11.customer) === null || _vm$data11$customer === void 0 ? void 0 : _vm$data11$customer.totalPoints) || '0'))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Type")]), _c('div', {
    staticClass: "col-8 text-capitalize"
  }, [_vm._v(": " + _vm._s(((_vm$data12 = _vm.data) === null || _vm$data12 === void 0 ? void 0 : (_vm$data12$customer = _vm$data12.customer) === null || _vm$data12$customer === void 0 ? void 0 : _vm$data12$customer.type) || 'N/A'))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Provider")]), _c('div', {
    staticClass: "col-8 text-capitalize"
  }, [_vm._v(": " + _vm._s(((_vm$data13 = _vm.data) === null || _vm$data13 === void 0 ? void 0 : (_vm$data13$customer = _vm$data13.customer) === null || _vm$data13$customer === void 0 ? void 0 : _vm$data13$customer.provider) || 'N/A'))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Request BP")]), _c('div', {
    staticClass: "col-8"
  }, [_vm._v(": " + _vm._s((_vm$data14 = _vm.data) === null || _vm$data14 === void 0 ? void 0 : (_vm$data14$customer = _vm$data14.customer) === null || _vm$data14$customer === void 0 ? void 0 : _vm$data14$customer.reqBpStatus))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "col-8 text-capitalize"
  }, [_vm._v(": " + _vm._s(((_vm$data15 = _vm.data) === null || _vm$data15 === void 0 ? void 0 : (_vm$data15$customer = _vm$data15.customer) === null || _vm$data15$customer === void 0 ? void 0 : _vm$data15$customer.status) || 'N/A'))])]), _c('div', {
    staticClass: "row mb8"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Verified")]), _c('div', {
    staticClass: "col-8 text-capitalize"
  }, [_vm._v(": " + _vm._s((_vm$data16 = _vm.data) === null || _vm$data16 === void 0 ? void 0 : (_vm$data16$customer = _vm$data16.customer) === null || _vm$data16$customer === void 0 ? void 0 : _vm$data16$customer.isVerified))])]), _c('div', {
    staticClass: "row mb8",
    staticStyle: {
      "border-bottom": "0"
    }
  }, [_c('div', {
    staticClass: "col-4"
  }, [_vm._v("Joined Date")]), _c('div', {
    staticClass: "col-8 text-capitalize"
  }, [_vm._v(": " + _vm._s(((_vm$data17 = _vm.data) === null || _vm$data17 === void 0 ? void 0 : (_vm$data17$customer = _vm$data17.customer) === null || _vm$data17$customer === void 0 ? void 0 : _vm$data17$customer.createdAt) && _vm.$helper.formatDate((_vm$data18 = _vm.data) === null || _vm$data18 === void 0 ? void 0 : (_vm$data18$customer = _vm$data18.customer) === null || _vm$data18$customer === void 0 ? void 0 : _vm$data18$customer.createdAt)))])])]), _c('div', {
    staticClass: "col-12 pt20"
  }, [_vm._m(1), _c('table', {
    staticClass: "table",
    attrs: {
      "cellspacing": "0"
    }
  }, [_vm._m(2), _c('tbody', _vm._l((_vm$data19 = _vm.data) === null || _vm$data19 === void 0 ? void 0 : (_vm$data19$customer = _vm$data19.customer) === null || _vm$data19$customer === void 0 ? void 0 : _vm$data19$customer.addresses, function (item, index) {
    var _item$district, _item$province;
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : item.id) || index + 1))]), _c('td', [_c('div', [_c('p', {
      staticClass: "mb0"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.name))]), _c('small', {
      staticClass: "color-default"
    }, [_vm._v(" #" + _vm._s(item === null || item === void 0 ? void 0 : item.houseNo) + ", St." + _vm._s((item === null || item === void 0 ? void 0 : item.street).split("#").join("")) + ", " + _vm._s(item === null || item === void 0 ? void 0 : (_item$district = item.district) === null || _item$district === void 0 ? void 0 : _item$district.name) + ", " + _vm._s(item === null || item === void 0 ? void 0 : (_item$province = item.province) === null || _item$province === void 0 ? void 0 : _item$province.name) + " ")]), item !== null && item !== void 0 && item.lat && item !== null && item !== void 0 && item.lng ? _c('a', {
      staticClass: "ml10",
      attrs: {
        "href": 'https://www.google.com/maps/search/?api=1&query=' + (item === null || item === void 0 ? void 0 : item.lat) + ',' + (item === null || item === void 0 ? void 0 : item.lng),
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v("Open")]) : _vm._e()])])]);
  }), 0)]), (_vm$data20 = _vm.data) !== null && _vm$data20 !== void 0 && (_vm$data20$customer = _vm$data20.customer) !== null && _vm$data20$customer !== void 0 && _vm$data20$customer.addresses && ((_vm$data21 = _vm.data) === null || _vm$data21 === void 0 ? void 0 : (_vm$data21$customer = _vm$data21.customer) === null || _vm$data21$customer === void 0 ? void 0 : _vm$data21$customer.addresses.length) <= 0 ? _c('NoItem') : _vm._e()], 1)]) : _vm._e(), _c('div', {
    staticClass: "modal-footer border-top"
  }, [_c('button', {
    staticClass: "btn btn-info btn-xs go-right",
    on: {
      "click": function ($event) {
        _vm.displayModalDetail = false;
      }
    }
  }, [_vm._v(" Close ")])])])]) : _vm._e(), _vm.modal.delete ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal-md"
  }, [_vm._m(3), _vm._m(4), _c('div', {
    staticClass: "modal-footer border-top"
  }, [!_vm.isDeleting ? _c('button', {
    staticClass: "btn btn-light-danger btn-xs go-right",
    on: {
      "click": _vm.confirmBlock
    }
  }, [_vm._v(" " + _vm._s(_vm.data.fields.status === "active" ? "Block This Customer" : "Unblock This Customer") + " ")]) : _c('button', {
    staticClass: "btn btn-info btn-xs go-right"
  }, [_vm._v(" Loading... ")]), _c('button', {
    staticClass: "btn btn-light-secondary btn-xs mr12 go-right",
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" No ")])])])]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-header border-bottom txt-info"
  }, [_c('span', {
    staticClass: "txt-sm"
  }, [_vm._v("Customer Detail")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "font15 pb5"
  }, [_c('b', [_vm._v("Customer address")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', {
    staticStyle: {
      "background": "#ededed"
    }
  }, [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Address")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-header border-bottom txt-info"
  }, [_c('span', {
    staticClass: "txt-sm"
  }, [_vm._v("Update Customer Status")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-body",
    staticStyle: {
      "overflow-y": "hidden",
      "padding": "16px"
    }
  }, [_c('span', [_vm._v("Do you want to update this customer status?")])]);

}]

export { render, staticRenderFns }