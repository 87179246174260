var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [!_vm.isLogging ? _c('div', [_c('div', {
    staticClass: "sidebar-container m-scroll"
  }, [_c('Sidebar')], 1), _c('Navbar'), _c('div', {
    staticClass: "wrapper-container"
  }, [_c('router-view')], 1)], 1) : _c('router-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }