<template>
    <div class="col-12">
        <div v-if="display?.media" class="overlay">
            <div @click="closeModal" class="overlay"></div>
            <div class="modal-md">
                <media-upload type="single" :method="closeModal" @existedMedia="existedMediaCallback"
                    @newMedia="newMediaCallBack">
                </media-upload>
            </div>
        </div>
        <div class="col-6 ph6 media-container">
            <label for="">Upload Image <span class="sub">(Support JPG, PNG)</span></label>
            <div class="media-wrapper">
                <div v-if="body?.image?.file" @click="imagePreview" class="go-left pointer display-flex img-absolute">
                    <image-loading :src="body?.image?.file && fileToPath(body?.image?.file)" height="35px" className="attachment mr6" />
                    <div class="p-file-name">
                        <span class="font12 p-file-name mr6"
                            style="line-height: 35px;">{{ body?.image?.file?.name }}</span>
                    </div>
                    <span class="font12" style="line-height: 35px;">( {{ body?.image?.file?.size / 1000 }} KB
                        )</span>                   
                </div>
                <div v-else-if="value || (data.media && Object.keys(data.media).length != 0)" @click="imagePreview"
                    class="go-left pointer display-flex img-absolute" style="display: flex;">
                    <image-loading :src="data?.media?.url && getFullImage(data?.media?.url || value) || value" height="35px" className="attachment mr6" />
                    <div class="p-file-name">
                        <span class="font12 p-file-name mr6"
                            style="line-height: 35px;">{{ data?.media?.url && getNameFromUrl(data?.media?.url) }}</span>
                    </div>
                </div>
                <div v-else class="go-left img-relative">
                    <image-loading :src="require('@/assets/icons/browse-image.svg')" width="11px"
                        className="attachment" />
                    <span>No file selected</span>
                </div>
            </div>
            <label @click="chooseImage" id="image" class="btn btn-secondary btn-sm go-right mt2" style="width: 100%;">Browse</label>
        </div>
        <input type="hidden" :value="value" @input="emitData(value)" />
    </div>
</template>
<script>
import MediaUpload from "../media/index.vue"
export default {
    name: "upload-image-file",
    props: {
        value: { type: String, default: "" }
    },
    data() {
        return {
            body: {
                image: {
                    file: "",
                    presign: "",
                }
            },
            display: {
                imagePreview: false,
                media: false,
            },
            data: {
                categoryList: [],
            },
        }
    },
    components: { MediaUpload },
    methods: {
        // start uploud file
        closeModal() {
            this.display = {
                imagePreview: false,
                media: false,
            };
        },
        imagePreview() {
            if (this.body?.image?.file || this.data?.media) {
                this.display.imagePreview = true;
            }
        },
        chooseImage() {
            this.display.media = true;
        },
        existedMediaCallback(media) {
            this.data.media = media;
            this.closeModal();
        },
        newMediaCallBack(file) {
            this.body.image.file = file;
            this.closeModal();
        },
        getFullImage(path) {
            let dev = this.$helper.getFullImage(path)
            this.emitData(dev)
            return dev
        },
        getNameFromUrl(url) {
            return url?.split("/").pop();
        },
        fileToPath(file) {
            return window.URL.createObjectURL(file);
        },
        emitData(value) {
            this.$emit('input', value)
        }
        // end upload
    }
}
</script>