var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('image-loading-component', [_c('img', {
    class: _vm.className || '',
    style: {
      borderRadius: _vm.radiusChange,
      width: _vm.widthChange || '100%',
      height: _vm.heightChange || '100%',
      objectFit: _vm.objectFit || 'contain'
    },
    attrs: {
      "slot": "image",
      "src": _vm.src || require('../../image-detail/noImage.jpg')
    },
    slot: "image"
  }), _c('div', {
    staticClass: "loading-style",
    attrs: {
      "slot": "preloader"
    },
    slot: "preloader"
  }, [_c('img', {
    class: _vm.className || 'image-loading-class',
    attrs: {
      "src": require('../../image-detail/loading1.gif')
    }
  })]), _c('div', {
    staticClass: "image-not-found",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  }, [_vm._v("Wrong Image Url")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }