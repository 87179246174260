var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_vm.imagePreview ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal"
  }, [_c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.getFullImage(_vm.serviceDetail.media[_vm.imagePreviewIndex].url)
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "font18",
    staticStyle: {
      "font-weight": "600 !important",
      "margin": "0px 4px 0px 4px"
    }
  }, [_c('i', {
    staticClass: "fas fa-arrow-left pointer",
    staticStyle: {
      "margin-right": "18px",
      "cursor": "pointer"
    },
    attrs: {
      "title": "Back"
    },
    on: {
      "click": _vm.goBack
    }
  }), _vm._v(" Service Ticket Detail "), _c('hr')]), _vm.serviceDetail ? _c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "col-6 col-md-6 p4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(0), _c('li', [_vm._m(1), _c('div', {
    staticClass: "col-7"
  }, _vm._l(_vm.serviceDetail.media, function (image, index) {
    return _c('img', {
      key: index,
      staticClass: "pointer",
      attrs: {
        "src": _vm.getFullImage(image.url),
        "width": "40px"
      },
      on: {
        "click": function ($event) {
          return _vm.preview(index);
        }
      }
    });
  }), 0)]), _c('li', [_vm._m(2), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.type.name))])]), _c('li', [_vm._m(3), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.subId))])]), _c('li', [_vm._m(4), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.provider.toUpperCase()))])]), _c('li', [_vm._m(5), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.problemType))])]), _c('li', [_vm._m(6), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.techName))])]), _c('li', [_vm._m(7), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.totalAmount))])]), _c('li', [_vm._m(8), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.remarks))])]), _c('li', [_vm._m(9), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.status))])]), _c('li', [_vm._m(10), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.$helper.formatDate(_vm.serviceDetail.createdAt)))])])])])]), _c('div', {
    staticClass: "col-6 col-md-6 p4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(11), _c('li', [_vm._m(12), _vm.serviceDetail.account ? _c('div', {
    staticClass: "col-7 profile"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.account.firstName + ' ' + _vm.serviceDetail.account.lastName))]) : _vm._e()]), _c('li', [_vm._m(13), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.contactPerson))])]), _c('li', [_vm._m(14), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.contactPhone))])])])]), _c('div', {
    staticClass: "row mt12"
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(15), _c('li', [_vm._m(16), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.equipment.product.name))])]), _c('li', [_vm._m(17), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.equipment.brand.name))])]), _c('li', [_vm._m(18), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.equipment.subcategory.name))])])])]), _c('div', {
    staticClass: "row mt12"
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(19), _c('li', [_vm._m(20), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.location.type))])]), _c('li', [_vm._m(21), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.location.name))])]), _c('li', [_vm._m(22), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.serviceDetail.addressDetail))])])])])]), _c('div', {
    staticClass: "row p4 mt12",
    attrs: {
      "hidden": ""
    }
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(23), _c('li', [_c('br'), _c('div', {
    staticClass: "split"
  }, [_vm._m(24), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.serviceDetail.text,
      expression: "serviceDetail.text"
    }],
    staticClass: "input outline-dark desc",
    attrs: {
      "type": "text",
      "placeholder": "input description..."
    },
    domProps: {
      "value": _vm.serviceDetail.text
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.serviceDetail, "text", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "split"
  }, [_vm._m(25), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.serviceDetail.status,
      expression: "serviceDetail.status"
    }],
    staticClass: "input input-xs mr6 filter-type",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.serviceDetail, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "pending"
    }
  }, [_vm._v("Pending")]), _c('option', {
    attrs: {
      "value": "closed"
    }
  }, [_vm._v("Closed")]), _c('option', {
    attrs: {
      "value": "open"
    }
  }, [_vm._v("Open")])])]), _c('div', {
    staticClass: "mt20",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('button', {
    staticClass: "btn btn-light-secondary mr8",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Cancel ")]), !_vm.isUpdating ? _c('button', {
    staticClass: "btn btn-info"
  }, [_vm._v(" Update ")]) : _c('button', {
    staticClass: "btn btn-info"
  }, [_vm._v("Loading...")])]), _c('br')])])])]) : _vm._e()])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fa fa-info-circle"
  }), _vm._v(" Request Detail")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Media")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Type")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("SAP ID")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Provider")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Problem Type")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Tech Name")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Total")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Remark")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Status")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Created At")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fa fa-phone"
  }), _vm._v(" Contact Info")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5 pt6"
  }, [_c('b', [_vm._v("Customer")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Contact Person")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Contact Phone")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fa fa-cog"
  }), _vm._v(" Equipment")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Product")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Brand")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Category")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fa fa-map-marker"
  }), _vm._v(" Location")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Type")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Name")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Address")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fas fa-edit"
  }), _vm._v("Update Ticket")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31 font14"
  }, [_vm._v("Description :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31 font14"
  }, [_vm._v("Status :")])]);

}]

export { render, staticRenderFns }