<template>
    <image-loading-component>
        <img slot="image" :src="src || require('@/assets/images/product.png')" :class="className || 'image-background'" />
        <img slot="preloader" :src="require('@/assets/images/loading1.gif')" :class="className || 'image-background'" />
        <div slot="error" class="image-not-found">Wrong Image Url</div>
    </image-loading-component>
</template>
<script>
import ImageLoadingComponent from './component/index.vue'
export default {
    name: "image-loading",
    props: {
        src: String,
        width: String,
        height: String,
        className: String
    },
    components: { ImageLoadingComponent },
}
</script>
<style scoped>
    .image-background {
        width: 32px;
        height:32px;
        object-fit: cover;
        border-radius: 50%;
    }
    .image-not-found {
        width: 100%;
        position: absolute;
        right: 0;
        left: 0;
        top: 30px;
        margin: 0 auto;
        text-align: center;
    }
</style>