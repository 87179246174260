import Pagination from "@/components/share/pagination";
import Service from "../../../../utils/api/service";
import Helper from "../../../../utils/global/func";
import TableLoading from "./../../../../components/share/table-loading";
import NoItem from "./../../../../components/share/table-no-item";
import { mapState } from "vuex";

export default {
  name: "ListTopSelection",
  data() {
    return {
      isFetching: true,
      isDeleting: false,
      isUpdating: false,
      isNoData: false,
      isEditing: false,
      fields: {
        id: "",
        index: "",
      },
      display: {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          update: {
            data: "",
            show: false,
            index: -1,
          },
        },
      },
      data: {
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
      },
      search: {
        topSelection: true,
        sort: 'top_selection_asc'
      },
    };
  },
  components: {
    Pagination,
    TableLoading,
    NoItem,
  },
  created() {
    this.getAllTopSelection();
  },
  watch: {
    "$route.fullPath": function() {
      this.getAllTopSelection();
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {},
  methods: {
    checkoutPermission(permissionName) {
      if (this.permissions) {
        let result = false;
        this.permissions.find((item) => {
          item.permissions.find((permission) => {
            if (permission.name == permissionName) {
              result = true;
            }
          });
        });

        return result;
      }
    },

    getAllTopSelection() {
      this.isFetching = true
      let query = this.$root.$route.query;
      let setQuery = this.$helper.filterKeyMapping({page: query?.page || 1, ...this.search})
      let params = this.$helper.queryParam(setQuery)

      Service.getAllProducts(params).then((response) => {
        this.isFetching = false;
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response == "ok") {
                this.getAllTopSelection();
              }
            });
          } else if (response.statusCode == "403") {
            this.isNoData = true;
            this.data.topSelection = [];
            this.$toasted.show(response.message.capitalize());
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          if (response.data.length > 0) {
            this.isNoData = false;
            response.data.map(item => {
              item.editSortOrder = item.topSelection
            })
            this.data.topSelection = response.data;
            this.data.pagination = Helper.calculatePagination(response.meta);
          } else {
            this.data.topSelection = [];
            this.isNoData = true;
            this.resetPagination();
          }
        }
      });
    },

    getFullImage(path) {
      return process.env.VUE_APP_BASE_URL_IMAGE + path;
    },

    roundInt(int){
      return +int.toFixed(2)
    },

    popupModal(index) {
      this.fields.index = index;
      this.display.modal.delete.show = true;
    },

    deleteTopSelection() {
      this.isDeleting = true;
      let topSelectionId = this.data.topSelection[this.fields.index].id;
      let body = {
        items: [
          { id: topSelectionId, topSelection: 0 }
        ]
      }

      Service.updateProductTopSelection(body).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == "4410") {
            Service.refreshToken().then((response) => {
              if (response === "ok") {
                this.deleteTopSelection();
              }
            });
          } else {
            this.isDeleting = false;
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.isDeleting = false;
          this.data.topSelection.splice(this.fields.index, 1);
          this.data.pagination.total -= 1;
          this.$toasted.show("Top selection product has been deleted.");
          this.closeModal();
        }
      });
    },

    displayFormUpdate(index) {
      var topSelection = this.data.topSelection[index];
      this.display.modal.update.data = {
        id: topSelection.id,
        name: topSelection.name,
        code: topSelection.code,
        imageUrl: topSelection.imageUrl,
        price: topSelection.price,
        sortOrder: topSelection.sortOrder,
      };
      this.display.modal.update.show = true;
      this.display.modal.update.index = index;
    },

    confirmUpdate() {
      let newBody = []
      this.data.topSelection.map(item => {
        let items = {
          topSelection: parseInt(item?.editSortOrder),
          id: parseInt(item.id)
        }
        if(item.topSelection != parseInt(item?.editSortOrder)){
          newBody.push(items)
        }
      })

      newBody.length > 0 && (this.isUpdating = true) && Service.updateProductTopSelection({items: newBody}).then((response) => {
        this.isEditing = false
        this.isUpdating = false
        if (response?.statusCode) {
          if (response?.statusCode === "4410") {
            Service.refreshToken().then((response) => {
              if (response === "ok") {
                this.confirmUpdate();
              }
            });
          } else {
            this.$toasted.show(response.message.capitalize());
          }
        } else {
          this.$toasted.show("Top selection product has been updated.");
          let query = this.$helper.filterKeyMapping({ limit: 10, page: 1 })
          this.$router.push({ query }).catch(() => { });
        }
      });
    },

    closeModal() {
      this.display = {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
          update: {
            data: "",
            show: false,
            index: -1,
          },
        },
      };
    },

    resetPagination() {
      this.data.pagination = {
        limit: 10,
        page: 1,
        total: 0,
        totalPage: 0,
      };
    },

    updated(value) {
      this.data.brands[this.fields.index] = value;
    },
  },
};
