var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "box-nav-profile mv32"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/logo.png'),
      "height": "64px"
    }
  })]), _c('ul', _vm._l(_vm.menu_list, function (item, index) {
    return _c('li', {
      key: (item, index),
      staticClass: "ul-nav-menu"
    }, [!item.children ? _c('div', [!item.hidden ? _c('router-link', {
      class: _vm.$root.$route.name === item.routeName ? 'active' : '',
      attrs: {
        "to": {
          name: item.routeName,
          params: {
            title: item.title
          }
        }
      }
    }, [_c('span', {
      staticClass: "icon"
    }, [_c('i', {
      class: item.icon
    })]), _c('span', [_vm._v(_vm._s(item.name))])]) : _vm._e()], 1) : _c('div', [_c('li', {
      staticClass: "dropdown",
      on: {
        "click": function ($event) {
          return _vm.dorpDown(index);
        }
      }
    }, [_c('i', {
      class: item.icon + ' icon'
    }), _vm._v(" " + _vm._s(item.name) + " "), _c('i', {
      staticClass: "fas fa-caret-down dropdown-icon"
    })]), item.dropdown ? _c('ul', {
      staticClass: "sidebar animate-bottom"
    }, _vm._l(item.children, function (child, childIndex) {
      return _c('li', {
        key: (child, childIndex)
      }, [_c('router-link', {
        class: _vm.$root.$route.name == child.routeName ? 'active' : '',
        attrs: {
          "to": {
            name: child.routeName
          }
        }
      }, [_c('span', {
        staticClass: "icon"
      }, [_c('i', {
        class: child.icon
      })]), _c('span', [_vm._v(_vm._s(child.name))])])], 1);
    }), 0) : _vm._e()])]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }