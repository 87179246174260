import Service from "../../../../../utils/api/service";
import Helper from "../../../../../utils/global/func";
import NoItem from "../../../../../components/share/table-no-item";
import Pagination from "../../../../../components/share/pagination";
import { mapState } from "vuex";

export default {
    name: "ListVaraintOption",
    props: {
        id: Number,
    },
    data() {
        return {
            isFetching: true,
            isNoData: false,
            display: {
                modal: {
                    delete: {
                        index: -1,
                        show: false,
                    },
                    large: false,
                },
            },
            data: {
                variantList: [],
                pagination: {
                    limit: 10,
                    page: 1,
                    total: 0,
                    totalPage: 0,
                },
            },
        };
    },
    components: {
        NoItem,
        Pagination,
    },
    created() {
        this.getProdcutVaraint(this.id);
    },
    watch: {
        "$route.fullPath": function () {
            this.getProdcutVaraint(this.id);
        },
    },
    computed: {
        ...mapState(["permissions"]),
    },
    mounted() {},
    methods: {
        checkoutPermission(permissionName) {
            if (this.permissions) {
                let result = false;
                this.permissions.find((item) => {
                    item.permissions.find((permission) => {
                        if (permission.name == permissionName) {
                            result = true;
                        }
                    });
                });

                return result;
            }
        },
        renderClass() {
            return {
                "no-data": this.isNoData,
                "": !this.isNoData,
            };
        },
        getProdcutVaraint(id) {
            let queryPage = this.$root.$route.query.page;
            let queryLimit = this.$root.$route.query.limit;
            if (queryPage != undefined) this.data.pagination.page = queryPage;
            if (queryLimit != undefined)
                this.data.pagination.limit = queryLimit;
            let offset =
                this.data.pagination.limit * this.data.pagination.page -
                this.data.pagination.limit;
            let param =
                "?productId=" +
                id +
                "&limit=" +
                this.data.pagination.limit +
                "&offset=" +
                offset;

            Service.getProductVariantDetail(param).then((response) => {
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.getProdcutVaraint(id);
                            }
                        });
                    } else {
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    if (response.data.length > 0) {
                        this.isFetching = false;
                        this.data.variantList = response.data;
                        this.data.pagination = Helper.calculatePagination(
                            response?.meta
                        );
                    } else {
                        this.isNoData = true;
                    }
                }
            });
        },
        formatPrice(price) {
            return Helper.formatPrice(price);
        },

        getFullImage(path) {
            return process.env.VUE_APP_BASE_URL_IMAGE + path;
        },

        htmlToText(html) {
            return Helper.convertToPlain(html);
        },

        popupModal(index) {
            this.fields.index = index;
            this.display.modal.delete.show = true;
        },

        updateVariant(productId, status) {
            let productStatus = status === "active" ? "inactive" : "active";
            let body = { status: productStatus };
            Service.updateVariant(productId, body).then((response) => {
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.updateVariant();
                            }
                        });
                    } else {
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    let productUpdatedIndex = this.data.variantList.findIndex(
                        (product) => product.id === productId
                    );
                    this.data.variantList[productUpdatedIndex].status =
                        productStatus;
                    this.$toasted.show(
                        "Variant has been updated to " +
                            productStatus.capitalize()
                    );
                }
            });
        },

        deleteOptionValue() {
            this.isDeleting = true;
            let optionValueId = this.data.optionValues[this.fields.index].id;

            Service.deleteOptionValue(optionValueId).then((response) => {
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.deleteOptionValue();
                            }
                        });
                    } else {
                        this.isDeleting = false;
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    this.isDeleting = false;
                    this.data.optionValues.splice(this.fields.index, 1);
                    this.data.pagination.total -= 1;
                    this.$toasted.show("Option value has been deleted.");
                    this.closeModal();
                }
            });
        },

        formatNumber(number) {
            let newNumber = number || 0;
            let result = newNumber
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return result;
        },

        closeModal() {
            this.display = {
                modal: {
                    delete: {
                        index: -1,
                        show: false,
                    },
                    large: false,
                },
            };
        },
    },
};
