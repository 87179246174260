var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_vm.display.imagePreview ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal"
  }, [_vm.data.media && _vm.data.media.url ? _c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.getFullImage(_vm.data.media.url)
    }
  }) : _c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.getFullImage(_vm.data.imageUrl)
    }
  })])]) : _vm._e(), _vm.display.media ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal-md"
  }, [_c('Media', {
    attrs: {
      "type": "single",
      "method": _vm.closeModal,
      "folder": "voucher"
    },
    on: {
      "existedMedia": _vm.existedMediaCallback
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Update Theme ")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "split mt35"
  }, [_vm._m(0), _c('div', [_c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.data.name,
      expression: "data.name"
    }],
    attrs: {
      "type": "text",
      "placeholder": "Input name..."
    },
    domProps: {
      "value": _vm.data.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.data, "name", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(1), _c('div', {
    staticClass: "media-container",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "border-radius": "4px",
      "border": "1px solid #ccc",
      "padding": "3px",
      "display": "inline-block"
    }
  }, [_vm.data.media && Object.keys(_vm.data.media).length != 0 ? _c('div', {
    staticClass: "go-left pointer",
    staticStyle: {
      "display": "flex"
    },
    on: {
      "click": _vm.imagePreview
    }
  }, [_c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.getFullImage(_vm.data.media.url),
      "height": "35px"
    }
  }), _c('div', {
    staticClass: "p-file-name"
  }, [_c('span', {
    staticClass: "font12 p-file-name mr6",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(_vm._s(_vm.getNameFromUrl(_vm.data.media.url)))])])]) : _vm.data.imageUrl && !_vm.data.image ? _c('div', {
    staticClass: "go-left pointer display-flex",
    on: {
      "click": _vm.imagePreview
    }
  }, [_c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.getFullImage(_vm.data.imageUrl),
      "height": "35px"
    }
  }), _c('div', {
    staticClass: "p-file-name"
  }, [_c('span', {
    staticClass: "font12 mr6",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(_vm._s(_vm.getNameFromUrl(_vm.data.imageUrl)))])])]) : _c('div', {
    staticClass: "go-left"
  }, [_c('img', {
    staticClass: "attachment mh6",
    attrs: {
      "src": require('@/assets/icons/Vectorpaperclip.png'),
      "width": "8px"
    }
  }), _c('span', {
    staticClass: "font12",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v("No file selected")])]), _c('label', {
    staticClass: "btn btn-secondary btn-sm go-right mt2 mr2",
    attrs: {
      "id": "image"
    },
    on: {
      "click": _vm.chooseImage
    }
  }, [_vm._v(" Choose file ")])])])])]), _c('div', {
    staticClass: "col-12 p6 mb12",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('button', {
    staticClass: "btn btn-light-secondary mr8",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Cancel ")]), !_vm.isUpdating ? _c('button', {
    staticClass: "btn btn-info",
    on: {
      "click": _vm.submitUpdate
    }
  }, [_vm._v(" Update ")]) : _c('button', {
    staticClass: "btn btn-info"
  }, [_vm._v("Loading...")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Name :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Image :")])]);

}]

export { render, staticRenderFns }