var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('image-loading-component', [_c('img', {
    class: _vm.className || 'image-background',
    attrs: {
      "slot": "image",
      "src": _vm.src || require('@/assets/images/product.png')
    },
    slot: "image"
  }), _c('img', {
    class: _vm.className || 'image-background',
    attrs: {
      "slot": "preloader",
      "src": require('@/assets/images/loading1.gif')
    },
    slot: "preloader"
  }), _c('div', {
    staticClass: "image-not-found",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  }, [_vm._v("Wrong Image Url")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }