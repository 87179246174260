var render = function render(){
  var _vm$data, _vm$data2, _vm$data3, _vm$data4, _vm$data5, _vm$data6, _vm$data7, _vm$data8, _vm$data9;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isFetching ? _c('div', {
    staticClass: "row bg-white p12 dashboard"
  }, [_c('TableLoading')], 1) : _c('div', [_c('div', {
    staticClass: "row pv12"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-8 txt-right pr6"
  }, [_c('span', {
    staticClass: "mh12"
  }, [_vm._v("From")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.startDate,
      expression: "filter.startDate"
    }],
    staticClass: "input h36",
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.filter.startDate
    },
    on: {
      "change": _vm.getPaymentGraphData,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filter, "startDate", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "mh12"
  }, [_vm._v("to")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.endDate,
      expression: "filter.endDate"
    }],
    staticClass: "input h36",
    staticStyle: {
      "width": "200px"
    },
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.filter.endDate
    },
    on: {
      "change": _vm.getPaymentGraphData,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filter, "endDate", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-3 col-md-6 p4",
    staticStyle: {
      "border-radius": "4px"
    }
  }, [_c('div', {
    staticClass: "row bg-white p12",
    staticStyle: {
      "height": "126px",
      "background": "white"
    }
  }, [_c('p', [_vm._v("Customers")]), _c('p', {
    staticStyle: {
      "font-size": "36px"
    }
  }, [_vm._v(_vm._s(_vm.$helper.formatNumber((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.customers)))]), _c('img', {
    staticClass: "db-image",
    attrs: {
      "src": require('@/assets/images/ic-user.png')
    }
  })]), _c('div', {
    staticClass: "row bg-white border-top pv6 ph12",
    staticStyle: {
      "min-height": "79px"
    }
  }, [_c('p', {
    staticClass: "mv6"
  }, [_vm._v("Customer Active : " + _vm._s(_vm.$helper.formatNumber((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : _vm$data2.activeCustomer)))]), _c('p', {
    staticClass: "mv6"
  }, [_vm._v("Customer Inactive : " + _vm._s(_vm.$helper.formatNumber((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.inActiveCustomer)))])])]), _c('div', {
    staticClass: "col-3 col-md-6 p4",
    staticStyle: {
      "border-radius": "4px"
    }
  }, [_c('div', {
    staticClass: "row bg-white p12",
    staticStyle: {
      "height": "126px",
      "background": "white"
    }
  }, [_c('p', [_vm._v("Products")]), _c('p', {
    staticStyle: {
      "font-size": "36px"
    }
  }, [_vm._v(_vm._s(_vm.$helper.formatNumber((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.products) || '0'))]), _c('img', {
    staticClass: "db-image",
    attrs: {
      "src": require('@/assets/images/ic-products.png')
    }
  })]), _c('div', {
    staticClass: "row bg-white border-top pv6 ph12",
    staticStyle: {
      "min-height": "79px"
    }
  })]), _c('div', {
    staticClass: "col-3 col-md-6 p4",
    staticStyle: {
      "border-radius": "4px"
    }
  }, [_c('div', {
    staticClass: "row bg-white p12",
    staticStyle: {
      "height": "126px",
      "background": "white"
    }
  }, [_c('p', [_vm._v("Success Order")]), _c('p', {
    staticStyle: {
      "font-size": "36px"
    }
  }, [_vm._v(_vm._s(_vm.$helper.formatNumber((_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : _vm$data5.successOrder)))]), _c('img', {
    staticClass: "db-image",
    attrs: {
      "src": require('@/assets/images/ic-order.png')
    }
  })]), _c('div', {
    staticClass: "row bg-white border-top pv6 ph12",
    staticStyle: {
      "min-height": "79px"
    }
  }, [_c('p', {
    staticClass: "mv6"
  }, [_vm._v("Total : " + _vm._s(_vm.$helper.formatPrice((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : _vm$data6.totalAmountSalOrder)))])])]), _c('div', {
    staticClass: "col-3 col-md-6 p4",
    staticStyle: {
      "border-radius": "4px"
    }
  }, [_c('div', {
    staticClass: "row bg-white p12",
    staticStyle: {
      "height": "126px",
      "background": "white"
    }
  }, [_c('p', [_vm._v("Order Status")]), _c('p', {
    staticStyle: {
      "font-size": "36px"
    }
  }, [_vm._v(_vm._s(_vm.$helper.formatNumber((_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : _vm$data7.totalOrderStatus)))]), _c('img', {
    staticClass: "db-image",
    attrs: {
      "src": require('@/assets/images/ic-pending-order.png')
    }
  })]), _c('div', {
    staticClass: "row bg-white border-top pv6 ph12",
    staticStyle: {
      "min-height": "79px"
    }
  }, [_c('p', {
    staticClass: "mv6"
  }, [_vm._v("Pending Order : " + _vm._s(_vm.$helper.formatNumber((_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : _vm$data8.pendingOrder)))]), _c('p', {
    staticClass: "mv6"
  }, [_vm._v("Order Failed : " + _vm._s(_vm.$helper.formatNumber((_vm$data9 = _vm.data) === null || _vm$data9 === void 0 ? void 0 : _vm$data9.orderFail)))])])]), _vm.userAccountAreaChart.labels.length > 0 ? _c('div', {
    staticClass: "col-6 col-md-12 p4"
  }, [_c('div', {
    staticClass: "bg-white",
    staticStyle: {
      "border-radius": "6px"
    }
  }, [_vm._m(1), _c('div', {
    staticClass: "row p16"
  }, [_c('div', {
    staticClass: "block-legend"
  }, _vm._l(_vm.data.userAccount, function (user, userIndex) {
    return _c('div', {
      staticClass: "legend-item"
    }, [_c('div', {
      staticClass: "legend-color",
      style: {
        'background': _vm.userAccountAreaChart.datasets[0].backgroundColor[userIndex]
      }
    }), _c('span', {
      staticClass: "font12"
    }, [_vm._v(_vm._s(user.label))])]);
  }), 0), _c('BarChart', {
    attrs: {
      "chartData": _vm.userAccountAreaChart
    }
  })], 1)])]) : _vm._e(), _vm.paymentMethodPieChart.labels.length > 0 ? _c('div', {
    staticClass: "col-6 col-md-12 p4"
  }, [_c('div', {
    staticClass: "bg-white",
    staticStyle: {
      "border-radius": "6px"
    }
  }, [_vm._m(2), _c('div', {
    staticClass: "row p16"
  }, [_c('PieChart', {
    attrs: {
      "chartData": _vm.paymentMethodPieChart
    }
  })], 1)])]) : _vm._e(), _vm.data.bestSales[0] ? _c('div', {
    staticClass: "col-6 col-md-12 p4"
  }, [_c('div', {
    staticClass: "bg-white",
    staticStyle: {
      "border-radius": "6px"
    }
  }, [_vm._m(3), _c('div', {
    staticClass: "row p16"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(4), _c('tbody', _vm._l(_vm.data.bestSales, function (bestSale, bestSaleIndex) {
    return _c('tr', {
      key: bestSale.productId
    }, [_c('td', [_vm._v(_vm._s(bestSaleIndex + 1))]), _c('td', [_vm._v(_vm._s(bestSale.name))]), _c('td', {
      staticClass: "txt-right"
    }, [_vm._v(_vm._s(_vm.$helper.formatPrice(bestSale.total)))])]);
  }), 0)])])])]) : _vm._e(), _vm.data.tickets[0] ? _c('div', {
    staticClass: "col-6 col-md-12 p4"
  }, [_c('div', {
    staticClass: "bg-white",
    staticStyle: {
      "border-radius": "6px"
    }
  }, [_vm._m(5), _c('div', {
    staticClass: "row p16"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(6), _c('tbody', _vm._l(_vm.data.tickets, function (ticket, ticketIndex) {
    return _c('tr', {
      key: ticket.productId
    }, [_c('td', [_vm._v(_vm._s(ticketIndex + 1))]), _c('td', [_vm._v(_vm._s(ticket.name))]), _c('td', {
      staticClass: "txt-right"
    }, [_vm._v(_vm._s(_vm.$helper.formatNumber(ticket.total)))])]);
  }), 0)])])])]) : _vm._e()])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-4 pv3"
  }, [_c('b', {
    staticClass: "font20"
  }, [_vm._v("Dashboard")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row border-bottom p12 txt-center font20"
  }, [_c('b', [_vm._v("Customer Registration")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row border-bottom p12 txt-center font20"
  }, [_c('b', [_vm._v("Used Payment")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row border-bottom p12 txt-center font20"
  }, [_c('b', [_vm._v("Top 10 selling products")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "50px"
    }
  }, [_vm._v("No")]), _c('th', [_vm._v("Product Name")]), _c('th', {
    staticClass: "txt-right"
  }, [_vm._v("Total")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row border-bottom p12 txt-center font20"
  }, [_c('b', [_vm._v("Ticket")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "50px"
    }
  }, [_vm._v("No")]), _c('th', [_vm._v("Ticket Type")]), _c('th', {
    staticClass: "txt-right"
  }, [_vm._v("Total")])])]);

}]

export { render, staticRenderFns }