var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Stock Status ")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "split"
  }, [_vm._m(0), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.name,
      expression: "body.name"
    }],
    attrs: {
      "type": "text",
      "placeholder": "input name..."
    },
    domProps: {
      "value": _vm.body.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "split mt35"
  }, [_vm._m(1), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.body.sortOrder,
      expression: "body.sortOrder",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "number",
      "placeholder": "input order..."
    },
    domProps: {
      "value": _vm.body.sortOrder
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "sortOrder", _vm._n($event.target.value));
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]), _c('div', {
    staticClass: "split mt35",
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm._m(2), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.description,
      expression: "body.description"
    }],
    staticClass: "input outline-dark desc",
    attrs: {
      "type": "text",
      "placeholder": "Description..."
    },
    domProps: {
      "value": _vm.body.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "description", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-12 p6 mb12",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('button', {
    staticClass: "btn btn-light-secondary mr8",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Cancel ")]), !_vm.isCreating ? _c('button', {
    staticClass: "btn btn-info",
    on: {
      "click": _vm.subminCreateStockStatus
    }
  }, [_vm._v(" Create ")]) : _c('button', {
    staticClass: "btn btn-info"
  }, [_vm._v("Loading...")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Name :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Sort order :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Description :")])]);

}]

export { render, staticRenderFns }