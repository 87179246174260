var render = function render(){
  var _vm$body, _vm$body$detail, _vm$body$detail$autho, _vm$body2, _vm$body3, _vm$body3$detail, _vm$body3$detail$auth, _vm$body4, _vm$body4$detail, _vm$body4$detail$auth, _vm$body5, _vm$body5$detail, _vm$body5$detail$auth, _vm$body6, _vm$body6$detail, _vm$body6$detail$auth, _vm$body7, _vm$body7$detail, _vm$body7$detail$refe, _vm$body8, _vm$body8$detail;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row p6 radius6 animate-bottom wrap"
  }, [_vm.display.imagePreview ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal"
  }, [_c('img', {
    attrs: {
      "src": _vm.getFullImage((_vm$body = _vm.body) === null || _vm$body === void 0 ? void 0 : (_vm$body$detail = _vm$body.detail) === null || _vm$body$detail === void 0 ? void 0 : (_vm$body$detail$autho = _vm$body$detail.author) === null || _vm$body$detail$autho === void 0 ? void 0 : _vm$body$detail$autho.imageUrl),
      "height": "100%"
    }
  })])]) : _vm._e(), _c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Review ")]), Object.keys((_vm$body2 = _vm.body) === null || _vm$body2 === void 0 ? void 0 : _vm$body2.detail).length > 0 ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "split"
  }, [_vm._m(0), _c('div', {
    staticClass: "input-container readonly"
  }, [_vm._v(" " + _vm._s((_vm$body3 = _vm.body) === null || _vm$body3 === void 0 ? void 0 : (_vm$body3$detail = _vm$body3.detail) === null || _vm$body3$detail === void 0 ? void 0 : (_vm$body3$detail$auth = _vm$body3$detail.author) === null || _vm$body3$detail$auth === void 0 ? void 0 : _vm$body3$detail$auth.firstName) + " " + _vm._s((_vm$body4 = _vm.body) === null || _vm$body4 === void 0 ? void 0 : (_vm$body4$detail = _vm$body4.detail) === null || _vm$body4$detail === void 0 ? void 0 : (_vm$body4$detail$auth = _vm$body4$detail.author) === null || _vm$body4$detail$auth === void 0 ? void 0 : _vm$body4$detail$auth.lastName) + " ")])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(1), _c('div', {
    staticClass: "media-container pointer",
    staticStyle: {
      "position": "relative"
    },
    on: {
      "click": _vm.imagePreview
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "border-radius": "4px",
      "border": "1px solid #ccc",
      "padding": "3px",
      "display": "inline-block"
    }
  }, [_c('div', {
    staticClass: "go-left",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.getFullImage((_vm$body5 = _vm.body) === null || _vm$body5 === void 0 ? void 0 : (_vm$body5$detail = _vm$body5.detail) === null || _vm$body5$detail === void 0 ? void 0 : (_vm$body5$detail$auth = _vm$body5$detail.author) === null || _vm$body5$detail$auth === void 0 ? void 0 : _vm$body5$detail$auth.imageUrl),
      "height": "35px"
    }
  }), _c('span', {
    staticClass: "font12 p-file-name mr6"
  }, [_vm._v(_vm._s(_vm.getNameFromUrl((_vm$body6 = _vm.body) === null || _vm$body6 === void 0 ? void 0 : (_vm$body6$detail = _vm$body6.detail) === null || _vm$body6$detail === void 0 ? void 0 : (_vm$body6$detail$auth = _vm$body6$detail.author) === null || _vm$body6$detail$auth === void 0 ? void 0 : _vm$body6$detail$auth.imageUrl) || "unknown"))])])])])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(2), _c('div', {
    staticClass: "input-container readonly"
  }, [_vm._v(" " + _vm._s((_vm$body7 = _vm.body) === null || _vm$body7 === void 0 ? void 0 : (_vm$body7$detail = _vm$body7.detail) === null || _vm$body7$detail === void 0 ? void 0 : (_vm$body7$detail$refe = _vm$body7$detail.reference) === null || _vm$body7$detail$refe === void 0 ? void 0 : _vm$body7$detail$refe.name) + " ")])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(3), _c('star-rating', {
    attrs: {
      "inline": true,
      "star-size": 30,
      "show-rating": false,
      "rating": (_vm$body8 = _vm.body) === null || _vm$body8 === void 0 ? void 0 : (_vm$body8$detail = _vm$body8.detail) === null || _vm$body8$detail === void 0 ? void 0 : _vm$body8$detail.rating
    },
    model: {
      value: _vm.body.detail.rating,
      callback: function ($$v) {
        _vm.$set(_vm.body.detail, "rating", $$v);
      },
      expression: "body.detail.rating"
    }
  })], 1), _c('div', {
    staticClass: "split",
    staticStyle: {
      "align-items": "normal"
    }
  }, [_vm._m(4), _c('div', {
    staticClass: "input-container"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.detail.text,
      expression: "body.detail.text"
    }],
    staticClass: "desc",
    attrs: {
      "type": "text",
      "placeholder": "Input title..."
    },
    domProps: {
      "value": _vm.body.detail.text
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body.detail, "text", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "split",
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm._m(5), _c('div', {
    staticClass: "select"
  }, [_c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.detail.status,
      expression: "body.detail.status"
    }],
    attrs: {
      "type": "radio",
      "id": "active",
      "value": "active"
    },
    domProps: {
      "checked": _vm._q(_vm.body.detail.status, "active")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body.detail, "status", "active");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "active"
    }
  }, [_vm._v("Active")])]), _c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.detail.status,
      expression: "body.detail.status"
    }],
    attrs: {
      "type": "radio",
      "id": "inactive",
      "value": "inactive"
    },
    domProps: {
      "checked": _vm._q(_vm.body.detail.status, "inactive")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body.detail, "status", "inactive");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "inactive"
    }
  }, [_vm._v("Inactive")])])])])]) : _vm._e(), _c('div', {
    staticClass: "col-12 p6 mb12",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('button', {
    staticClass: "btn btn-light-secondary mr8",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Cancel ")]), !_vm.isUpdating ? _c('button', {
    staticClass: "btn btn-info",
    on: {
      "click": _vm.confirmUpdate
    }
  }, [_vm._v("Update")]) : _c('button', {
    staticClass: "btn btn-info"
  }, [_vm._v("Loading...")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Reviewer :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Image :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Product/Showroom :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Rating :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Review :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Status :")])]);

}]

export { render, staticRenderFns }