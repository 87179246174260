var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.map.isOpenMap ? _c('div', [_vm.map.isLoading ? _c('div', {
    staticClass: "overlay"
  }) : _vm._e(), _c('div', {
    staticClass: "input-container"
  }, [_c('gmap-autocomplete', {
    on: {
      "place_changed": _vm.initMarker
    }
  })], 1), _c('label', {
    staticStyle: {
      "margin": "4px"
    }
  }, [_c('button', {
    on: {
      "click": _vm.addLocationMarker
    }
  }, [_vm._v("Add")]), _c('button', {
    staticStyle: {
      "margin-left": "4px"
    },
    on: {
      "click": _vm.getCurrentLocation
    }
  }, [_vm._v("Location")]), _c('button', {
    staticStyle: {
      "float": "right",
      "margin-right": "4px"
    },
    on: {
      "click": function ($event) {
        return _vm.getStreetAddressFrom(true);
      }
    }
  }, [_vm._v("Done")])]), _c('gmap-map', {
    staticStyle: {
      "width": "100%",
      "height": "400px",
      "margin-top": "4px"
    },
    attrs: {
      "zoom": 14,
      "center": _vm.map.center
    }
  }, [_c('gmap-marker', {
    attrs: {
      "position": _vm.map.center,
      "draggable": true
    },
    on: {
      "drag": _vm.updateCoordinates
    }
  })], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }