import Service from "../../../../utils/api/service";
import { mapState } from "vuex";

export default {
    name: "UpdateAdminAccount",
    
    data() {
        return {
            isCreating: false,
            show: false,
            display: {
                modal: {
                    formError: false,
                    media: false,
                },
                message: {
                    responseError: "",
                },
            },
            body: {
                accountId: "",
                email: "",
                roleId: "",
                firstName: "",
                lastName: "",
                imageUrl: "",
                password: "",
                status: "",
            },
            data: {
                roleList: [],
            },
            limit: 10,
            isDetail: false,
            isError: false,
            isErrorMsg: "",
            statusArr: [
                {
                    id: "active",
                    name: "Active",
                },
                {
                    id: "inactive",
                    name: "Inactive",
                },
            ],
        };
    },

    components: {},

    created() {
        this.getDetail();
    },

    computed: {
        ...mapState(["permissions"]),
    },

    methods: {
        checkoutPermission(permissionName) {
            if (this.permissions) {
                let result = false;
                this.permissions.find((item) => {
                    item.permissions.find((permission) => {
                        if (permission.name == permissionName) {
                            result = true;
                        }
                    });
                });

                return result;
            }
        },

        getDetail() {
            this.isDetail = true;
            const id = this.$route.params.id;

            Service.getAccountUpdateDetail(id).then((response) => {
                this.isDetail = false;

                if (response?.statusCode == "4410") {
                    Service.refreshToken().then((response) => {
                        if (response == "ok") {
                            this.getDetail();
                        }
                    });
                }

                if (!response?.statusCode) {
                    this.getAllRole(this.limit);
                    this.body = {
                        email: response?.data?.email,
                        firstName: response?.data?.firstName,
                        lastName: response?.data?.lastName,
                        roleId: response?.data?.roleId,
                        accountId: this.$route.params.id || null,
                        status: response?.data?.status,
                    };
                }

                if (response?.statusCode) {
                    this.isError = true;
                    this.isErrorMsg = response?.message;
                }
            });
        },

        preventNav(event) {
            if (
                !this.body.email ||
                !this.body.firstName ||
                !this.body.lastName ||
                !this.body.roleId ||
                !this.body.password
            )
                return;
            event.preventDefault();
            event.returnValue = "";
        },

        goBack() {
            this.$router.push({
                name: "ListAdminAccount",
            });
        },

        closeModal() {
            this.display.modal.formError = false;
            this.display.modal.media = false;
        },

        getAllRole(limit) {
            let param = "?status=active&limit=" + limit;
            Service.getAllRole(param).then((response) => {
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.getAllRole(limit);
                            }
                        });
                    } else {
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    this.data.roleList = response.data;
                }
            });
        },

        submitCreateAccountAdmin() {
            let validatedMessage = this.validateBody(this.body);
            if (validatedMessage == "ok") {
                this.isCreating = true;
                this.createAccountAdmin();
            } else {
                this.$toasted.show(validatedMessage);
            }
        },

        validateBody(data) {
            if (!data.firstName) {
                return "Firstname cannot be empty!";
            } else if (!data.lastName) {
                return "Lastname cannot be empty!";
            } else if (!data.email) {
                return "Email cannot be empty!";
            } else if (!data.roleId) {
                return "Role cannot be empty!";
            } else {
                return "ok";
            }
        },

        createAccountAdmin() {
            let body = {
                email: this.body.email,
                firstName: this.body.firstName,
                lastName: this.body.lastName,
                password: this.body.password,
                roleId: this.body.roleId,
                accountId: Number(this.$route.params.id),
                status: this.body.status,
            };

            !body?.email && delete body.email;
            !body?.firstName && delete body.firstName;
            !body?.lastName && delete body.lastName;
            !body?.password && delete body.password;
            !body?.roleId && delete body.roleId;

            Service.updateAdminAccountInfo(body).then((response) => {
                this.isCreating = false;
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.createAccountAdmin();
                            }
                        });
                    } else {
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    this.resetBody();
                    this.$toasted.show("Account admin has been updated.");
                    this.goBack();
                }
            });
        },

        resetBody() {
            this.body = {
                email: "",
                firstName: "",
                lastName: "",
                roleId: "",
                password: "",
                status: "",
            };
        },
    },
};
