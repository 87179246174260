var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mt10 mb20"
  }, [_c('div', {
    staticClass: "row radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row p12 bg-white border-top-light-blue"
  }, [_vm._m(0), _c('div', {
    staticClass: "p12",
    class: _vm.renderClass()
  }, [_c('table', {
    staticClass: "table wh800",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "txt-center",
    staticStyle: {
      "width": "40px"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "txt-center",
    staticStyle: {
      "width": "60px"
    }
  }, [_vm._v("Image")]), _c('th', {
    staticStyle: {
      "width": "120px"
    }
  }, [_vm._v("SKU")]), _c('th', [_vm._v("Name")]), _c('th', {
    staticClass: "txt-right",
    attrs: {
      "width": "100px"
    }
  }, [_vm._v("Quantity")]), _c('th', {
    staticClass: "txt-right",
    attrs: {
      "width": "100px"
    }
  }, [_vm._v("Discount")]), _c('th', {
    staticClass: "txt-right",
    attrs: {
      "width": "100px"
    }
  }, [_vm._v("Price")]), _c('th', {
    staticClass: "txt-right",
    attrs: {
      "width": "100px"
    }
  }, [_vm._v("Points")]), _vm.checkoutPermission('UPDATE_VARIANT') ? _c('th', {
    staticClass: "txt-right",
    attrs: {
      "width": "100px"
    }
  }, [_vm._v("Status")]) : _vm._e(), _vm.checkoutPermission('UPDATE_VARIANT') ? _c('th', {
    staticStyle: {
      "width": "100px",
      "text-align": "center"
    }
  }, [_vm._v(" Action ")]) : _vm._e()])]), !_vm.isFetching && _vm.checkoutPermission('LIST_VARIANT') ? _c('tbody', _vm._l(_vm.data.variantList, function (variantValue, index) {
    return _c('tr', {
      key: (variantValue, index),
      staticClass: "hover-list"
    }, [_c('td', {
      staticClass: "txt-center"
    }, [_vm._v(_vm._s(variantValue === null || variantValue === void 0 ? void 0 : variantValue.id))]), _c('td', {
      staticClass: "cover"
    }, [variantValue.imageUrl ? _c('img', {
      staticStyle: {
        "height": "40px"
      },
      attrs: {
        "src": _vm.getFullImage(variantValue.imageUrl)
      }
    }) : _c('img', {
      staticStyle: {
        "height": "40px"
      },
      attrs: {
        "src": require('@/assets/images/noImage.jpg'),
        "alt": "No Image"
      }
    })]), _c('td', [_vm._v(_vm._s(variantValue.sku))]), _c('td', [_vm._v(_vm._s(variantValue.name))]), _c('td', {
      staticClass: "txt-right"
    }, [_vm._v(_vm._s(variantValue.quantity))]), _c('td', {
      staticClass: "txt-right"
    }, [_vm._v(_vm._s(_vm.formatPrice(variantValue.prices.discountAmount)))]), _c('td', {
      staticClass: "txt-right"
    }, [_vm._v(_vm._s(_vm.formatPrice(variantValue.prices.priceBeforeDiscount)))]), _c('td', {
      staticClass: "txt-right"
    }, [_vm._v(_vm._s(_vm.formatNumber(variantValue.points)))]), _vm.checkoutPermission('UPDATE_VARIANT') ? _c('td', {
      staticClass: "txt-right"
    }, [_vm.checkoutPermission('UPDATE_VARIANT') ? _c('label', {
      staticClass: "switch"
    }, [_c('input', {
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": variantValue.status === 'active' && 'checked'
      },
      on: {
        "click": function ($event) {
          return _vm.updateVariant(variantValue.id, variantValue.status);
        }
      }
    }), _c('span', {
      staticClass: "slider round"
    })]) : _vm._e()]) : _vm._e(), _c('td', {
      staticStyle: {
        "text-align": "center"
      }
    }, [_vm.checkoutPermission('UPDATE_VARIANT') ? _c('router-link', {
      staticClass: "btn btn-info btn-xs",
      attrs: {
        "to": {
          name: 'EditVaraint',
          query: {
            id: variantValue.id
          }
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-pen"
    })]) : _vm._e()], 1)]);
  }), 0) : _vm._e()]), _vm.isNoData ? _c('NoItem') : _vm._e(), _c('div', {
    staticClass: "total ph12"
  }, [_c('div', {
    staticClass: "ul-xl-li go-left"
  }, [_vm._v(" Total : " + _vm._s(_vm.data.pagination.total) + " ")]), _vm.data.pagination.totalPage > 0 ? _c('Pagination', {
    attrs: {
      "pagination": _vm.data.pagination.totalPage
    }
  }) : _vm._e()], 1)], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    staticClass: "navbar r-navbar"
  }, [_c('div', {
    staticClass: "ul-xl"
  }, [_c('div', {
    staticClass: "ul-xl-li"
  }, [_vm._v(" Variantation ")])])]);

}]

export { render, staticRenderFns }