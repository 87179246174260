import ProductGeneral from "./../../../../components/catalog/product/edit/general";
import Service from "./../../../../utils/api/service";
import ListVaraintOption from "../../../../components/catalog/product/edit/variant";

export default {
    name: "EditProduct",
    data() {
        return {
            data: {
                productDetail: "",
                categories: [],
                productGeneral: "",
            },
            isLoading: false,
            display: {
                tab: "general",
            },
            // define the default value
            value: null,
            options: [],
        };
    },
    components: {
        ProductGeneral,
        ListVaraintOption,
    },
    created() {
        this.getProductDetail(this.$route.query.id);
    },
    methods: {
        async getProductDetail(id) {
            this.isLoading = true;
            await Service.getProductDetail(id).then((response) => {
                this.isLoading = false;
                if (response.statusCode) {
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.getProductDetail(id);
                            }
                        });
                    } else {
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    this.data.productDetail = response.data;
                }
            });
        },

        changeTab(type) {
            this.display.tab = type;
        },

        createProductGeneralListener(productGeneral) {
            this.data.productGeneral = productGeneral;
        },
    },
};
