var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_vm.productDetail.id ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 pr12"
  }, [_c('div', {
    staticClass: "row animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white pb-20 radius6"
  }, [_c('div', {
    staticClass: "col-6 pr6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" SKU: ")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text",
      "placeholder": "Name"
    },
    domProps: {
      "value": _vm.productDetail.sku
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Brand: ")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text",
      "placeholder": "Brand"
    },
    domProps: {
      "value": _vm.productDetail.brand.name
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Price: ")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.formatPrice(_vm.productDetail.prices.priceAfterDiscount)
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Quantity: ")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.productDetail.quantity
    }
  })])]), _c('div', {
    staticClass: "row",
    attrs: {
      "hidden": ""
    }
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Currency: ")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.currency,
      expression: "body.currency"
    }],
    staticClass: "input outline-dark",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "currency", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.data.currencies, function (currency, index) {
    return _c('option', {
      key: (currency, index),
      domProps: {
        "value": currency.code
      }
    }, [_vm._v(" " + _vm._s(currency.name) + " ")]);
  }), 0)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Image:")]), _c('div', {
    staticClass: "media-container",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "border-radius": "4px",
      "border": "1px solid #ccc",
      "padding": "3px",
      "display": "inline-block"
    }
  }, [_vm.body.imageUrl ? _c('div', {
    staticClass: "go-left pointer display-flex",
    staticStyle: {
      "width": "175px"
    }
  }, [_c('image-detail', {
    attrs: {
      "heightChange": '35px',
      "widthChange": '35px',
      "classChange": "mr6",
      "thumbnail": _vm.getFullImage(_vm.body.imageUrl)
    }
  }), _c('div', {
    staticClass: "remove-image"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/cancel1.svg'),
      "alt": "",
      "width": "10px"
    },
    on: {
      "click": function ($event) {
        _vm.body.imageUrl = '';
      }
    }
  })]), _c('div', {
    staticClass: "p-file-name"
  }, [_c('span', {
    staticClass: "font12 p-file-name mr6",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(" " + _vm._s(_vm.getNameFromUrl(_vm.body.imageUrl)) + " ")])])], 1) : _c('div', {
    staticClass: "go-left"
  }, [_c('img', {
    staticClass: "attachment mh6",
    attrs: {
      "src": require('@/assets/icons/Vectorpaperclip.png'),
      "width": "8px"
    }
  }), _c('span', {
    staticClass: "font12",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(" No file selected ")])]), _c('label', {
    staticClass: "btn btn-secondary btn-sm go-right mt2 mr2",
    attrs: {
      "id": "image"
    },
    on: {
      "click": function ($event) {
        return _vm.chooseImage('single');
      }
    }
  }, [_vm._v(" Choose file ")])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Additional Image: ")]), _c('div', {
    staticClass: "media-container",
    staticStyle: {
      "position": "relative",
      "width": "fit-content"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "border-radius": "4px",
      "border": "1px solid #ccc",
      "padding": "3px",
      "display": "inline-block"
    }
  }, [_vm.data.mediaList && Object.keys(_vm.data.mediaList).length != 0 ? _c('div', {
    staticClass: "go-left pointer display-flex"
  }, _vm._l(_vm.data.mediaList, function (media, index) {
    return _c('div', {
      key: (media, index)
    }, [_c('image-detail', {
      attrs: {
        "heightChange": '35px',
        "widthChange": '35px',
        "classChange": "mr6",
        "thumbnail": _vm.getFullImage(media.url)
      }
    })], 1);
  }), 0) : _c('div', {
    staticClass: "go-left"
  }, [_c('img', {
    staticClass: "attachment mh6",
    attrs: {
      "src": require('@/assets/icons/Vectorpaperclip.png'),
      "width": "8px"
    }
  }), _c('span', {
    staticClass: "font12",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(" No file selected ")])]), _c('label', {
    staticClass: "btn btn-secondary btn-sm go-right mt2 mr2",
    attrs: {
      "id": "image"
    },
    on: {
      "click": function ($event) {
        return _vm.chooseImage('multitple');
      }
    }
  }, [_vm._v(" Choose file ")])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Product Points: ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.body.points,
      expression: "body.points",
      modifiers: {
        "number": true
      }
    }],
    staticClass: "input outline-dark",
    attrs: {
      "type": "number",
      "placeholder": "input points..."
    },
    domProps: {
      "value": _vm.body.points
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "points", _vm._n($event.target.value));
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])])]), _c('div', {
    staticClass: "col-6 pl6"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Name: ")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.productDetail.name
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Category: ")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.productDetail.category ? _vm.productDetail.category.name : ''
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" DiscountPrice: ")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.formatPrice(_vm.productDetail.prices.discountAmount)
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Short Description: ")]), _c('input', {
    staticClass: "input outline-dark disable-input",
    attrs: {
      "disabled": "",
      "type": "text",
      "placeholder": "Short description..."
    },
    domProps: {
      "value": _vm.body.shortDescription
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Related Product:")]), _c('multiselect', {
    attrs: {
      "placeholder": "Search Product",
      "label": "name",
      "track-by": "id",
      "options": _vm.data.productList,
      "multiple": true,
      "taggable": true,
      "open-direction": "bottom",
      "loading": _vm.loading.isSearching,
      "show-no-results": false,
      "internal-search": false,
      "clear-on-select": false,
      "close-on-select": false,
      "options-limit": 100
    },
    on: {
      "search-change": _vm.debouncedOnSearchProducts
    },
    model: {
      value: _vm.data.relatedProduct,
      callback: function ($$v) {
        _vm.$set(_vm.data, "relatedProduct", _vm._n($$v));
      },
      expression: "data.relatedProduct"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Shipping: ")]), _c('div', {
    staticClass: "select"
  }, [_c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.shipping,
      expression: "body.shipping"
    }],
    attrs: {
      "type": "radio",
      "id": "true"
    },
    domProps: {
      "value": true,
      "checked": _vm._q(_vm.body.shipping, true)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body, "shipping", true);
      }
    }
  }), _c('label', {
    attrs: {
      "for": "true"
    }
  }, [_vm._v("True")])]), _c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.shipping,
      expression: "body.shipping"
    }],
    attrs: {
      "type": "radio",
      "id": "false"
    },
    domProps: {
      "value": false,
      "checked": _vm._q(_vm.body.shipping, false)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body, "shipping", false);
      }
    }
  }), _c('label', {
    attrs: {
      "for": "false"
    }
  }, [_vm._v("False")])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v(" Status: ")]), _c('div', {
    staticClass: "select"
  }, [_c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.status,
      expression: "body.status"
    }],
    attrs: {
      "type": "radio",
      "id": "active",
      "value": "active"
    },
    domProps: {
      "checked": _vm._q(_vm.body.status, "active")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body, "status", "active");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "active"
    }
  }, [_vm._v("Active")])]), _c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.status,
      expression: "body.status"
    }],
    attrs: {
      "type": "radio",
      "id": "inactive",
      "value": "inactive"
    },
    domProps: {
      "checked": _vm._q(_vm.body.status, "inactive")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body, "status", "inactive");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "inactive"
    }
  }, [_vm._v("Inactive")])])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Description:")]), _c('vue-editor', {
    staticStyle: {
      "height": "250px"
    },
    attrs: {
      "id": "editor",
      "editor-toolbar": _vm.customToolbar,
      "useCustomImageHandler": ""
    },
    on: {
      "imageAdded": _vm.handleImageAdded
    },
    model: {
      value: _vm.body.description,
      callback: function ($$v) {
        _vm.$set(_vm.body, "description", $$v);
      },
      expression: "body.description"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p6"
  }, [_c('p', {
    staticClass: "mt0 mb6"
  }, [_vm._v("Specification:")]), _c('vue-editor', {
    staticStyle: {
      "height": "250px"
    },
    attrs: {
      "id": "editor1",
      "editor-toolbar": _vm.customToolbarSpecification
    },
    model: {
      value: _vm.body.specification,
      callback: function ($$v) {
        _vm.$set(_vm.body, "specification", $$v);
      },
      expression: "body.specification"
    }
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_vm.loading.isCreating ? _c('button', {
    staticClass: "btn btn-md btn-info go-right"
  }, [_vm._v(" Processing... ")]) : _c('button', {
    staticClass: "btn btn-md btn-info go-right",
    on: {
      "click": _vm.submitEdit
    }
  }, [_vm._v(" Submit ")])]), _vm.display.imagePreview ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal"
  }, [_vm.body.image.file ? _c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.fileToPath(_vm.body.image.file)
    }
  }) : _c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.getFullImage(_vm.data.media.url)
    }
  })])]) : _vm._e(), _vm.display.media.single ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal-md"
  }, [_c('Media', {
    attrs: {
      "type": "single",
      "method": _vm.closeModal,
      "folder": "catelog"
    },
    on: {
      "existedMedia": _vm.existedMediaCallback
    }
  })], 1)]) : _vm.display.media.multiple ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal-md"
  }, [_c('Media', {
    attrs: {
      "type": "multiple",
      "method": _vm.closeModal,
      "folder": "catelog"
    },
    on: {
      "existedMultiMedia": _vm.existedMultiMediaCallback
    }
  })], 1)]) : _vm._e()])])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }