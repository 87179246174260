import Service from "../../../../utils/api/service";
import { mapState } from "vuex";
import Helper from "../../../../utils/global/func";

export default {
  name: "DetailCustomer",
  data() {
    return {
      isLoading: false,
      undefinedAccount: false,
      data: {}
    };
  },
  created() {
    this.getCustomer()
  },
  computed: {
    ...mapState(["permissions"]),
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "ListCustomer",
        query: { page: 1, limit: 10 },
      });
    },

    checkoutPermission(permissionName) {
      if (this.permissions) {
        let result = false;
        this.permissions.find((item) => {
          item.permissions.find((permission) => {
            if (permission.name == permissionName) {
              result = true;
            }
          });
        });

        return result;
      }
    },

    getCustomer() {
      this.isLoading = true;
      let id = this.$root.$route.query?.id

      if (id) {
        Service.getCustomer(id).then((response) => {
          this.isLoading = false;
          if (response?.statusCode) {
            if (response?.statusCode === "4410") {
              Service.refreshToken().then((response) => {
                if (response === "ok") {
                  this.getCustomer();
                }
              });
            } else {
              this.$toasted.show(response?.message.capitalize());
              this.data = response
            }
          } else {
            this.isLoading = false
            this.undefinedAccount = false
            this.data = response?.data
          }
        });
      } else {
        this.isLoading = false
        this.undefinedAccount = true
      }
    },

    getFullImage(path) {
      return process.env.VUE_APP_BASE_URL_IMAGE + path;
    },
  }
}
