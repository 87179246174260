var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-container navbar-sticky"
  }, [_c('ul', {
    staticClass: "ul-navbar"
  }, [_c('li', [_c('p', {
    staticClass: "nav-item menu-sidebar",
    on: {
      "click": function ($event) {
        return _vm.popup('sidebar');
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-bars"
  })])]), _c('li', {
    staticClass: "go-right",
    on: {
      "click": function ($event) {
        _vm.display.modal.logout = true;
      }
    }
  }, [_c('p', {
    staticClass: "nav-item name-admin"
  }, [_vm._v("Logout")])])]), _vm.display.modal.sidebar ? _c('div', {
    staticClass: "overlay-slide fadeIn-overlay",
    on: {
      "click": _vm.closemodel
    }
  }) : _vm._e(), _c('div', {
    class: _vm.display.modal.sidebar ? 'sidebar-con animmation-left-right' : 'sidebar-con animmation-left-right-close'
  }, [_c('Menusidebar')], 1), _vm.display.modal.logout ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeLogout
    }
  }), _c('div', {
    staticClass: "modal-md"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "modal-footer border-top"
  }, [_c('button', {
    staticClass: "btn btn-light-danger btn-xs go-right",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Logout")]), _c('button', {
    staticClass: "btn btn-light-secondary btn-xs mr12 go-right",
    on: {
      "click": function ($event) {
        _vm.display.modal.logout = false;
      }
    }
  }, [_vm._v("Cancel")])])])]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-header border-bottom txt-info"
  }, [_c('span', {
    staticClass: "txt-sm",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_vm._v("Logout")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-body",
    staticStyle: {
      "overflow-y": "hidden",
      "padding": "16px"
    }
  }, [_c('span', [_vm._v("Are you sure you want to Logout ?")])]);

}]

export { render, staticRenderFns }