import Pagination from './../../../../components/share/pagination'
import Service from '../../../../utils/api/service'
import TableLoading from './../../../../components/share/table-loading'
import ListVaraintOption from './../../../../components/catalog/product/edit/variant'
import NoItem from './../../../../components/share/table-no-item'
import Helper from '../../../../utils/global/func'
import { mapState } from 'vuex'

export default {
  name: 'ListProduct',
  data() {
    return {
      isFetching: true,
      isDeleting: false,
      isNoData: false,
      display: {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
        },
      },
      data: {
        pagination: {
          limit: 10,
          page: 1,
          total: 0,
          totalPage: 0,
        },
        productList: [],
        brands: [],
        categories: [],
      },
      search: {
        search: '',
        sku: '',
        brandId: '',
        categoryId: '',
        variantSku: '',
        sort: '' // newest, oldest, lowest_price, highest_price, popular_search
      },
    }
  },
  components: {
    TableLoading,
    NoItem,
    Pagination,
    ListVaraintOption,
  },
  created() {
    this.setParams()
    this.getAllProducts()
    this.getBrands()
    this.getCategory()
  },
  mounted() { },
  watch: {
    '$route.fullPath': function () {
      this.setParams()
      this.getAllProducts()
    },
  },
  computed: {
    ...mapState(['permissions']),
  },
  methods: {
    setParams() {
      this.search = this.$helper.setParams(this.search, this.$root.$route.query)
    },

    checkoutPermission(permissionName) {
      if (this.permissions) {
        let result = false
        this.permissions.find((item) => {
          item.permissions.find((permission) => {
            if (permission.name == permissionName) {
              result = true
            }
          })
        })

        return result
      }
    },

    async getBrands() {
      let query = '?limit=100&offset=0'
      await Service.getAllBrands(query).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.getBrands()
              }
            })
          }
        } else {
          this.data.brands = response.data
        }
      })
    },

    async getCategory() {
      await Service.getAllCategories('').then((response) => {
        if (response.statusCode) {
          if (response.statusCode === '4410') {
            Service.refreshToken().then((response) => {
              if (response === 'ok') {
                this.getCategory()
              }
            })
          }
        } else {
          this.data.categories = response.data
        }
      })
    },

    getAllProducts() {
      this.isFetching = true
      this.isNoData = false
      let query = this.$root.$route.query;
      let top = ['top_selection_desc', 'top_selection_asc'].includes(query?.sort) && true
      let setQuery = this.$helper.filterKeyMapping({ topSelection: top, page: query?.page || 1, ...query })
      let params = this.$helper.queryParam(setQuery)

      Service.getAllProducts(params).then((response) => {
        this.isFetching = false
        if (response?.statusCode) {
          if (response?.statusCode === '4410') {
            Service.refreshToken().then((response) => {
              if (response === 'ok') {
                this.getAllProducts()
              }
            })
          } else if (response?.statusCode === '403') {
            this.isNoData = true
            this.data.productList = []
            this.$toasted.show(response?.message.capitalize())
          } else {
            this.$toasted.show(response?.message.capitalize())
          }
        } else {
          if (response?.data.length > 0) {
            this.isNoData = false
            this.data.productList = response?.data
            this.data.pagination = Helper.calculatePagination(response?.meta)
          } else {
            this.data.productList = []
            this.isNoData = true
            this.resetPagination()
          }
        }
      })
    },

    async searchProduct() {
      let query = this.$helper.filterKeyMapping({ limit: 10, page: 1, ...this.search })
      await this.$router.push({ query }).catch(() => { });
    },

    popupModalDelete(index) {
      this.display.modal.delete.index = index
      this.display.modal.delete.show = true
    },

    deleteProduct() {
      this.isDeleting = true
      let productId = this.data.productList[this.display.modal.delete.index].id
      Service.deleteProduct(productId).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.deleteProduct()
              }
            })
          } else {
            this.isDeleting = false
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          this.isDeleting = false
          this.data.productList.splice(this.display.modal.delete.index, 1)
          this.data.pagination.total -= 1
          this.$toasted.show('Product has been deleted.')
          this.closeModal()
        }
      })
    },

    getFullImage(path) {
      return process.env.VUE_APP_BASE_URL_IMAGE + path;
    },

    roundInt(int) {
      return +int.toFixed(2)
    },

    formatPrice(price) {
      return Helper.formatPrice(price)
    },

    closeModal() {
      this.display = {
        modal: {
          delete: {
            index: -1,
            show: false,
          },
        },
      }
    },

    updateTopSelection(productId, topSelection) {
      let top = topSelection ? 0 : 1;
      let body = {
        items: [
          { id: productId, topSelection: top }
        ]
      }
      Service.updateProductTopSelection(body).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.deleteProduct()
              }
            })
          } else {
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          let productUpdatedIndex = this.data.productList.findIndex((product => product?.id === productId));
          this.data.productList[productUpdatedIndex].topSelection = top;
        }
      })
    },

    updateProduct(productId, status) {
      let productStatus = status === 'active' ? 'inactive' : 'active';
      let body = { status: productStatus }
      Service.updateProduct(productId, body).then((response) => {
        if (response.statusCode) {
          if (response.statusCode == '4410') {
            Service.refreshToken().then((response) => {
              if (response == 'ok') {
                this.deleteProduct()
              }
            })
          } else {
            this.$toasted.show(response.message.capitalize())
          }
        } else {
          let productUpdatedIndex = this.data.productList.findIndex((product => product.id === productId));
          this.data.productList[productUpdatedIndex].status = productStatus;
          this.$toasted.show('Product has been updated to ' + productStatus.capitalize());
        }
      })
    },

    resetPagination() {
      this.data.pagination = {
        limit: 10,
        page: 1,
        total: 0,
        totalPage: 0,
      }
    },
  },
}
