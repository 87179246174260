import Service from "../../../utils/api/service";
import Pagination from "@/components/share/pagination";
import TableLoading from "./../../../components/share/table-loading";
import NoItem from "./../../../components/share/table-no-item";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { mapState } from "vuex";

export default {
  name: "ListLanguage",
  data() {
    return {
      isFetching: true,
      isDeleting: false,
      isUpdating: false,
      isNoData: false,
      fields: {
        id: "",
        index: "",
      },
      search: {
        dateRange: [],
      },
      data: [],
      activeCollapse: "",
      body: {
        key: "",
        kh: "",
        en: "",
        parentKey: "",
        title: "",
      },
    };
  },
  components: {
    Pagination,
    TableLoading,
    NoItem,
    DatePicker,
  },
  created() {
    this.setParams();
    this.getLocalize();
  },
  watch: {
    "$route.fullPath": function () {
      this.setParams();
      this.getLocalize();
    },
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {},
  methods: {
    setActiveCollapse: function (key) {
      if (this.activeCollapse === key) {
        this.activeCollapse = "";
      } else {
        this.activeCollapse = key;
      }
    },

    setParams() {
      this.search = this.$helper.setParams(
        this.search,
        this.$root.$route.query
      );
    },

    getLocalize: function () {
      this.isFetching = true;
      Service.getLanguage().then((response) => {
        if (response?.statusCode === "4410") {
          Service.refreshToken().then((response) => {
            if (response === "ok") {
              this.getLocalize();
            }
          });
        }
        this.isFetching = false;
        this.data = response?.data || [];
        return;
      });
    },

    updateLocalize: function () {
      let validatedMessage = this.validateBody(this.body);
      if (validatedMessage !== "ok") {
        this.$toasted.show(validatedMessage);
        return;
      }
      let body = {
        en: this.body?.en,
        kh: this.body?.kh,
        title: this.body?.title,
      };
      let key = this.body?.key;
      this.isUpdating = true;
      Service.updateLanguage(body, key).then((response) => {
        if (response?.statusCode === "4410") {
          Service.refreshToken().then((response) => {
            if (response === "ok") {
              this.getLocalize();
            }
          });
        }
        this.isUpdating = false;
        this.resetBody();
        this.getLocalize();
      });
    },

    setUpdateLocalize: function (key) {
      this.body = key;
    },

    validateBody(data) {
      if (!data.title) {
        return "Title cannot be empty!";
      } else if (!data.en) {
        return "English cannot be empty!";
      } else if (!data.kh) {
        return "Khmer cannot be empty!";
      } else {
        return "ok";
      }
    },

    resetBody: function () {
      this.body = {
        key: "",
        kh: "",
        en: "",
        parentKey: "",
        title: "",
      };
    },
  },
};
