var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Filter Customer Report ")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "split"
  }, [_vm._m(0), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.customerGroupId,
      expression: "body.customerGroupId"
    }],
    staticClass: "input-container",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "customerGroupId", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Customer Group")]), _vm._l(_vm.data.customerGroups, function (customerGroup, customerGroupIndex) {
    return _c('option', {
      key: customerGroup.id,
      domProps: {
        "value": customerGroup.id
      }
    }, [_vm._v(" " + _vm._s(customerGroup.name))]);
  })], 2)]), _c('div', {
    staticClass: "split"
  }, [_vm._m(1), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.status,
      expression: "body.status"
    }],
    staticClass: "input-container",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Customer Status")]), _c('option', {
    attrs: {
      "value": "active"
    }
  }, [_vm._v("Active")]), _c('option', {
    attrs: {
      "value": "inactive"
    }
  }, [_vm._v("Inactive")])])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(2), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.verified,
      expression: "body.verified"
    }],
    staticClass: "input-container",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "verified", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Verified")]), _c('option', {
    attrs: {
      "value": "true"
    }
  }, [_vm._v("True")]), _c('option', {
    attrs: {
      "value": "false"
    }
  }, [_vm._v("False")])])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(3), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.orderStatus,
      expression: "body.orderStatus"
    }],
    staticClass: "input-container",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "orderStatus", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Order Status")]), _c('option', {
    attrs: {
      "value": "active"
    }
  }, [_vm._v("Active")]), _c('option', {
    attrs: {
      "value": "inactive"
    }
  }, [_vm._v("Inactive")])])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(4), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('date-picker', {
    attrs: {
      "value-type": "format",
      "placeholder": "Start date",
      "format": "YYYY-MM-DD"
    },
    model: {
      value: _vm.body.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.body, "startDate", $$v);
      },
      expression: "body.startDate"
    }
  })], 1), _c('span', {
    staticClass: "col-1 txt-center mt5"
  }, [_vm._v("To")]), _c('div', {
    staticClass: "col-4"
  }, [_c('date-picker', {
    attrs: {
      "value-type": "format",
      "placeholder": "End date",
      "format": "YYYY-MM-DD"
    },
    model: {
      value: _vm.body.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.body, "endDate", $$v);
      },
      expression: "body.endDate"
    }
  })], 1)])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(5), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-4"
  }, [_c('date-picker', {
    attrs: {
      "value-type": "format",
      "placeholder": "Start date",
      "format": "YYYY-MM-DD"
    },
    model: {
      value: _vm.body.startOrderDate,
      callback: function ($$v) {
        _vm.$set(_vm.body, "startOrderDate", $$v);
      },
      expression: "body.startOrderDate"
    }
  })], 1), _c('span', {
    staticClass: "col-1 txt-center mt5"
  }, [_vm._v("To")]), _c('div', {
    staticClass: "col-4"
  }, [_c('date-picker', {
    attrs: {
      "value-type": "format",
      "placeholder": "End date",
      "format": "YYYY-MM-DD"
    },
    model: {
      value: _vm.body.endOrderDate,
      callback: function ($$v) {
        _vm.$set(_vm.body, "endOrderDate", $$v);
      },
      expression: "body.endOrderDate"
    }
  })], 1)])])]), _c('div', {
    staticClass: "col-12 pv6 mb12",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('div', [!_vm.isGenerating ? _c('button', {
    staticClass: "btn btn-theme-grey-light btn-xs btn-create",
    on: {
      "click": function ($event) {
        return _vm.generateReport('');
      }
    }
  }, [_c('span', [_vm._v("Generate")])]) : _c('button', {
    staticClass: "btn btn-info btn-xs btn-create"
  }, [_c('span', [_vm._v("Generating...")])])])])])]), _vm.isGenerated ? _c('div', {
    staticClass: "row bg-white border-top-light-blue mt26"
  }, [_c('input', {
    attrs: {
      "type": "file",
      "id": "file",
      "hidden": ""
    }
  }), _c('header', {
    staticClass: "navbar r-navbar"
  }, [_vm._m(6), _vm.table.headers.length > 0 && _vm.table.body.length > 0 ? _c('div', {
    staticClass: "con-search-btn",
    staticStyle: {
      "position": "unset"
    }
  }, [!_vm.isExporting ? _c('button', {
    staticClass: "btn btn-theme-grey-light btn-xs btn-create",
    on: {
      "click": function ($event) {
        return _vm.generateReport('export');
      }
    }
  }, [_vm._v(" Export File ")]) : _c('button', {
    staticClass: "btn btn-theme-grey-light btn-xs btn-create"
  }, [_c('span', [_vm._v("Exporting...")])])]) : _vm._e()]), _c('div', {
    staticClass: "table-control p12"
  }, [_c('table', {
    staticClass: "table wh800",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('thead', [_c('tr', _vm._l(_vm.table.headers, function (item) {
    return _c('th', {
      staticClass: "table-header"
    }, [_vm._v(_vm._s(item))]);
  }), 0)]), _c('tbody', _vm._l(_vm.table.body, function (item, index) {
    return _c('tr', {
      key: (item, index),
      staticClass: "hover-list"
    }, _vm._l(item, function (key, keyIndex) {
      return _c('td', [_vm._v(" " + _vm._s(keyIndex == 'Joined Date' || keyIndex == 'Updated Date' ? _vm.ExcelDateToJSDate(key) : key) + " ")]);
    }), 0);
  }), 0)])])]) : _vm._e(), _vm.table.body.length == 1 ? _c('div', {
    staticClass: "row"
  }, [_c('p', {
    staticClass: "txt-center",
    staticStyle: {
      "line-height": "490px"
    }
  }, [_vm._v("No Report")])]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Customer Group :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Customer Status :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Verified :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Order Status :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Customer Joined Date :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Ordered Date "), _c('span', {
    staticClass: "txt-dark-light"
  }, [_vm._v("(BP Active)")]), _vm._v(" :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ul-xl"
  }, [_c('div', {
    staticClass: "ul-xl-li"
  }, [_vm._v(" Reports ")])]);

}]

export { render, staticRenderFns }