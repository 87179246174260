var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "con-login"
  }, [_c('div', {
    staticClass: "box-form bg-white"
  }, [_c('div', {
    staticClass: "imgcontainer"
  }, [_c('img', {
    staticClass: "avatar",
    attrs: {
      "src": require('@/assets/images/logo.png')
    }
  })]), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row mb12"
  }, [_c('label', {
    attrs: {
      "for": "uname"
    }
  }, [_vm._v("Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.email,
      expression: "body.email"
    }],
    staticClass: "input",
    attrs: {
      "type": "text",
      "placeholder": "Enter email"
    },
    domProps: {
      "value": _vm.body.email
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "email", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "row"
  }, [_c('label', {
    attrs: {
      "for": "psw"
    }
  }, [_vm._v("Password")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [(_vm.showPassword ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.password,
      expression: "body.password"
    }],
    staticClass: "input-info outline-success",
    attrs: {
      "name": "password",
      "placeholder": "Enter Password",
      "required": "",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.body.password) ? _vm._i(_vm.body.password, null) > -1 : _vm.body.password
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      },
      "change": function ($event) {
        var $$a = _vm.body.password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.body, "password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.body, "password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.body, "password", $$c);
        }
      }
    }
  }) : (_vm.showPassword ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.password,
      expression: "body.password"
    }],
    staticClass: "input-info outline-success",
    attrs: {
      "name": "password",
      "placeholder": "Enter Password",
      "required": "",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.body.password, null)
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      },
      "change": function ($event) {
        return _vm.$set(_vm.body, "password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.password,
      expression: "body.password"
    }],
    staticClass: "input-info outline-success",
    attrs: {
      "name": "password",
      "placeholder": "Enter Password",
      "required": "",
      "type": _vm.showPassword ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.body.password
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "password", $event.target.value);
      }
    }
  }), _vm.showPassword ? _c('i', {
    staticClass: "fas fa-eye show-icon",
    on: {
      "click": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    }
  }) : _c('i', {
    staticClass: "fas fa-eye-slash show-icon",
    on: {
      "click": function ($event) {
        _vm.showPassword = !_vm.showPassword;
      }
    }
  })])]), !_vm.isLogin ? _c('p', {
    staticClass: "btn btn-primary mt12 mb30",
    on: {
      "click": _vm.login
    }
  }, [_vm._v("Login")]) : _c('p', {
    staticClass: "btn btn-primary mt12 mb30"
  }, [_vm._v("Loading...")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }