var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pagination"
  }, [_c('div', {
    staticClass: "btn-responsive",
    staticStyle: {
      "margin-left": "auto"
    }
  }, [_c('div', [_c('span', {
    staticClass: "h30 pt5"
  }, [_vm._v("Go to")]), _c('span', {
    staticClass: "mb0 text-14",
    staticStyle: {
      "margin-top": "-2px",
      "border": "1px solid #ccc"
    },
    attrs: {
      "title": "enter go to page"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inputPage,
      expression: "inputPage"
    }],
    staticClass: "h30",
    staticStyle: {
      "padding": "0px",
      "outline": "none",
      "width": "50px"
    },
    attrs: {
      "type": "number",
      "min": "1"
    },
    domProps: {
      "value": _vm.inputPage
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.goTo(Number(_vm.inputPage));
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.inputPage = $event.target.value;
      }
    }
  })])]), _c('div', [_c('span', {
    staticStyle: {
      "width": "30px",
      "text-align": "center",
      "background": "black",
      "padding": "5px 8px",
      "border-radius": "4px",
      "color": "white",
      "margin-right": "3px"
    },
    attrs: {
      "title": "Previous"
    },
    on: {
      "click": function ($event) {
        _vm.goTo(Number(_vm.page) - 1);
      }
    }
  }, [_vm._v(" « ")]), _c('span', {
    staticClass: "h30 mb0 pt6 text-14"
  }, [_vm._v(" Page " + _vm._s(_vm.page) + " of " + _vm._s(_vm.pagination) + " ")]), _c('span', {
    staticStyle: {
      "background": "black",
      "padding": "5px 12px",
      "border-radius": "4px",
      "color": "white",
      "margin-right": "3px"
    },
    attrs: {
      "title": "Next"
    },
    on: {
      "click": function ($event) {
        _vm.goTo(Number(_vm.page) + 1);
      }
    }
  }, [_vm._v(" » ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }