var render = function render(){
  var _vm$data, _vm$data$orderDetail, _vm$data$orderDetail$, _vm$data2, _vm$data2$orderDetail, _vm$data2$orderDetail2, _vm$data3, _vm$data3$orderDetail, _vm$data3$orderDetail2, _vm$data4, _vm$data4$orderDetail, _vm$data4$orderDetail2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row border-top-light-blue bg-white pt10 order-loading",
    staticStyle: {
      "overflow": "hidden"
    }
  }, [_c('div', {
    staticClass: "font18 pr24 pl24",
    staticStyle: {
      "width": "100%",
      "font-weight": "600 !important",
      "margin-bottom": "-22px",
      "display": "inline-block"
    }
  }, [_c('i', {
    staticClass: "fas fa-arrow-left pointer",
    staticStyle: {
      "margin-right": "18px",
      "cursor": "pointer"
    },
    attrs: {
      "title": "Back"
    },
    on: {
      "click": _vm.goBack
    }
  }), _c('span', [_vm._v("Order Detail")]), _c('button', {
    staticClass: "btn btn-info btn-sm go-right",
    on: {
      "click": _vm.printOrder
    }
  }, [_c('i', {
    staticClass: "fa fa-print mr3"
  }), _vm._v(" Export PDF")]), _c('hr')]), !_vm.isFetching ? _c('div', {
    attrs: {
      "id": "order-report"
    }
  }, [_c('div', {
    staticClass: "row pt24 pr24 pl24 pt10"
  }, [_c('div', {
    staticClass: "col-6 col-md-6 p4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(0), _c('li', [_vm._m(1), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": #" + _vm._s(_vm.data.orderDetail.invoiceNumber))])]), _c('li', [_vm._m(2), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.data.orderDetail.transactionNumber))])]), _c('li', [_vm._m(3), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.data.orderDetail.paymentMethod.name))])]), _c('li', [_vm._m(4), _c('div', {
    staticClass: "col-7 text-capitalize"
  }, [_vm._v(": " + _vm._s(_vm.data.orderDetail.shippingMethod))])]), _c('li', [_vm._m(5), _c('div', {
    staticClass: "col-7 text-capitalize"
  }, [_vm._v(": " + _vm._s(_vm.data.orderDetail.addressDetail))])]), _c('li', [_vm._m(6), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": $" + _vm._s(_vm.data.orderDetail.paymentDetail.shippingRate.toFixed(2)))])]), _c('li', [_vm._m(7), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.data.orderDetail.createdAt && _vm.$helper.formatDate(_vm.data.orderDetail.createdAt)) + " ")])]), _c('li', [_vm._m(8), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.data.orderDetail.orderStatus.name))])]), _c('li', [_vm._m(9), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.data.orderDetail.rewardPoints))])]), _c('li', [_vm._m(10), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.data.orderDetail.remark || "N/A"))])])])])]), _c('div', {
    staticClass: "col-6 col-md-6"
  }, [_c('div', {
    staticClass: "row ph4 pt4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(11), _c('li', [_vm._m(12), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s([((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$orderDetail = _vm$data.orderDetail) === null || _vm$data$orderDetail === void 0 ? void 0 : (_vm$data$orderDetail$ = _vm$data$orderDetail.account) === null || _vm$data$orderDetail$ === void 0 ? void 0 : _vm$data$orderDetail$.firstName) || 'N/A', ((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$orderDetail = _vm$data2.orderDetail) === null || _vm$data2$orderDetail === void 0 ? void 0 : (_vm$data2$orderDetail2 = _vm$data2$orderDetail.account) === null || _vm$data2$orderDetail2 === void 0 ? void 0 : _vm$data2$orderDetail2.lastName) || 'N/A'].join(' ')))])]), _c('li', [_vm._m(13), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : (_vm$data3$orderDetail = _vm$data3.orderDetail) === null || _vm$data3$orderDetail === void 0 ? void 0 : (_vm$data3$orderDetail2 = _vm$data3$orderDetail.account) === null || _vm$data3$orderDetail2 === void 0 ? void 0 : _vm$data3$orderDetail2.contactEmail) || 'N/A'))])]), _c('li', [_vm._m(14), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(_vm.$helper.formatPhoneNumber((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : (_vm$data4$orderDetail = _vm$data4.orderDetail) === null || _vm$data4$orderDetail === void 0 ? void 0 : (_vm$data4$orderDetail2 = _vm$data4$orderDetail.account) === null || _vm$data4$orderDetail2 === void 0 ? void 0 : _vm$data4$orderDetail2.contactPhone) || 'N/A') + " ")])])])]), _c('div', {
    staticClass: "row mt10"
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(15), _c('li', [_vm._m(16), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(" : "), _vm.data.orderDetail.paymentDetail.couponCode ? _c('div', {
    staticStyle: {
      "display": "initial"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.data.orderDetail.paymentDetail.couponCode) + " / ")]), _vm.data.orderDetail.paymentDetail.couponType == 'percentage' ? _c('span', [_vm._v(" " + _vm._s(_vm.data.orderDetail.paymentDetail.couponDiscount) + " % ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$helper.formatPrice(_vm.data.orderDetail.paymentDetail.couponDiscount)) + " ")])]) : _vm._e()])]), _c('li', [_vm._m(17), _c('div', {
    staticClass: "col-7"
  }, [_vm._v(": " + _vm._s(!_vm.data.orderDetail.paymentDetail.couponFreeShipping ? 'Charged' : 'Free'))])]), _c('li', [_vm._m(18), _c('div', {
    staticClass: "col-7 text-capitalize"
  }, [_vm._v(": "), _vm.data.orderDetail.paymentDetail.paymentStatus == 'success' ? _c('span', {
    staticClass: "txt-success"
  }, [_vm._v(_vm._s(_vm.data.orderDetail.paymentDetail.paymentStatus))]) : _vm.data.orderDetail.paymentDetail.paymentStatus == 'pending' ? _c('span', {
    staticClass: "txt-warning"
  }, [_vm._v(_vm._s(_vm.data.orderDetail.paymentDetail.paymentStatus))]) : _c('span', {
    staticClass: "txt-danger"
  }, [_vm._v(_vm._s(_vm.data.orderDetail.paymentDetail.paymentStatus))])])])])])])])]), _c('div', {
    staticClass: "row pr27 pl27 mt12 mb24"
  }, [_c('table', {
    staticClass: "table",
    attrs: {
      "cellspacing": "0"
    }
  }, [_vm._m(19), _c('tbody', [_vm._l(_vm.data.orderDetail.orderItems, function (orderItem, index) {
    return _c('tr', {
      key: (orderItem, index)
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(orderItem.name))]), _c('td', {
      staticClass: "tr-right"
    }, [_vm._v(_vm._s(orderItem.quantity))]), _c('td', {
      staticClass: "tr-right"
    }, [_vm._v("$" + _vm._s(orderItem.priceBeforeDiscount.toFixed(2)))]), _c('td', {
      staticClass: "tr-right"
    }, [_vm._v("$" + _vm._s(orderItem.totalAmount.toFixed(2)))])]);
  }), _c('tr', {
    staticClass: "tr"
  }, [_c('td', {
    staticClass: "tr-right",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("Sub-Total")]), _c('td', {
    staticClass: "tr-right"
  }, [_vm._v("$" + _vm._s(_vm.data.orderDetail.paymentDetail.subtotalAmount.toFixed(2)))])]), _vm.data.orderDetail.paymentDetail.discountAmount ? _c('tr', {
    staticClass: "tr"
  }, [_c('td', {
    staticClass: "tr-right",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("Discount")]), _c('td', {
    staticClass: "tr-right"
  }, [_vm._v("-$" + _vm._s(_vm.data.orderDetail.paymentDetail.discountAmount.toFixed(2)))])]) : _vm._e(), _c('tr', {
    staticClass: "tr"
  }, [_c('td', {
    staticClass: "tr-right",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("Shipping Charge")]), _c('td', {
    staticClass: "tr-right"
  }, [_vm._v("$" + _vm._s(_vm.data.orderDetail.paymentDetail.shippingRate.toFixed(2)))])]), _vm.data.orderDetail.paymentDetail.couponCode ? _c('tr', {
    staticClass: "tr"
  }, [_c('td', {
    staticClass: "tr-right",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("Coupon(" + _vm._s(_vm.data.orderDetail.paymentDetail.couponCode) + ")")]), _c('td', {
    staticClass: "tr-right"
  }, [_vm._v("-$" + _vm._s(_vm.data.orderDetail.paymentDetail.couponDiscountAmount.toFixed(2)))])]) : _vm._e(), _c('tr', {
    staticClass: "tr tr-color"
  }, [_c('td', {
    staticClass: "tr-right",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("Total")]), _c('td', {
    staticClass: "tr-right"
  }, [_vm._v("$" + _vm._s(_vm.data.orderDetail.paymentDetail.totalAmount.toFixed(2)))])])], 2)]), _c('div', {
    staticClass: "row mt12",
    attrs: {
      "hidden": ""
    }
  }, [_c('ul', {
    staticClass: "list-group"
  }, [_vm._m(20), _c('li', [_c('div', {
    staticClass: "split"
  }, [_c('label', {
    attrs: {
      "for": "remark"
    }
  }, [_vm._v("Remark")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.text,
      expression: "body.text"
    }],
    staticClass: "input outline-dark desc",
    attrs: {
      "type": "text",
      "id": "remark",
      "placeholder": "Write the remark..."
    },
    domProps: {
      "value": _vm.body.text
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "text", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "split",
    attrs: {
      "hidden": ""
    }
  }, [_vm._m(21), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.orderStatusId,
      expression: "body.orderStatusId"
    }],
    staticClass: "input input-xs mr6 filter-type",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "orderStatusId", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.data.orderStatus, function (orderStatus, index) {
    return _c('option', {
      key: (orderStatus, index),
      domProps: {
        "value": orderStatus.id
      }
    }, [_vm._v(" " + _vm._s(orderStatus.name))]);
  }), 0)]), _c('div', {
    staticClass: "mt12",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('button', {
    staticClass: "btn btn-light-secondary mr8",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Cancel ")]), !_vm.isUpdating ? _c('button', {
    staticClass: "btn btn-info",
    on: {
      "click": _vm.submitUpdateOrder
    }
  }, [_vm._v(" Save ")]) : _c('button', {
    staticClass: "btn btn-info"
  }, [_vm._v("Loading...")])])])])])])]) : _vm._e(), _vm.isFetching ? _c('TableLoading') : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fa fa-shopping-cart"
  }), _vm._v(" Order Detail")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5 text-capitalize"
  }, [_c('b', [_vm._v("Invoice No")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Transaction Number")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Payment Method")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Shipping Method")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Shipping Address")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Shipping Fee")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Ordered Date")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Ordered Status")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Reward Points")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Remark")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fas fa-user"
  }), _vm._v(" Customer")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Name")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Email")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Phone")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fas fa-money-check-alt"
  }), _vm._v(" Payment Detail")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Coupon Code/Discount")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Coupon Free Shipping")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-5"
  }, [_c('b', [_vm._v("Payment Status")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Product")]), _c('th', {
    staticClass: "tr-right"
  }, [_vm._v("Quantity")]), _c('th', {
    staticClass: "tr-right"
  }, [_vm._v("Unit Price")]), _c('th', {
    staticClass: "tr-right"
  }, [_vm._v("Total")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('i', {
    staticClass: "fas fa-edit"
  }), _vm._v("Add Remark")]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31 font14"
  }, [_vm._v("Status :")])]);

}]

export { render, staticRenderFns }