var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_vm.display.imagePreview ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal"
  }, [_vm.body.image.file ? _c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.fileToPath(_vm.body.image.file)
    }
  }) : _c('img', {
    staticClass: "attachment mr6",
    attrs: {
      "src": _vm.getFullImage(_vm.data.media.url)
    }
  })])]) : _vm._e(), _vm.display.media ? _c('div', {
    staticClass: "overlay"
  }, [_c('div', {
    staticClass: "overlay",
    on: {
      "click": _vm.closeModal
    }
  }), _c('div', {
    staticClass: "modal-md"
  }, [_c('Media', {
    attrs: {
      "type": "single",
      "method": _vm.closeModal,
      "folder": "catelog"
    },
    on: {
      "existedMedia": _vm.existedMediaCallback,
      "newMedia": _vm.newMediaCallBack
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Brand ")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "split"
  }, [_vm._m(0), _c('div', {
    staticClass: "media-container",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100%",
      "border-radius": "4px",
      "border": "1px solid #ccc",
      "padding": "3px",
      "display": "inline-block"
    }
  }, [_vm.body.image.file ? _c('div', {
    staticClass: "go-left pointer display-flex"
  }, [_c('image-detail', {
    attrs: {
      "heightChange": '36px',
      "widthChange": '36px',
      "thumbnail": _vm.fileToPath(_vm.body.image.file)
    }
  }), _c('div', {
    staticClass: "p-file-name"
  }, [_c('span', {
    staticClass: "font12 p-file-name mr6",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(_vm._s(_vm.body.image.file.name))])]), _c('span', {
    staticClass: "font12",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v("( " + _vm._s(_vm.body.image.file.size / 1000) + " KB )")])], 1) : _vm.data.media && Object.keys(_vm.data.media).length != 0 ? _c('div', {
    staticClass: "go-left pointer display-flex",
    staticStyle: {
      "display": "flex"
    }
  }, [_c('image-detail', {
    attrs: {
      "heightChange": '36px',
      "widthChange": '36px',
      "thumbnail": _vm.data.media.url && _vm.getFullImage(_vm.data.media.url) || require('@/assets/images/noImage.jpg')
    }
  }), _c('div', {
    staticClass: "p-file-name"
  }, [_c('span', {
    staticClass: "font12 p-file-name mr6",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v(_vm._s(_vm.getNameFromUrl(_vm.data.media.url)))])])], 1) : _c('div', {
    staticClass: "go-left"
  }, [_c('img', {
    staticClass: "attachment mh6",
    attrs: {
      "src": require('@/assets/icons/Vectorpaperclip.png'),
      "width": "8px"
    }
  }), _c('span', {
    staticClass: "font12",
    staticStyle: {
      "line-height": "35px"
    }
  }, [_vm._v("No file selected")])]), _c('label', {
    staticClass: "btn btn-secondary btn-sm go-right mt2 mr2",
    attrs: {
      "id": "image"
    },
    on: {
      "click": _vm.chooseImage
    }
  }, [_vm._v(" Choose file ")])])])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(1), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.name,
      expression: "body.name"
    }],
    attrs: {
      "type": "text",
      "placeholder": "input name..."
    },
    domProps: {
      "value": _vm.body.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "split"
  }, [_vm._m(2), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.categoryId,
      expression: "body.categoryId"
    }],
    staticClass: "input-container",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.body, "categoryId", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.data.categoryList, function (category, categoryIndex) {
    return _c('optgroup', {
      key: (category, categoryIndex),
      attrs: {
        "label": category.name
      }
    }, _vm._l(category.children, function (subcategory, index) {
      return _c('option', {
        key: index,
        domProps: {
          "value": subcategory.id
        },
        model: {
          value: _vm.body.categoryId,
          callback: function ($$v) {
            _vm.$set(_vm.body, "categoryId", _vm._n($$v));
          },
          expression: "body.categoryId"
        }
      }, [_vm._v(_vm._s(subcategory.name) + " ")]);
    }), 0);
  }), 0)]), _c('div', {
    staticClass: "split"
  }, [_vm._m(3), _c('div', {
    staticClass: "input-container"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.body.sortOrder,
      expression: "body.sortOrder",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "type": "number",
      "placeholder": "input order..."
    },
    domProps: {
      "value": _vm.body.sortOrder
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.body, "sortOrder", _vm._n($event.target.value));
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]), _c('div', {
    staticClass: "split",
    staticStyle: {
      "margin-bottom": "0"
    }
  }, [_vm._m(4), _c('div', {
    staticClass: "select"
  }, [_c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.status,
      expression: "body.status"
    }],
    attrs: {
      "type": "radio",
      "id": "active",
      "value": "active"
    },
    domProps: {
      "checked": _vm._q(_vm.body.status, "active")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body, "status", "active");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "active"
    }
  }, [_vm._v("Active")])]), _c('div', {
    staticClass: "radio-item"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.body.status,
      expression: "body.status"
    }],
    attrs: {
      "type": "radio",
      "id": "inactive",
      "value": "inactive"
    },
    domProps: {
      "checked": _vm._q(_vm.body.status, "inactive")
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.body, "status", "inactive");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "inactive"
    }
  }, [_vm._v("Inactive")])])])])]), _c('div', {
    staticClass: "col-12 p6 mb12",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('button', {
    staticClass: "btn btn-light-secondary mr8",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Cancel ")]), !_vm.isCreating ? _c('button', {
    staticClass: "btn btn-info",
    on: {
      "click": _vm.submitCreateBrand
    }
  }, [_vm._v(" Create ")]) : _c('button', {
    staticClass: "btn btn-info"
  }, [_vm._v("Loading...")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Image :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Name :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Cateory :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Sort Order :")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Status :")])]);

}]

export { render, staticRenderFns }