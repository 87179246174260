var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('div', {
    staticClass: "container-wrap"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Top Selection Product ")]), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "split"
  }, [_vm._m(0), _c('div', {
    staticClass: "create-topselection"
  }, [_c('multiselect', {
    staticStyle: {
      "height": "36px"
    },
    attrs: {
      "placeholder": "Search Product",
      "label": "name",
      "track-by": "id",
      "options": _vm.data.productList,
      "multiple": true,
      "taggable": true,
      "open-direction": "bottom",
      "loading": _vm.isSearching,
      "show-no-results": false,
      "internal-search": false,
      "clear-on-select": false,
      "close-on-select": false,
      "options-limit": 100
    },
    on: {
      "search-change": _vm.debouncedOnSearchProducts
    },
    model: {
      value: _vm.body.product,
      callback: function ($$v) {
        _vm.$set(_vm.body, "product", _vm._n($$v));
      },
      expression: "body.product"
    }
  })], 1)])]), _c('div', {
    staticClass: "col-12 p6 mb12",
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end",
      "z-index": "0"
    }
  }, [_c('button', {
    staticClass: "btn btn-light-secondary mr8",
    on: {
      "click": _vm.goBack
    }
  }, [_vm._v(" Cancel ")]), !_vm.isCreating ? _c('button', {
    staticClass: "btn btn-info",
    on: {
      "click": _vm.subminCreateTopSelection
    }
  }, [_vm._v(" Create ")]) : _c('button', {
    staticClass: "btn btn-info"
  }, [_vm._v("Loading...")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "txt-title"
  }, [_c('span', {
    staticClass: "txt-danger",
    staticStyle: {
      "line-height": "31px"
    }
  }, [_vm._v("*")]), _c('span', {
    staticClass: "line-h31"
  }, [_vm._v("Product :")])]);

}]

export { render, staticRenderFns }