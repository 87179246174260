import Service from "../../../../../utils/api/service";
import Media from "./../../../../share/media";
import Helper from "../../../../../utils/global/func";
import Multiselect from "vue-multiselect";
import _debounce from "lodash.debounce";

export default {
    name: "product_general_edit",
    props: {
        productDetail: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            data: {
                currencies: [],
                subcategories: [],
                productList: [],
                options: [],
                optionValues: [],
                uom: [],
                mediaList: [],
                media: "",
                relatedProduct: [],
            },
            body: {
                image: {
                    file: "",
                    presign: "",
                    fileList: [],
                },
                specification: "",
                //currency: '',
                price: "",
                imageUrl: "",
                shipping: true,
                status: "active",
                mediaId: [],
                productRelatedIds: [],
                points: 0,
                shortDescription: "",
            },
            loading: {
                isCreating: false,
                isSearching: false,
            },
            display: {
                imagePreview: false,
                media: {
                    single: false,
                    multiple: false,
                },
                category: false,
                option: false,
            },
            customToolbar: [
                ["bold", "italic", "underline"],
                [
                    {
                        list: "ordered",
                    },
                    {
                        list: "bullet",
                    },
                ],
                ["image"],
            ],
            customToolbarSpecification: [
                ["bold", "italic", "underline"],
                [
                    {
                        list: "ordered",
                    },
                    {
                        list: "bullet",
                    },
                ],
                ["link"],
            ],
        };
    },
    computed: {
        debouncedOnSearchProducts() {
            return _debounce(this.searchProducts, 500);
        },
    },
    components: { Media, Multiselect },

    created() {
        this.searchProducts();
        this.mapData();
        this.getRelatedProduct();
        //this.getAllCurrency()
    },
    watch: {
        "$route.fullPath": function () {
            this.mapData();
        },
    },

    methods: {
        mapData() {
            this.body = {
                description: this.productDetail.description,
                specification: this.productDetail.specification,
                imageUrl: this.productDetail.imageUrl,
                shipping: this.productDetail.shipping,
                //currency: this.productDetail.currency,
                status: this.productDetail.status,
                shortDescription: this.productDetail.shortDescription,
                points: this.productDetail.points,
                image: {
                    file: "",
                    presign: "",
                    fileList: [],
                },
            };
            let media = this.productDetail.media;
            this.data.mediaList = media;
            this.data.media = this.productDetail.imageUrl;
            this.body.categoryId = "";
            if (
                this.productDetail.categories &&
                this.productDetail.categories[0]
            ) {
                for (let i = 0; i < this.productDetail.categories.length; i++) {
                    const element = this.productDetail.categories[i];
                    this.body.categoryId = element.id;
                }
            }
        },
        formatPrice(price) {
            return Helper.formatPrice(price);
        },
        async getAllCurrency() {
            let param = "?status=active";
            await Service.getAllCurrency(param).then((response) => {
                if (response.statusCode) {
                    this.$toasted.show(response.message.capitalize());
                    if (response.statusCode == "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.getAllCurrency();
                            }
                        });
                    } else {
                        this.$toasted.show(response.message.capitalize());
                    }
                } else {
                    this.isFetching = false;
                    this.data.currencies = response.data;
                }
            });
        },
        async searchProducts(query) {
            this.loading.isSearching = true;
            let search = { search: query };
            let setQuery = this.$helper.filterKeyMapping({
                page: query?.page || 1,
                ...search,
            });
            let params = this.$helper.queryParam(setQuery);

            await Service.getAllProducts(params).then((response) => {
                this.loading.isSearching = false;
                if (response?.statusCode) {
                    if (response?.statusCode === "4410") {
                        Service.refreshToken().then((response) => {
                            if (response === "ok") {
                                this.getAllProducts();
                            }
                        });
                    } else {
                        this.$toasted.show(response?.message.capitalize());
                    }
                } else {
                    this.data.productList = response?.data;
                }
            });
        },
        async getRelatedProduct() {
            let productId = this.$route.query.id;
            await Service.getRelatedProducts(productId).then((response) => {
                if (response?.statusCode) {
                    if (response?.statusCode === "4410") {
                        Service.refreshToken().then((response) => {
                            if (response === "ok") {
                                this.getRelatedProduct();
                            }
                        });
                    } else {
                        this.$toasted.show(response?.message.capitalize());
                    }
                } else {
                    this.data.relatedProduct = response?.data;
                }
            });
        },

        closeModal() {
            this.display.imagePreview = false;
            this.display.media = {
                single: false,
                multiple: false,
            };
        },

        chooseImage(type) {
            if (type == "single") {
                this.display.media.single = true;
            } else this.display.media.multiple = true;
        },

        existedMediaCallback(media) {
            this.data.media = media;
            this.body.imageUrl = media.url;
            this.closeModal();
        },

        existedMultiMediaCallback(media) {
            this.data.mediaList = media;
            this.body.mediaId = [];
            for (let index = 0; index < media.length; index++) {
                const element = media[index];
                this.body.mediaId.push(element.id);
            }

            this.closeModal();
        },

        getFullImage(path) {
            return process.env.VUE_APP_BASE_URL_IMAGE + path;
        },

        getNameFromUrl(url) {
            return url.split("/").pop();
        },

        submitEdit() {
            this.loading.isCreating = true;
            this.body.productRelatedIds = [];
            if (this.data.relatedProduct) {
                for (
                    let index = 0;
                    index < this.data.relatedProduct.length;
                    index++
                ) {
                    const product = this.data.relatedProduct[index];
                    this.body.productRelatedIds.push(product.id);
                }
            }
            this.submitProduct();
        },

        async submitProduct() {
            let body = {
                description: this.body.description,
                specification: this.body.specification,
                imageUrl: this.body.imageUrl,
                shipping: this.body.shipping,
                //currency: this.body.currency,
                status: this.body.status,
                mediaId: this.body.mediaId,
                relatedId: this.body.productRelatedIds,
                points: this.body.points,
            };
            let id = this.productDetail.id;
            await Service.updateProduct(id, body).then(async (response) => {
                this.loading.isCreating = false;
                if (response?.statusCode) {
                    if (response?.statusCode === "4410") {
                        Service.refreshToken().then((response) => {
                            if (response === "ok") {
                                this.submitProduct();
                            }
                        });
                    } else {
                        if (
                            response.message ==
                            "brandId must be a number conforming to the specified constraints"
                        ) {
                            this.$toasted.show(
                                "Must select brand available with category"
                            );
                        } else {
                            this.$toasted.show(response.message.capitalize());
                        }
                    }
                } else {
                    this.$toasted.show("Product has been updated.");
                    this.goBack();
                }
            });
        },

        handleImageAdded(file, Editor, cursorLocation) {
            let body = {
                media: [
                    {
                        ext: file.type.split("/").pop(),
                        type: "product",
                        filename: file.name,
                    },
                ],
            };
            Service.uploadPresign(body).then((response) => {
                this.body.image.presign = response.data;
                this.uploadFile(file);
                let url = this.body.image.presign[0].accessUrl;
                Editor.insertEmbed(cursorLocation, "image", url);
            });
        },

        async uploadFile(file) {
            if (file) {
                let uploadUrl = this.body.image.presign[0].uploadUrl;
                await Service.uploadMedia(uploadUrl, file, file.type).then(
                    (response) => {
                        if (response != "ok") {
                            this.$toasted.show("File upload fail!");
                        }
                    }
                );
            } else {
                this.$toasted.show("File cannot be empty!");
            }
        },

        toUpperCase(string) {
            return string.toUpperCase();
        },

        toCapitalLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },

        resetBody() {
            this.body = {
                image: {
                    file: "",
                    presign: "",
                    fileList: [],
                },
                specification: "",
                sku: "",
                code: "",
                //currency: '',
                price: "",
                quantity: "",
                tax: "",
                imageUrl: "",
                shipping: true,
                status: "active",
                points: 0,
                stockStatusId: "",
                brandId: "",
                categoryId: [],
                mediaId: [],
                relatedId: [],
                optionId: [],
                relatedProduct: [],
            };

            this.data.mediaList = [];
            this.goBack();
        },
        goBack() {
            this.$router.push({
                name: "ListProduct",
                query: {
                    page: 1,
                    limit: 10,
                },
            });
        },
    },
};
