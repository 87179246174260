<template>
    <div id="app">
        <div v-if="!isLogging">
            <div class="sidebar-container m-scroll">
                <Sidebar></Sidebar>
            </div>
            <Navbar></Navbar>
            <div class="wrapper-container">
                <router-view></router-view>
            </div>
        </div>
        <router-view v-else></router-view>
    </div>
</template>
<script>
import Sidebar from "./components/sidebar";
import Navbar from "./components/navbar";
import Service from "./utils/api/service";

export default {
    name: "app",
    data() {
        return {
            isLogging: false,
        };
    },
    components: {
        Sidebar,
        Navbar,
    },
    created() {
        this.checkRouteName();
        this.getAccountDetail();
        this.getMediaDirectory();
    },
    methods: {
        checkRouteName() {
            let routeName = this.$route.name;
            if (routeName == "login") {
                this.isLogging = true;
            } else {
                this.isLogging = false;
            }
        },

        getAccountDetail() {
            let token = localStorage.getItem(
                this.$helper?.tokenEnum()?.access_token
            )
                ? localStorage.getItem(this.$helper?.tokenEnum()?.access_token)
                : "";

            if (token) {
                Service.getAccountDetail().then((response) => {
                    if (response.statusCode) {
                        if (response.statusCode == "4410") {
                            Service.refreshToken().then((response) => {
                                if (response == "ok") {
                                    this.getAccountDetail();
                                }
                            });
                        } else {
                            this.$toasted.show(response.message.capitalize());
                        }
                    } else {
                        this.$store.dispatch("storeAccountInfo", response.data);
                    }
                });
            }
        },

        getMediaDirectory() {
            let token = localStorage.getItem(
                this.$helper?.tokenEnum()?.access_token
            )
                ? localStorage.getItem(this.$helper?.tokenEnum()?.access_token)
                : "";

            if (token) {
                Service.getMediaDirectory().then((response) => {
                    if (response.statusCode) {
                        if (response.statusCode == "4410") {
                            Service.refreshToken().then((response) => {
                                if (response == "ok") {
                                    this.getMediaDirectory();
                                }
                            });
                        } else {
                            // this.$toasted.show(response.message.capitalize());
                        }
                    } else {
                        this.$store.dispatch(
                            "storeMediaDirectory",
                            response.data
                        );
                    }
                });
            }
        },
    },
};
</script>
<style>
* {
    box-sizing: border-box;
}

body {
    /* font-family: var(--font-family-monospace); */
    font-display: var(--font-family-monospace);
    font-weight: 300 !important;
    margin: 0;
    padding: 0;
    background: #f6f6f6f6;
}

.ck-content {
    min-height: 200px;
}

.ck-powered-by {
    display: none;
}

input[readonly][disabled] {
    background-color: lightgray; /* Set your desired background color here */
    color: darkgray; /* Set your desired text color here */
}

.table-control {
    min-height: 545px;
}

.dashboard .loader {
    top: 0 !important;
}

.order-loading .loader {
    top: 0 !important;
}

.h-filter {
    display: flex;
    position: relative;
    float: right;
    align-items: center;
}

.h-filter-item {
    width: fit-content;
    max-width: 200px;
    display: block;
    position: relative;
    text-align: left;
    float: left;
    margin-right: 6px;
}

/* ===== Remove Arrows/Spinners in Input Number ==== */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.option-all {
    color: #555;
    font-weight: 600;
}

.vti__dropdown-list.below {
    top: 33px;
    display: none !important;
}

.vti__dropdown-arrow {
    display: none !important;
}

.vti__dropdown {
    padding: 12px !important;
}

.vti__input {
    padding-left: 12px !important;
    padding-top: 5px !important;
}

.sidebar-container {
    width: 235px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #19222e;
    z-index: 9;
    overflow-y: auto;
    overflow-x: hidden;
    color: var(--white);
}

.display-flex {
    display: flex;
}

.not-allowed {
    cursor: not-allowed;
    background: #cccccc24;
}

tr.hover-list {
    height: 50px;
}

input {
    outline-style: none;
    border-style: none;
    width: 100%;
    background: transparent;
}

.display-time {
    border: none !important;
}

div#editor,
div#editor1 {
    height: 200px;
}

.resize-v {
    resize: vertical;
}

i.far.fa-question-circle {
    font-size: 13px;
}

/* multiselect */
.multiselect__tags {
    border: 1px solid #ccc !important;
    /* min-height: 34px !important; */
    font-size: 12px !important;
    padding: 4px 7px 0px 7px !important;
    overflow-y: scroll;
    max-height: 100px !important;
}

.multiselect__tag {
    background: rgb(216, 216, 216) !important;
    color: #000 !important;
    padding: 4px 25px !important;
    margin-bottom: 0 !important;
}

.multiselect__placeholder {
    font-size: 13px !important;
    margin-bottom: 0 !important;
    margin-top: 4px;
}

.multiselect__tag-icon {
    padding-top: 0px !important;
    padding-left: 3px !important;
}

.multiselect__tag-icon:hover {
    background: none !important;
}

.multiselect__tag-icon:after {
    color: #000 !important;
}

.multiselect__single {
    margin-bottom: 0px !important;
    font-size: 14px !important;
}

.multiselect__option {
    font-size: 13px !important;
}

input.multiselect__input {
    font-size: 13px;
}

span.multiselect__single {
    color: black;
    margin-top: 2px;
}

@media screen and (max-width: 1200px) {
    .sidebar-container {
        display: none;
    }

    .wrapper-container {
        padding: 60px 5px 5px 5px;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
    bottom: -3px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
    bottom: 5px;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 5px;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    /* background-color: #2196f3; */
    background-color: var(--primary);
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* upload image */
.media-container {
    position: relative;
    width: 300px;
}

.media-container .sub {
    color: #ccc;
    font-size: 10px;
}

.media-container .media-wrapper {
    width: 100%;
    border-radius: 4px;
    border: 1px dashed #ccc;
    padding: 3px;
    display: inline-block;
    height: 150px;
    position: relative;
}

.media-container .media-wrapper .btn-secondary {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: fit-content;
    background: none !important;
    border: 0;
    overflow: hidden;
    color: #936efa;
}

.media-container .media-wrapper .btn-secondary:hover {
    text-decoration: underline;
}

.media-container .media-wrapper .go-left {
    width: calc(100% - 115px);
    overflow: hidden;
}

.media-container .media-wrapper .img-relative {
    width: 80%;
    overflow: hidden;
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    height: 88px;
    font-size: 12px;
}

.media-container .media-wrapper .img-relative span {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.media-container .media-wrapper .img-relative img {
    width: 50px;
}

.media-container .media-wrapper .img-absolute {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.media-container .media-wrapper .img-absolute img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}

.media-container .media-wrapper .go-left .p-file-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* notification */
.details-wrapper .details-wrapper-child .image-loading img {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    object-fit: cover !important;
    border: 2px solid #dddddd;
    margin-bottom: 3px;
}

.details-wrapper .details-wrapper-child .image-loading img:hover {
    border: 2px solid #f38121;
}

.p-file-name {
    margin-bottom: 0px;
    color: #fdb432;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px !important;
    width: 120px !important;
}

.table td ul {
    margin: 0;
    list-style-type: none;
}
</style>
