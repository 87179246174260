var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "row p6 radius6 animate-bottom"
  }, [_c('div', {
    staticClass: "row bg-white border-top-light-blue"
  }, [_c('header', {
    staticClass: "row p12",
    staticStyle: {
      "background": "#f2f2f2"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "h-filter"
  }, [_c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Brand")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.brandId,
      expression: "search.brandId"
    }],
    staticClass: "input input-xs h34 mr6",
    staticStyle: {
      "padding": "6px"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.search, "brandId", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.searchProduct]
    }
  }, [_c('option', {
    staticClass: "option-all",
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Brand")]), _vm._l(_vm.data.brands, function (brand, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": brand.id
      }
    }, [_vm._v(" " + _vm._s(brand.name) + " ")]);
  })], 2)]), _c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Category")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.categoryId,
      expression: "search.categoryId"
    }],
    staticClass: "input input-xs h34 mr6",
    staticStyle: {
      "padding": "6px"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.search, "categoryId", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.searchProduct]
    }
  }, [_c('option', {
    staticClass: "option-all",
    attrs: {
      "value": ""
    }
  }, [_vm._v("All Category")]), _vm._l(_vm.data.categories, function (category, index) {
    return _c('optgroup', {
      key: index,
      attrs: {
        "label": category.name
      }
    }, _vm._l(category.children, function (subCategory, id) {
      return _c('option', {
        key: id,
        domProps: {
          "value": subCategory.id
        }
      }, [_vm._v(" " + _vm._s(subCategory.name) + " ")]);
    }), 0);
  })], 2)]), _c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Sort By")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.sort,
      expression: "search.sort"
    }],
    staticClass: "input mr6 h34",
    staticStyle: {
      "padding": "6px"
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.search, "sort", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.searchProduct]
    }
  }, [_c('option', {
    staticClass: "option-all",
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select below")]), _c('option', {
    attrs: {
      "value": "newest"
    }
  }, [_vm._v("Newest")]), _c('option', {
    attrs: {
      "value": "oldest"
    }
  }, [_vm._v("Oldest")]), _c('option', {
    attrs: {
      "value": "popular_search"
    }
  }, [_vm._v("Popular Search")]), _c('option', {
    attrs: {
      "value": "top_selection_asc"
    }
  }, [_vm._v("Top Selection Ascending")]), _c('option', {
    attrs: {
      "value": "top_selection_desc"
    }
  }, [_vm._v("Top Selection Descending")])])]), _c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("SKU")]), _c('div', {
    staticClass: "search-box mr0"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.sku,
      expression: "search.sku"
    }],
    attrs: {
      "type": "search",
      "autocomplete": "off",
      "placeholder": "sku",
      "id": "search"
    },
    domProps: {
      "value": _vm.search.sku
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchProduct.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "sku", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Variant SKU")]), _c('div', {
    staticClass: "search-box mr0"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.variantSku,
      expression: "search.variantSku"
    }],
    attrs: {
      "type": "search",
      "autocomplete": "off",
      "placeholder": "variant sku",
      "id": "search"
    },
    domProps: {
      "value": _vm.search.variantSku
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchProduct.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "variantSku", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "h-filter-item"
  }, [_c('b', {
    staticClass: "w100 font12"
  }, [_vm._v("Search")]), _c('div', {
    staticClass: "search-box mr0"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search.search,
      expression: "search.search"
    }],
    attrs: {
      "type": "search",
      "autocomplete": "off",
      "placeholder": "Search",
      "id": "search"
    },
    domProps: {
      "value": _vm.search.search
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.searchProduct.apply(null, arguments);
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.search, "search", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticStyle: {
      "display": "block",
      "position": "relative",
      "text-align": "left",
      "float": "left"
    }
  }, [_c('button', {
    staticClass: "btn btn-info btn-sm mr6 mt25",
    on: {
      "click": _vm.searchProduct
    }
  }, [_c('i', {
    staticClass: "fa fa-search"
  }), _vm._v(" Search ")])])])]), _c('div', {
    staticClass: "table-control"
  }, [_c('table', {
    staticClass: "table wh800",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "width": "100px"
    }
  }, [_vm._v("SKU")]), _c('th', {
    attrs: {
      "width": "100px"
    }
  }, [_vm._v("Image")]), _c('th', {
    attrs: {
      "width": "300px"
    }
  }, [_vm._v("Name")]), _c('th', {
    attrs: {
      "width": "150px"
    }
  }, [_vm._v("Brand")]), _c('th', [_vm._v("Category")]), _vm.checkoutPermission('UPDATE_MANY_PRODUCT') ? _c('th', {
    staticClass: "txt-center",
    attrs: {
      "width": "100px"
    }
  }, [_vm._v("Top selection")]) : _vm._e(), _vm.checkoutPermission('UPDATE_PRODUCT') ? _c('th', {
    staticClass: "txt-center",
    attrs: {
      "width": "100px"
    }
  }, [_vm._v("Status")]) : _vm._e(), _vm.checkoutPermission('UPDATE_PRODUCT') || _vm.checkoutPermission('DELETE_PRODUCT') ? _c('th', {
    staticClass: "text-center"
  }, [_vm._v(" Action ")]) : _vm._e()])]), _vm.checkoutPermission('LIST_PRODUCT') ? _c('tbody', _vm._l(_vm.data.productList, function (product, index) {
    return _c('tr', {
      key: (product, index),
      staticClass: "hover-list"
    }, [_c('td', [_c('span', [_vm._v(_vm._s(product.sku))])]), _c('td', [_c('image-detail', {
      attrs: {
        "heightChange": '36px',
        "widthChange": '60px',
        "objectFit": 'cover',
        "thumbnail": product.imageUrl && _vm.getFullImage(product.imageUrl) || require('@/assets/images/noImage.jpg')
      }
    })], 1), _c('td', [_vm._v(_vm._s(product.name))]), _c('td', [product.brand ? _c('span', [_vm._v(_vm._s(product.brand.name))]) : _c('span', [_vm._v("N/A")])]), _c('td', [product.category ? _c('span', [_vm._v(_vm._s(product.category.name))]) : _vm._e()]), _c('td', {
      staticClass: "txt-center"
    }, [_vm.checkoutPermission('UPDATE_MANY_PRODUCT') ? _c('label', {
      staticClass: "switch"
    }, [_c('input', {
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": (product === null || product === void 0 ? void 0 : product.topSelection) && 'checked'
      },
      on: {
        "click": function ($event) {
          return _vm.updateTopSelection(product === null || product === void 0 ? void 0 : product.id, product === null || product === void 0 ? void 0 : product.topSelection);
        }
      }
    }), _c('span', {
      staticClass: "slider round"
    })]) : _vm._e()]), _c('td', {
      staticClass: "txt-center"
    }, [_vm.checkoutPermission('UPDATE_PRODUCT') ? _c('label', {
      staticClass: "switch"
    }, [_c('input', {
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": product.status === 'active' && 'checked'
      },
      on: {
        "click": function ($event) {
          return _vm.updateProduct(product.id, product.status);
        }
      }
    }), _c('span', {
      staticClass: "slider round"
    })]) : _vm._e()]), _c('td', {
      staticClass: "text-center"
    }, [_vm.checkoutPermission('UPDATE_PRODUCT') ? _c('router-link', {
      attrs: {
        "to": {
          name: 'EditProduct',
          query: {
            id: product.id
          }
        }
      }
    }, [_c('button', {
      staticClass: "btn btn-info btn-xs mr5"
    }, [_c('i', {
      staticClass: "fa fa-pen"
    })])]) : _vm._e()], 1)]);
  }), 0) : _vm._e()]), _vm.isFetching ? _c('TableLoading') : _vm._e(), _vm.isNoData && !_vm.isFetching ? _c('NoItem') : _vm._e()], 1), _c('div', {
    staticClass: "total ph12"
  }, [_c('div', {
    staticClass: "ul-xl-li go-left"
  }, [_vm._v(" Total : " + _vm._s(_vm.data.pagination.total) + " ")]), _vm.data.pagination.totalPage > 0 ? _c('Pagination', {
    attrs: {
      "pagination": _vm.data.pagination.totalPage
    }
  }) : _vm._e()], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row"
  }, [_c('b', [_vm._v("Products")])]);

}]

export { render, staticRenderFns }