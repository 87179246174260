import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import axios from "axios";
import VueCookies from "vue-cookies";
import VueRouter from "./router";
import router from "./router/index";
import _ from "lodash";
import Progress from "vue-multiple-progress";
import Toasted from "vue-toasted";
import store from "./store/store";
import Vue2Editor from "vue2-editor";
// Main JS (in UMD format)
import VueTimepicker from "vue2-timepicker";
// CSS
import "vue2-timepicker/dist/VueTimepicker.css";
import * as VueGoogleMaps from "vue2-google-maps"; // Import package

import * as helper from "./utils/api/helper";
import UploadImage from "./components/share/upload-image/index.vue";
import ImageLoading from "./components/share/image-loading/index.vue";
import ImageDetail from "./components/share/image-detail/index.vue";
import CKEditor from "./components/share/ck-editor/index.vue";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAoICXqGkchKOAwsgZePC1D36JQmhcybHA",
    libraries: "places",
  },
});
Vue.config.productionTip = false;
Vue.component("VmProgress", Progress);
Vue.use(Vuex);
Vue.use(Vuex, axios);
Vue.prototype.$axios = axios;
Vue.prototype.$helper = helper.default;
Vue.use(VueCookies);
Vue.use(VueRouter);

Object.defineProperty(Vue.prototype, "$_", { value: _ });
Vue.use(require("vue-moment"));
Vue.use(Vue2Editor);
Vue.use(VueTimepicker);

// custom component
Vue.component("UploadImage", UploadImage);
Vue.component("ImageLoading", ImageLoading);
Vue.component("ImageDetail", ImageDetail);
Vue.component("CKEditor", CKEditor);

let Options = {
  position: "bottom-right",
  duration: 3000,
  keepOnHover: true,
};
Vue.use(Toasted, Options);

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
