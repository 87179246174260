import Pagination from "@/components/share/pagination";
import Service from "../../../../utils/api/service";
import Helper from "../../../../utils/global/func";
import TableLoading from "../../../../components/share/table-loading";
import NoItem from "./../../../../components/share/table-no-item";
import { mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
    name: "ListSpecialBlendOrder",
    data() {
        return {
            isFetching: true,
            isNoData: false,
            data: {
                order: [],
                orderStatus: [],
                pagination: {
                    limit: 10,
                    page: 1,
                    total: 0,
                    totalPage: 0,
                },
            },
            search: {
                accountId: "",
                transactionNumber: "",
                invoiceNumber: "",
                orderStatusId: "",
                paymentStatus: "",
                cardCode: "",
                dateRange: "",
                customerGroupId: "",
            },
            customerGroups: [],
        };
    },
    components: {
        Pagination,
        TableLoading,
        NoItem,
        DatePicker,
    },
    created() {
        this.setParams();
        this.getAllOrder();
        this.getOrderStatus();
        this.getCustomerGroups();
    },
    watch: {
        "$route.fullPath": function () {
            this.setParams();
            this.getAllOrder();
        },
    },
    computed: {
        ...mapState(["permissions"]),
    },
    mounted() {},
    methods: {
        setParams() {
            this.search = this.$helper.setParams(
                this.search,
                this.$root.$route.query
            );
        },

        checkoutPermission(permissionName) {
            if (this.permissions) {
                let result = false;
                this.permissions.find((item) => {
                    item.permissions.find((permission) => {
                        if (permission.name == permissionName) {
                            result = true;
                        }
                    });
                });

                return result;
            }
        },

        getCustomerGroups() {
            let params = "?limit=100&offset=0";

            Service.getCustomerGroups(params).then((response) => {
                if (response?.statusCode) {
                    if (response?.statusCode === "4410") {
                        Service.refreshToken().then((response) => {
                            if (response === "ok") {
                                this.getCustomerGroups();
                            }
                        });
                    } else if (response?.statusCode === "403") {
                        this.customerGroups = [];
                        this.$toasted.show(response?.message.capitalize());
                    } else {
                        this.$toasted.show(response?.message.capitalize());
                    }
                } else {
                    if (response?.data.length > 0) {
                        this.customerGroups = response?.data;
                    } else {
                        this.customerGroups = [];
                    }
                }
            });
        },

        async searching() {
            let query = this.$helper.filterKeyMapping({
                limit: 10,
                page: 1,
                ...this.search,
            });
            await this.$router.push({ query }).catch(() => {});
        },

        getOrderStatus() {
            Service.getAllOrderStatus().then((response) => {
                if (response?.statusCode === "4410") {
                    Service.refreshToken().then((response) => {
                        if (response == "ok") {
                            this.getAllOrderStatus();
                            return;
                        }
                    });
                }
                this.data.orderStatus = response.data;
            });
        },

        getAllOrder() {
            this.isFetching = true;
            let query = this.$root.$route.query;
            let setQuery = this.$helper.filterKeyMapping({
                page: query?.page || 1,
                ...query,
            });
            let params = this.$helper.queryParam(setQuery);
            Service.getAllSpecialBlendOrder(params).then((response) => {
                this.isFetching = false;
                if (response?.statusCode) {
                    if (response?.statusCode === "4410") {
                        Service.refreshToken().then((response) => {
                            if (response == "ok") {
                                this.getAllOrder();
                            }
                        });
                    } else if (response?.statusCode === "403") {
                        this.isNoData = true;
                        this.data.order = [];
                        this.$toasted.show(response?.message.capitalize());
                    } else {
                        this.$toasted.show(response?.message.capitalize());
                    }
                } else {
                    if (response.data && response.data.length > 0) {
                        this.isNoData = false;
                        this.data.order = response?.data;
                        this.data.pagination = Helper.calculatePagination(
                            response?.meta
                        );
                    } else {
                        this.data.order = [];
                        this.isNoData = true;
                        this.resetPagination();
                    }
                }
            });
        },

        viewOrder(id) {
            let object = {
                id: id,
                page: this.$root.$route.query.page,
                accountId: this.search.accountId,
                transactionNumber: this.search.transactionNumber,
                invoiceNumber: this.search.invoiceNumber,
            };
            if (this.search.accountId == "") {
                delete object.accountId;
            }
            if (this.search.transactionNumber == "") {
                delete object.transactionNumber;
            }
            if (this.search.invoiceNumber == "") {
                delete object.invoiceNumber;
            }
            this.$router.push({ name: "EditSpecialBlendOrder", query: object });
        },

        resetPagination() {
            this.data.pagination = {
                limit: 10,
                page: 1,
                total: 0,
                totalPage: 0,
            };
        },

        getFullImage(path) {
            return Helper.getFullImage(path);
        },
    },
};
